import { BulkUploadColumns } from '../../components/BulkUploadJobsGrid/types.ts';
import { IBulkUploadCreatedInstance } from './click-through/types.ts';
import { ExportableQuestion } from './download-template/types.ts';
import { uploadFileMetadata } from './import-file-validation/fileMetadata.ts';
import {
  FormsBulkUploadValidateFileUploadRow,
  RowStatus,
} from './import-file-validation/types.ts';
import * as types from './types.ts';

export const DEFAULT_STATE: types.IFormsBulkUploadState = {
  downloadTemplate: {
    showHiddenFields: false,
    isLoading: false,
    isError: false,
    exportableQuestions: [],
  },
  importFileValidation: {
    filteredRows: [],
    selectedTabId: 'UPLOAD',
    statusFilters: [],
    isSpinning: false,
  },
  jobHistory: {
    isLoading: false,
    isLoadingMore: false,
    error: undefined,
    hasNextPage: false,
    currentPage: 1,
    orderBy: [
      {
        fieldName: 'createdAt',
        direction: 'Descending',
      },
    ],
  },
  workflow: {
    workflowType: 'Default',
    createAutomatedActions: true,
  },
};

export function reducer(
  state = DEFAULT_STATE,
  action: types.FormsBulkUploadAction,
): types.IFormsBulkUploadState {
  switch (action.type) {
    case 'ui.forms/bulk-upload/jobs/LOAD': {
      const { isLoadingMore } = action.payload;
      return {
        ...state,
        jobHistory: {
          ...state.jobHistory,
          error: undefined,
          isLoading: !isLoadingMore,
          isLoadingMore,
        },
      };
    }

    case 'ui.forms/bulk-upload/jobs/LOADED': {
      const { hasLoadedMore } = action.payload;
      return {
        ...state,
        jobHistory: {
          ...state.jobHistory,
          error: undefined,
          isLoading: false,
          isLoadingMore: false,
          jobs: hasLoadedMore
            ? [...(state.jobHistory.jobs ?? []), ...action.payload.jobs]
            : [...action.payload.jobs],
          currentPage: action.payload.pageInfo.currentPage,
          hasNextPage: action.payload.pageInfo.hasNextPage,
        },
      };
    }

    case 'ui.forms/bulk-upload/jobs/LOAD_FAILED':
      return {
        ...state,
        jobHistory: {
          ...state.jobHistory,
          isLoading: false,
          isLoadingMore: false,
        },
      };

    case 'ui.forms/bulk-upload/download-template/TEMPLATE_CHANGE':
      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          selectedTemplate: action.payload.template,
        },
      };

    case 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOAD':
      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          isLoading: true,
          isError: false,
        },
      };

    case 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOADED':
      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          isLoading: false,
          exportableQuestions: action.payload.exportableQuestions,
        },
      };

    case 'ui.forms/bulk-upload/download-template/TOGGLE_SHOW_HIDDEN_FIELDS': {
      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          showHiddenFields: !state.downloadTemplate.showHiddenFields,
        },
      };
    }

    case 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOAD_ERROR':
      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          isLoading: false,
          isError: true,
          exportableQuestions: [],
        },
      };

    case 'ui.forms/bulk-upload/download-template/CLEAR':
      return {
        ...state,
        downloadTemplate: DEFAULT_STATE.downloadTemplate,
      };

    case 'ui.forms/bulk-upload/download-template/SELECT_SECTION_HEADING': {
      const { id, to } = action.payload;
      const { exportableQuestions } = state.downloadTemplate;
      if (!exportableQuestions.length) return state;

      const updatedQuestions = exportableQuestions.map((question) =>
        question.parentHeadingId === id
          ? {
              ...question,
              isSelected:
                question.isEnabled !== false ? to : question.isSelected,
            }
          : question,
      );

      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          exportableQuestions: updatedQuestions.map((question) =>
            question.id === id
              ? {
                  ...question,
                  isSelected: parentSelectionState(id, updatedQuestions),
                }
              : question,
          ),
        },
      };
    }

    case 'ui.forms/bulk-upload/download-template/SELECT_QUESTION': {
      const { id, to } = action.payload;
      const { exportableQuestions } = state.downloadTemplate;
      if (!exportableQuestions.length) return state;

      const updatedQuestions = exportableQuestions.map((question) =>
        question.id === id
          ? {
              ...question,
              isSelected:
                question.isEnabled !== false ? to : question.isSelected,
            }
          : question,
      );

      const parentHeadingId = updatedQuestions.find(
        (question) => question.id === id,
      )?.parentHeadingId;

      return {
        ...state,
        downloadTemplate: {
          ...state.downloadTemplate,
          exportableQuestions: updatedQuestions.map((question) =>
            question.id === parentHeadingId
              ? {
                  ...question,
                  isSelected: parentSelectionState(
                    parentHeadingId,
                    updatedQuestions,
                  ),
                }
              : question,
          ),
        },
      };
    }

    case 'ui.forms/bulk-upload/click-through/CREATE': {
      const { type, jobId } = action.payload;
      return {
        ...state,
        clickThrough: {
          instances: [],
          jobId,
          isLoading: true,
          type,
          orderBy:
            type === BulkUploadColumns.SUCCEEDED
              ? [
                  {
                    fieldName: 'createdAt',
                    direction: 'Descending',
                  },
                ]
              : [
                  {
                    fieldName: 'rowNumber',
                    direction: 'Ascending',
                  },
                ],
        },
      };
    }

    case 'ui.forms/bulk-upload/click-through/LOAD': {
      const { isLoadingMore } = action.payload;
      return {
        ...state,
        clickThrough: {
          ...state.clickThrough,
          errors: undefined,
          isLoading: !isLoadingMore,
          isLoadingMore,
        },
      };
    }

    case 'ui.forms/bulk-upload/click-through/LOADED': {
      const { instances, pageInfo, hasLoadedMore } = action.payload;
      return {
        ...state,
        clickThrough: {
          ...state.clickThrough,
          instances: hasLoadedMore
            ? [
                ...((state.clickThrough?.instances ??
                  []) as IBulkUploadCreatedInstance[]),
                ...(instances as IBulkUploadCreatedInstance[]),
              ]
            : instances,
          isLoading: false,
          isLoadingMore: false,
          pagination: pageInfo,
        },
      };
    }

    case 'ui.forms/bulk-upload/click-through/LOAD_ERROR': {
      const { message } = action.payload;

      return {
        ...state,
        clickThrough: {
          ...state.clickThrough,
          isLoading: false,
          errors: {
            ...state.clickThrough?.errors,
            load: message,
          },
        },
      };
    }

    case 'ui.forms/bulk-upload/import-file-validation/CLEAR':
      return {
        ...state,
        importFileValidation: {
          filteredRows: [],
          selectedTabId: 'UPLOAD',
          statusFilters: [],
          isSpinning: false,
        },
        workflow: DEFAULT_STATE.workflow,
      };

    case 'ui.forms/bulk-upload/import-file-validation/FILE_ERROR_UPLOADING':
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          file: {
            ...state.importFileValidation.file,
            action: undefined,
            error: action.payload.errorMessage,
          },
        },
      };

    case 'ui.forms/bulk-upload/import-file-validation/FILE_UPLOAD':
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          file: {
            ...uploadFileMetadata(action.payload.file),
            action: 'uploading',
          },
          validatedFile: action.payload.file,
        },
      };

    case 'ui.forms/bulk-upload/import-file-validation/FILE_UPLOADED':
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          file: {
            ...state.importFileValidation.file,
            action: undefined,
            canDelete: false,
          },
          statusFilters: ['Error', 'Success', 'Warning'],
          filteredRows: action.payload.validationResult.rows,
          validationResult: action.payload.validationResult,
        },
      };

    case 'ui.forms/bulk-upload/import-file-validation/IMPORT': {
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          isSpinning: true,
        },
      };
    }

    case 'ui.forms/bulk-upload/import-file-validation/STATUS_FILTER_CHANGE': {
      const statusFilters = setStatusFilter(
        action.payload.filterValue,
        state.importFileValidation.statusFilters,
      );

      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          filteredRows: filterRows(
            statusFilters,
            state.importFileValidation.validationResult?.rows,
          ),
          statusFilters: statusFilters,
        },
      };
    }

    case 'ui.forms/bulk-upload/import-file-validation/TAB_CHANGE':
      return {
        ...state,
        importFileValidation: {
          ...state.importFileValidation,
          selectedTabId: action.payload.tabId,
        },
      };

    case 'ui.forms/bulk-upload/click-through/ORDER_BY_CHANGED': {
      const { orderBy } = action.payload;
      return {
        ...state,
        clickThrough: {
          ...state.clickThrough,
          orderBy: [orderBy],
          pagination: undefined,
        },
      };
    }

    case 'ui.forms/bulk-upload/click-through/LOAD_MORE_ERROR': {
      const { message } = action.payload;

      return {
        ...state,
        clickThrough: {
          ...state.clickThrough,
          isLoadingMore: false,
          errors: {
            ...state.clickThrough?.errors,
            loadMore: message,
          },
        },
      };
    }

    case 'ui.forms/bulk-upload/download-template/EXPORT_CANCEL': {
      return {
        ...state,
        downloadTemplate: DEFAULT_STATE.downloadTemplate,
      };
    }

    case 'ui.forms/bulk-upload/select-workflow/SELECT_WORKFLOW_OPTION': {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          workflowType: action.payload.workflowType,
        },
      };
    }

    case 'ui.forms/bulk-upload/select-workflow/SELECT_CREATE_AUTOMATED_ACTIONS': {
      return {
        ...state,
        workflow: {
          ...state.workflow,
          createAutomatedActions: action.payload.createAutomatedActions,
        },
      };
    }

    default:
      return state;
  }
}

const parentSelectionState = (
  parentId: string,
  questions: ExportableQuestion[],
) => {
  const children = questions.filter(
    (question) =>
      question.parentHeadingId === parentId &&
      !['assignedTo', 'subject'].includes(question.id),
  );

  if (children.every(({ isSelected }) => isSelected)) return true;
  if (children.every(({ isSelected }) => !isSelected)) return false;
  return 'indeterminate';
};

const filterRows = (
  statusFilters: RowStatus[],
  rows?: FormsBulkUploadValidateFileUploadRow[],
) => {
  if (!rows) return rows;
  if (statusFilters.length === 0) return [];

  return rows.filter((row) =>
    filterValidationFileUploadRow(row, statusFilters),
  );
};

const filterValidationFileUploadRow = (
  row: FormsBulkUploadValidateFileUploadRow,
  statusFilters: RowStatus[],
) => {
  if (statusFilters.length === 0) return row;

  return statusFilters?.some((filter) => filter === row.status)
    ? row
    : undefined;
};

const setStatusFilter = (
  filterValue: RowStatus,
  statusFilters: RowStatus[],
): RowStatus[] => {
  if (statusFilters.indexOf(filterValue) === -1) {
    return [...statusFilters, filterValue];
  }

  const filteredList = statusFilters.filter((filter) => filter !== filterValue);

  return filteredList;
};
