/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  LabelButton,
  LabelButtonDropdownClickEvent,
} from '@seeeverything/ui.primitives/src/components/LabelButton/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo, useState } from 'react';
import {
  FormInstanceStatus,
  IFormInstanceSignOff,
} from '../../../redux/form-instance/types.ts';

const BUTTONS = {
  signOff: {
    dataTest: 'forms-buttonSignoff-confirmOption',
    id: 'SIGNOFF',
    icon: Icons.tickDone,
    iconColor: { default: COLORS.GREEN_TICK, selected: COLORS.WHITE },
    content: {
      text: 'Confirm',
      description: 'Sign-off and lock further changes.',
    },
  },
  signOffWhileAppealed: {
    dataTest: 'forms-buttonSignoff-confirmOption',
    id: 'SIGNOFF',
    icon: Icons.tickDone,
    iconColor: { default: COLORS.GREEN_TICK, selected: COLORS.WHITE },
    content: {
      text: 'Confirm & Sign-Off',
      description: 'Cancel appeal, sign-off and lock further changes.',
    },
  },
  revertSignoff: {
    dataTest: 'forms-signoff-buttonRevert-reopenOption',
    id: 'REVERT',
    icon: Icons.undo,
    iconColor: { default: COLORS.GREEN_TICK, selected: COLORS.WHITE },
    content: {
      text: 'Reopen',
      description: 'Reopen for further changes.',
    },
  },
  dismiss: {
    id: 'DISMISS',
    icon: Icons.arrowBack,
    content: {
      text: 'Dismiss',
      description: 'Leave in current state.',
    },
  },
  appealAsSubject: {
    id: 'APPEAL',
    icon: Icons.feedback,
    iconColor: { default: '#F4B96F', selected: COLORS.WHITE },
    content: {
      text: 'Appeal',
      description: 'Ensure you have agreement from your manager in advance.',
    },
  },

  appealAsManager: {
    id: 'APPEAL',
    icon: Icons.feedback,
    iconColor: { default: '#F4B96F', selected: COLORS.WHITE },
    content: {
      text: 'Appeal',
      description: 'Appeal this review as a manager.',
    },
  },

  onlyManagerCanAppeal: {
    id: 'APPEAL',
    icon: Icons.feedback,
    iconColor: { default: '#F4B96F', selected: COLORS.WHITE },
    content: {
      text: 'Appeal',
      description: 'This review can only be appealed by a manager.',
    },
    isEnabled: false,
  },
};

export interface ISignOffButtonProps {
  canAppealAsManager: boolean;
  canAppealAsSubject: boolean;
  errorTooltip?: string;
  instanceStatus: FormInstanceStatus;
  label: string;
  onAppeal: () => void;
  onlyManagerCanAppeal: boolean;
  onRevert: () => void;
  onSignoff: () => void;
  showAppeal: boolean;
  showRevert: boolean;
  showSignOff: boolean;
  signOff: IFormInstanceSignOff;
}

export const SignOffButton = ({
  canAppealAsManager,
  canAppealAsSubject,
  errorTooltip,
  instanceStatus,
  label,
  onAppeal,
  onlyManagerCanAppeal,
  onRevert,
  onSignoff,
  signOff,
  showAppeal,
  showRevert,
  showSignOff,
}: ISignOffButtonProps) => {
  const [showingDropdown, setShowingDropdown] = useState(false);

  const dropdownItemSelected = useCallback(
    ({ id }: LabelButtonDropdownClickEvent) => {
      setShowingDropdown(false);

      if (id === 'SIGNOFF') onSignoff?.();
      if (id === 'APPEAL') onAppeal?.();
      if (id === 'REVERT') onRevert?.();
    },
    [onAppeal, onRevert, onSignoff],
  );

  const showDropdown = useCallback(() => {
    setShowingDropdown(true);
  }, []);

  const hideDropdown = useCallback(() => {
    setShowingDropdown(false);
  }, []);

  const isAppealed = instanceStatus === 'Appealed';
  const isEnabled =
    !errorTooltip && Boolean(showSignOff || showAppeal || showRevert);

  const dropdown = showingDropdown
    ? {
        width: 230,
        items: [
          showSignOff && !isAppealed && BUTTONS.signOff,
          showSignOff && isAppealed && BUTTONS.signOffWhileAppealed,
          showAppeal && canAppealAsSubject && BUTTONS.appealAsSubject,
          showAppeal && canAppealAsManager && BUTTONS.appealAsManager,
          showAppeal && onlyManagerCanAppeal && BUTTONS.onlyManagerCanAppeal,
          showRevert && BUTTONS.revertSignoff,
          BUTTONS.dismiss,
        ].filter(Boolean),
      }
    : undefined;

  const theme = useMemo(() => {
    if (showRevert) return 'DARK';
    if (showAppeal) return 'BLUE_3D';
    if (showSignOff) return 'GREEN_3D';
    return 'DARK';
  }, [showAppeal, showRevert, showSignOff]);

  const leftIcon = useMemo(() => {
    if (showSignOff || showAppeal || showRevert) return Icons.arrowDropDown;
    return undefined;
  }, [showAppeal, showRevert, showSignOff]);

  const rightIcon = useMemo(() => {
    if (showRevert) return Icons.check;
    if (signOff) return Icons.check;
    if (showSignOff || showAppeal) return Icons.fingerprint;
    return undefined;
  }, [showAppeal, showRevert, showSignOff, signOff]);

  return (
    <LabelButton
      dataTest={'forms-buttonSignoff'}
      label={label}
      capitalized={true}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      theme={theme}
      size={'MEDIUM'}
      dropdown={dropdown}
      onClick={showDropdown}
      onDropdownClick={dropdownItemSelected}
      onDropdownHide={hideDropdown}
      isEnabled={isEnabled}
      tooltip={errorTooltip}
    />
  );
};
