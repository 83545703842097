/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { IActionPlanLine } from '../../../types/types.ts';
import { FormListItemWrapped } from '../../FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../../SectionTitle/SectionTitle.tsx';

export interface IActionPlanPlaceholderProps {
  instanceId: string;
  line: IActionPlanLine;
}

export const ActionPlanPlaceholder: React.FC<IActionPlanPlaceholderProps> = ({
  instanceId,
  line,
}) => {
  return (
    <FormListItemWrapped
      bullet={'block'}
      bulletLeft={0}
      bulletTop={-12}
      highlightOnHover={false}
      id={'actionPlan'}
      key={`actionPlanPlaceholder-${instanceId}-${line.id}`}
      parentId={line.parentId}
      marginTop={0}
      marginBottom={15}
      isVisible={line.isEnabled}
    >
      <div css={styles.content}>
        <SectionTitle text={line.title} />
        <ContentPlaceholder
          kind={'LIST'}
          emptyText={''}
          hintText={'There are no items on the action plan.'}
        />
      </div>
    </FormListItemWrapped>
  );
};

const styles = {
  content: css({
    marginTop: 60,
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  }),
};
