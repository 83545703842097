import momentTz from 'moment-timezone';

/**
 * Returns supplied status or 'Overdue' when today is after end-of 'dueBy' day.
 */
export function statusWithOverdue(
  status: 'Open' | 'Completed' | 'Cancelled' | 'Overdue',
  timezone: string,
  dueBy?: string,
) {
  if (!dueBy || (status !== 'Open' && status !== 'Overdue')) {
    return status;
  }
  const isOverdue = momentTz()
    .tz(timezone)
    .startOf('day')
    .isAfter(momentTz(dueBy).tz(timezone).endOf('day'));

  return isOverdue ? 'Overdue' : 'Open';
}
