import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { getFormTemplateSubjectType } from '../utils.ts';
import {
  InstanceSchedule,
  InstanceScheduleFrequency,
  InstanceScheduleStatus,
} from './instanceScheduleTypes.ts';

type GetInstanceSchedulesArgs = {
  orderBy: OrderBy;
  pageNumber?: number;
  showAll?: boolean;
};

export type GetInstanceSchedulesResponseDto = {
  pageInfo: {
    hasNextPage: boolean;
    currentPage: number;
  };
  instanceSchedules: InstanceSchedule[];
};

export const getInstanceSchedules = async (
  client: IGraphQLClient,
  args: GetInstanceSchedulesArgs,
): Promise<QueryResult<GetInstanceSchedulesResponseDto>> => {
  try {
    const response = await client.query<{
      forms: {
        instanceSchedules: {
          pageInfo: { hasNextPage: boolean; currentPage: number };
          nodes: Array<{
            id: string;
            createdAt: string;
            name: string;
            template: {
              entity: {
                id: string;
                name: string;
                category: { id: string; name: string };
                currentDefinition: { definition: string };
              };
            };
            assignedToSelector: 'Manager' | 'LineManager';
            frequency: InstanceScheduleFrequency;
            startDate: string;
            endDate: string;
            dueDateExpression: string;
            distributionList: { id: string; name: string };
            nextRunAt: string;
            status: InstanceScheduleStatus;
            reportingDateExpression: string;
            updatedBy: {
              id: string;
              name: string;
            };
            updatedAt: string;
          }>;
        };
      };
    }>({
      query: gql`
        query InstanceSchedules(
          $pageNumber: Int!
          $orderBy: [OrderByInput!]
          $showAll: Boolean
        ) {
          forms {
            instanceSchedules(
              orderBy: $orderBy
              pagination: { size: 100, pageNumber: $pageNumber }
              showAll: $showAll
            ) {
              pageInfo {
                currentPage
                hasNextPage
              }
              nodes {
                id
                createdAt
                distributionList {
                  id
                  name
                }
                dueDateExpression
                endDate
                frequency
                name
                nextRunAt
                reportingDateExpression
                startDate
                status
                template {
                  entity {
                    id
                    name
                    category {
                      id
                      name
                    }
                    currentDefinition {
                      definition
                    }
                  }
                }
                assignedToSelector
                updatedAt
                updatedBy {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      variables: {
        pageNumber: args.pageNumber,
        orderBy: args.orderBy,
        showAll: args.showAll,
      },
      fetchPolicy: 'network-only',
    });

    const { pageInfo, nodes } = response.data.forms.instanceSchedules;

    if (!nodes) {
      log.error('Failed to retrieve instance schedules');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: {
          hasNextPage: pageInfo.hasNextPage,
          currentPage: pageInfo.currentPage,
        },
        instanceSchedules: nodes.map(
          (node): InstanceSchedule => ({
            id: node.id,
            assignedToSelector: node.assignedToSelector,
            createdAt: node.createdAt,
            distributionList: node.distributionList,
            dueDateExpression: dueFromDueDateExpression(node.dueDateExpression),
            endDate: node.endDate,
            frequency: node.frequency,
            name: node.name,
            nextRunAt: node.nextRunAt,
            reportingDateExpression: node.reportingDateExpression,
            startDate: node.startDate,
            status: node.status,
            subjectType: getFormTemplateSubjectType(
              node.template.entity.currentDefinition.definition,
            ),
            template: {
              id: node.template.entity.id,
              name: node.template.entity.name,
              category: {
                id: node.template.entity.category.id,
                name: node.template.entity.category.name,
              },
              currentDefinition: {
                definition: node.template.entity.currentDefinition.definition,
              },
            },
            updatedAt: node.updatedAt,
            updatedBy: node.updatedBy,
          }),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query instance schedules - ${error.message}`,
      error,
    );
    return { isSuccess: false, errorReason: 'UNKNOWN', error };
  }
};

const dueFromDueDateExpression = (dueDateExpression: string) => {
  switch (dueDateExpression?.toLowerCase()) {
    case '1 week':
      return '1 Week';
    case '2 weeks':
      return '2 Weeks';
    case '3 weeks':
      return '3 Weeks';
    case '1 month':
      return '1 Month';
    case '2 months':
      return '2 Months';
    case '3 months':
      return '3 Months';
    default:
      return undefined;
  }
};
