import {
  addFormActionNote,
  cancelFormAction,
  completeFormAction,
  createFormAction,
  revertFormAction,
  updateFormActionAnswerDropdown,
  updateFormActionAnswerText,
  updateFormActionAssignedToPersonId,
  updateFormActionDescription,
  updateFormActionDueBy,
  updateFormActionIssueId,
  updateFormActionVerificationNotes,
  updateFormActionVerificationStatus,
} from './action.ts';
import {
  createFormAnswerWithIssue,
  updateFormAnswerWithIssue,
} from './answer.ts';
import {
  createAutomatedAction,
  deleteAutomatedAction,
  updateAutomatedActionDescription,
} from './automatedAction.ts';
import {
  activateGoalSchedule,
  createGoalSchedule,
  deactivateGoalSchedule,
  updateGoalSchedule,
  updateGoalScheduleEndDate,
} from './goalSchedule.ts';
import {
  createInstanceSchedule,
  deactivateInstanceScheduleStatus,
  updateInstanceSchedule,
  updateInstanceScheduleEndDate,
} from './instanceSchedule.ts';
import { updateFormAnswerIssueClassifications } from './issueClassifications.ts';
import { updateFormAnswerIssueNotes } from './issueNotes.ts';

export const formsMutation = {
  activateGoalSchedule,
  addFormActionNote,
  cancelFormAction,
  completeFormAction,
  createAutomatedAction,
  createFormAction,
  createFormAnswerWithIssue,
  createGoalSchedule,
  createInstanceSchedule,
  deactivateGoalSchedule,
  deactivateInstanceScheduleStatus,
  deleteAutomatedAction,
  revertFormAction,
  updateAutomatedActionDescription,
  updateFormActionAnswerDropdown,
  updateFormActionAnswerText,
  updateFormActionAssignedToPersonId,
  updateFormActionDescription,
  updateFormActionDueBy,
  updateFormActionIssueId,
  updateFormActionVerificationNotes,
  updateFormActionVerificationStatus,
  updateFormAnswerIssueClassifications,
  updateFormAnswerIssueNotes,
  updateFormAnswerWithIssue,
  updateGoalSchedule,
  updateGoalScheduleEndDate,
  updateInstanceSchedule,
  updateInstanceScheduleEndDate,
};
