import { StateObservable } from 'redux-observable';
import {
  concatAll,
  concatMap,
  EMPTY,
  groupBy,
  merge,
  mergeMap,
  Observable,
} from 'rxjs';
import {
  getAnswerChangesObservable,
  handleAnswerChangeAction,
  handleReviewerChangeAction,
  handleSubjectChangeAction,
} from './form-instance/answer/epic.answerChange.ts';
import {
  ReduxFormInstanceAnswerChange,
  ReduxFormInstanceReviewerChange,
  ReduxFormInstanceSubjectChange,
} from './form-instance/types.ts';
import {
  getIssueClassificationsObservable,
  getIssueNotesObservable,
  handleIssueClassificationsChangeAction,
  handleIssueNotesChangeAction,
} from './issue/epic.issueDetails.ts';
import { issueSlice } from './issue/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from './store.ts';

export const processInstanceChangesSerialEpic = (
  action$: Observable<
    | ReduxFormInstanceAnswerChange
    | ReduxFormInstanceSubjectChange
    | ReduxFormInstanceReviewerChange
  >,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) => {
  const pipeline$ = merge(
    getAnswerChangesObservable(action$, state$),
    getIssueNotesObservable(action$, state$),
    getIssueClassificationsObservable(action$, state$),
  );

  return pipeline$.pipe(
    groupBy((action) => action.payload.concurrencyId),
    mergeMap((group) =>
      group.pipe(
        concatMap(async (action) => {
          if (action.type === 'ui.forms/instance/ANSWER/CHANGE')
            return await handleAnswerChangeAction(action, client);

          if (action.type === 'ui.forms/instance/ANSWER/REVIEWER_CHANGE')
            return await handleReviewerChangeAction(action, client);

          if (action.type === 'ui.forms/instance/ANSWER/SUBJECT_CHANGE')
            return await handleSubjectChangeAction(action, client);

          if (issueSlice.setNotes.match(action))
            return await handleIssueNotesChangeAction(action.payload, client);

          if (
            issueSlice.addClassification.match(action) ||
            issueSlice.deleteClassification.match(action) ||
            issueSlice.saveClassifications.match(action)
          )
            return await handleIssueClassificationsChangeAction(
              action.payload,
              client,
              state$.value.formIssue.issues.find(
                (formIssue) => formIssue.answerId === action.payload.answerId,
              ),
            );

          return EMPTY;
        }),
      ),
    ),
    concatAll(),
  );
};
