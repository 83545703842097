import removeMarkdown from 'remove-markdown';
import { replaceWordChars } from './str.replaceWordChars.ts';

export interface IRemoveMarkdownAndSpecialCharactersOptions {
  removeTrailingQuestionMark?: boolean;
  shouldReplaceWordChars?: boolean;
  specialCharactersToRemove?: string[];
}

/**
 * Attempts to remove markdown syntax from the provided input string,
 * as well as removing special characters.
 */
export function removeMarkdownAndSpecialCharacters(
  input: string,
  options: IRemoveMarkdownAndSpecialCharactersOptions = {},
) {
  const {
    removeTrailingQuestionMark = false,
    shouldReplaceWordChars = true,
    specialCharactersToRemove = ['\r', '\n', '&nbsp;'],
  } = options;

  let result = removeMarkdown(input);
  specialCharactersToRemove.forEach((c) => {
    result = result.replace(c, '');
  });

  if (shouldReplaceWordChars) {
    result = replaceWordChars(result);
  }

  if (removeTrailingQuestionMark && result.endsWith('?')) {
    result = result.slice(0, -1);
  }

  return result.trim();
}
