import * as issueSlice from '@seeeverything/ui.forms/src/redux/issue/issueSlice.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  debounceTime,
  filter,
  from,
  map,
} from 'rxjs';
import { FormAnswer } from '../../types/types.ts';
import { automatedActionSlice } from '../automatedAction/index.ts';
import { ReduxFormInstanceAnswerChange } from '../form-instance/types.ts';
import { GlobalFormsState } from '../store.ts';

export function isLoadingOnExistingIssueForAnswerIdChangedEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) =>
      Boolean(
        action.payload.change.type === 'UPDATE' &&
          action.payload.change.fromAnswer &&
          action.payload.change.postChangeQueryIssueId,
      ),
    ),
    map((action) =>
      issueSlice.existingIssueForAnswerIdLoading(
        action.payload.change.fromAnswer.id,
      ),
    ),
  );
}

export function addIssueForOptionsAnswerWithIssueEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(issueSlice.answerChangedWithNewIssueId.match),
    map((action) => {
      const toAnswer = action.payload.toAnswer;

      const issueDefinition = getIssueDefinition(
        action.payload.instanceId,
        toAnswer,
        state$.value,
      );

      const answerId = toAnswer.id;

      const existingAnswerIssue = state$.value.formIssue.issues.find(
        (issue) => issue.answerId === answerId,
      );

      const removeExistingIssue =
        existingAnswerIssue &&
        issueSlice.removeIssueByAnswerId(existingAnswerIssue.answerId);

      const addNewIssue = issueSlice.addIssue({
        id: action.payload.issueId,
        answerId,
        coachingRequirement: issueDefinition.coachingRequirement,
        label: issueDefinition.label,
        formSectionName: issueDefinition.formSectionName,
        formQuestionText: issueDefinition.formQuestionText,
        formAnswerSelected: issueDefinition.formAnswerSelected,
        formLineId: issueDefinition.formLineId,
        parentSectionLineId: issueDefinition.parentSectionLineId,
        notesLabel: issueDefinition.notesLabel,
        notesRequired: issueDefinition.notesRequired,
        classificationOptions: issueDefinition.classification,
        issueCoachingRequired: issueDefinition.coachingRequired,
        notes: existingAnswerIssue?.notes,
        classifications: existingAnswerIssue?.classifications?.length
          ? issueDefinition.classification?.options
              ?.map((classificationDefinition, index) => {
                const copyClassificationToNew =
                  existingAnswerIssue.classifications.some(
                    (existingClassification) =>
                      existingClassification.value === classificationDefinition,
                  );

                return copyClassificationToNew
                  ? { index, value: classificationDefinition }
                  : undefined;
              })
              .filter(Boolean)
          : [],
      });

      return from([removeExistingIssue, addNewIssue].filter(Boolean));
    }),
    concatAll(),
  );
}

export function removeIssueForOptionsAnswerWithoutIssueEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type === 'DELETE') return false;

      const answer = change.toAnswer;

      const issueExists = state$.value.formIssue.issues.some(
        (issue) => issue.answerId === answer.id,
      );
      return (
        issueExists && !getIssueDefinition(instanceId, answer, state$.value)
      );
    }),
    map((action) => {
      const answerId = action.payload.change.toAnswer?.id;

      const existingIssueForAnswerId = state$.value.formIssue.issues.find(
        (issue) => issue.answerId === answerId,
      );

      return from([
        issueSlice.removeIssueByAnswerId(existingIssueForAnswerId.answerId),
        automatedActionSlice.setAnswerAutomatedActions({
          answerId: existingIssueForAnswerId.answerId,
          automatedActions: [],
        }),
      ]);
    }),
    concatAll(),
  );
}

export function removeIssueOnOptionsAnswerHiddenEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type !== 'DELETE') return false;

      const instance = state$.value.formInstance.instances[instanceId];
      if (!instance) return false;

      return state$.value.formIssue.issues.some(
        (issue) => issue.answerId === change.fromAnswer.id,
      );
    }),
    map((action) =>
      issueSlice.removeIssueByAnswerId(action.payload.change.fromAnswer?.id),
    ),
  );
}

export function saveCopiedIssueDetailsOnOptionsAnswerSavedEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    debounceTime(10),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (change.type === 'DELETE') return false;

      return Boolean(
        getIssueDefinition(instanceId, change.toAnswer, state$.value),
      );
    }),
    map((action) => {
      const { instanceId, change } = action.payload;
      const toAnswer = change.toAnswer;

      const existingAnswerIssue = state$.value.formIssue.issues.find(
        (issue) => issue.answerId === toAnswer.id,
      );

      if (!existingAnswerIssue) return EMPTY;

      const setClassifications = existingAnswerIssue.classifications?.length
        ? issueSlice.saveClassifications({
            answerId: toAnswer.id,
            instanceId,
            concurrencyId: change.toAnswer.lineId,
          })
        : undefined;

      const setNotes = existingAnswerIssue.notes
        ? issueSlice.setNotes({
            instanceId,
            answerId: toAnswer.id,
            notes: existingAnswerIssue.notes,
            concurrencyId: change.toAnswer.lineId,
          })
        : undefined;

      return from([setClassifications, setNotes].filter(Boolean));
    }),
    concatAll(),
  );
}

const getIssueDefinition = (
  instanceId: string,
  answer: FormAnswer,
  state: GlobalFormsState,
) => {
  if (answer.type !== 'optionsAnswer') return undefined;
  if (answer.subType !== 'RADIO') return undefined;

  const answerValue = answer.value;
  if (!answerValue) return undefined;

  const instance = state.formInstance.instances[instanceId];
  if (!instance) return undefined;

  const formLineId = answer.lineId;
  const formLine = instance.lines[formLineId];
  if (!formLine) return undefined;
  if (formLine.type !== 'optionsAnswer') return undefined;

  const answerOptions = formLine.options;
  if (!answerOptions?.length) return undefined;

  const answerOption = answerOptions.find(
    (option) => option.id === answerValue,
  );
  return answerOption?.issue;
};
