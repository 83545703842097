import { uploadFileMetadata } from './fileMetadata.ts';
import { IFormFileState, IFormFileAction, IFormFile } from './types.ts';

export const DEFAULT_STATE: IFormFileState = {
  files: {},
  rejections: [],
};

export function reducer(
  state: IFormFileState = DEFAULT_STATE,
  action: IFormFileAction,
): IFormFileState {
  switch (action.type) {
    case 'ui.forms/file/UPLOAD_REJECTED':
      return {
        ...state,
        rejections: action.payload.rejections,
      };

    case 'ui.forms/file/UPLOAD':
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.fileId]: {
            ...uploadFileMetadata(
              action.payload.fileId,
              action.payload.file,
              action.payload.uploadedByName,
            ),
            action: 'uploading',
          },
        },
        rejections: [], // Can clear because Upload happens before Reject.
      };

    case 'ui.forms/file/UPLOADED':
      return setFileState(state, action.payload.fileId, {
        ...action.payload.metadata,
        action: undefined,
        error: undefined,
      });

    case 'ui.forms/file/UPLOAD_ERROR':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        error: action.payload.errorMessage,
      });

    case 'ui.forms/file/DOWNLOAD_REQUEST':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        action: 'downloading',
      });

    case 'ui.forms/file/DOWNLOAD_COMPLETE':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        action: undefined,
        error: undefined,
      });

    case 'ui.forms/file/DOWNLOAD_ERROR':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        error: action.payload.errorMessage,
      });

    case 'ui.forms/file/DELETE_REQUEST':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        action: 'deleting',
      });

    case 'ui.forms/file/DELETED':
      state = { ...state };
      delete state.files[action.payload.fileId];
      return { ...state };

    case 'ui.forms/file/DELETE_ERROR':
      return setFileState(state, action.payload.fileId, {
        ...state.files[action.payload.fileId],
        error: action.payload.errorMessage,
      });

    case 'ui.forms/file/COPY_FROM_FORM_INSTANCE': {
      const files = action.payload.filesMetadata
        .map((file) => {
          return { [file.id]: { ...file } };
        })
        .reduce((acc, file) => ({ ...acc, ...file }), {});

      return {
        ...state,
        files,
        rejections: [],
      };
    }

    default:
      return state;
  }
}

function setFileState(state: IFormFileState, id: string, file: IFormFile) {
  return {
    ...state,
    files: {
      ...state.files,
      [id]: file,
    },
  };
}
