import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormAnswerAutomatedAction } from '@se/data/forms/types.ts';

type FormAnswerId = string;

type AnswerState = {
  isLoading: boolean;
  automatedActions: FormAnswerAutomatedAction[];
};

export type AutomatedActionsState = Record<FormAnswerId, AnswerState>;

const DEFAULT_STATE: AutomatedActionsState = {};

const slice = createSlice({
  name: 'libs/forms/automatedAction',
  initialState: DEFAULT_STATE,
  reducers: {
    initializeForInstance(
      state,
      action: PayloadAction<Record<FormAnswerId, FormAnswerAutomatedAction[]>>,
    ) {
      return Object.entries(action.payload).reduce(
        (acc, [answerId, automatedActions]) => ({
          ...acc,
          [answerId]: { isLoading: false, automatedActions },
        }),
        {},
      );
    },
    loadingAutomatedActionsForAnswer(
      state,
      action: PayloadAction<{ answerId: string }>,
    ) {
      const answerId = action.payload.answerId;
      if (state[answerId]) {
        state[answerId].isLoading = true;
      } else {
        state[answerId] = {
          isLoading: true,
          automatedActions: [],
        };
      }
    },
    setAnswerAutomatedActions(
      state,
      action: PayloadAction<{
        answerId: string;
        automatedActions: FormAnswerAutomatedAction[];
      }>,
    ) {
      state[action.payload.answerId] = {
        isLoading: false,
        automatedActions: action.payload.automatedActions,
      };
    },
    createdAction(
      state,
      action: PayloadAction<{ automatedActionId: string; actionId: string }>,
    ) {
      Object.values(state).forEach((answer) => {
        const automatedAction = answer.automatedActions.find(
          (aa) => aa.id === action.payload.automatedActionId,
        );
        if (automatedAction) {
          automatedAction.createdActionId = action.payload.actionId;
          return;
        }
      });
    },
  },
});

export const {
  createdAction,
  setAnswerAutomatedActions,
  initializeForInstance,
  loadingAutomatedActionsForAnswer,
} = slice.actions;
export const reducer = slice.reducer;
export type State = AutomatedActionsState;
