import { GraphQLFormattedError } from 'graphql';

export type GraphQLError = Error & {
  graphQLErrors?: Array<GraphQLFormattedError>;
  extensions?: {
    errorType: string;
  };
};

/**
 * A type-guard for the ValidationError class.
 * @param {Error} target The target error object to check.
 * @returns {target is ValidationError} True if the object is a ValidationError, false otherwise.
 */
export function isValidationError(
  error: GraphQLError | GraphQLFormattedError,
): error is GraphQLFormattedError {
  if (!error) return false;

  const errorType = error.extensions?.errorType.toString();
  if (errorType === 'VALIDATION') return true;

  const graphqlError = (error as GraphQLError).graphQLErrors?.[0];
  if (!graphqlError) return false;

  return graphqlError.extensions?.['errorType'].toString() === 'VALIDATION';
}

/**
 * Gets the error code from a GraphQL formatted validation error.
 */
export function getValidationErrorCode(error: GraphQLFormattedError) {
  if (!error) return;
  if (!isValidationError(error)) return;

  return error.extensions['errorCode']?.toString();
}
