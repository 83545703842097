/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@seeeverything/ui.primitives/src/components/Accordion/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export interface ICategoryProps {
  children: React.ReactNode;
  id: string;
  title: string;
  overdueLabel?: string;
  isDefaultExpanded: boolean;
}

export const Category: React.FC<ICategoryProps> = ({
  id,
  children,
  title,
  overdueLabel,
  isDefaultExpanded,
}) => {
  return (
    <Accordion id={id} isDefaultExpanded={isDefaultExpanded}>
      <AccordionSummary
        style={styles.accordionSummaryRoot}
        contentStyle={styles.accordionSummaryContent}
      >
        <div
          css={styles.summaryLabelContainer}
          data-test={'forms-actionPlan-goalCategoryLabel'}
        >
          <Text cursor={'pointer'}>{title}</Text>
          {overdueLabel && (
            <Text size={12} color={COLORS.ERROR_RED} cursor={'pointer'}>
              {overdueLabel}
            </Text>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '16px 16px 9px 16px' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  accordionSummaryContent: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionSummaryRoot: {
    cursor: 'pointer',
    '&$expanded': {
      background: '#a0a0a0',
      color: 'white', // Ripple color.
      '&:hover': {
        background: '#aaaaaa',
      },
    },
    // Add goal icon button.
    '&$expanded > $content div div svg': {
      fill: 'white !important',
    },
    '&$expanded > $content > div div': {
      color: 'white',
    },
    // Collapse icon
    '&$expanded > div svg': {
      fill: 'white !important',
    },
    '&:hover': {
      background: color.format(-0.04),
    },
  },
  summaryLabelContainer: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: '1 1 auto',
    cursor: 'pointer',
  }),
};
