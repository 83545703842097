/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/index.ts';
import { ErrorBar } from './ErrorBar.tsx';

export interface IEmptyFilesPanelSmallProps {
  canUpload: boolean;
  onSelectFile: (event: React.MouseEvent<HTMLElement>) => void;
  error?: string;
}

export const EmptyFilesPanelSmall: React.FC<IEmptyFilesPanelSmallProps> = ({
  canUpload,
  onSelectFile,
  error,
}) => {
  const computedStyles = {
    textUploadLink: css({
      ':hover': { textDecoration: 'underline' },
      cursor: 'pointer',
      color: COLORS.BLUE,
    }),
  };

  const elError = canUpload && error && (
    <div css={styles.errorOuter}>
      <ErrorBar error={error} size={'small'} />
    </div>
  );

  const size = canUpload ? 14 : 16;
  const iconColor = canUpload ? '#DEDEDE' : color.format(-0.25);
  const placeholderTextColor = canUpload ? '#808080' : color.format(-0.8);
  const placeholderTextWeight = canUpload ? 500 : 400;

  return (
    <div css={styles.base}>
      <div css={styles.outer}>
        <div css={styles.inner}>
          <Icons.document size={size} fill={iconColor} />
        </div>
        <Text
          size={size}
          color={placeholderTextColor}
          weight={placeholderTextWeight}
        >
          {'There are no files attached.'}
        </Text>
        {canUpload && (
          <>
            <div css={styles.horizontalSpacer} />
            <Text size={14} color={'#808080'} weight={FontWeight.medium}>
              {'Drag files, or'}
            </Text>
            <div css={styles.horizontalSpacer} />
            <div onClick={onSelectFile} css={computedStyles.textUploadLink}>
              <TextLink
                size={14}
                weight={FontWeight.medium}
                cursor={'inherit'}
                block={true}
              >
                {'click here to get started.'}
              </TextLink>
            </div>
          </>
        )}
      </div>
      {elError}
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    justifyContent: 'center',
  }),
  outer: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
    minHeight: 30,
    padding: 10,
  }),
  inner: css({
    paddingTop: 2,
    paddingRight: 4,
  }),
  horizontalSpacer: css({
    width: 3,
  }),
  errorOuter: css({
    margin: '0 10px 10px 10px',
  }),
};
