/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface IBulletDigitalContentProps {
  style?: SerializedStyles;
}

/**
 * A bullet that contains a digital content icon.
 */
export const BulletDigitalContent = ({ style }: IBulletDigitalContentProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css(
        {
          width: 22,
          height: 22,
        },
        style,
      ),
      icon: {
        zIndex: 1,
        position: 'relative',
      } as const,
    }),
    [style],
  );

  return (
    <div css={computedStyles.base}>
      <Icons.collections
        style={computedStyles.icon}
        fill={'#D2D2D2'}
        size={22}
      />
    </div>
  );
};
