import { FormTemplate } from '@se/data/forms/types.ts';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import {
  BulkUploadFormTemplateExported,
  ExportableQuestion,
  ReduxFormBulkUploadDownloadTemplateClear,
  ReduxFormBulkUploadDownloadTemplateExportCancel,
  ReduxFormBulkUploadDownloadTemplateExportClick,
  ReduxFormBulkUploadDownloadTemplateToggleShowHiddenFields,
  ReduxFormBulkUploadSelectionSectionHeading,
  ReduxFormBulkUploadSelectQuestion,
  ReduxFormBulkUploadTemplateChange,
  ReduxFormBulkUploadTemplateDownload,
  ReduxFormBulkUploadTemplateQuestionsLoad,
  ReduxFormBulkUploadTemplateQuestionsLoadError,
  ReduxFormBulkUploadTemplateQuestionsLoadSuccess,
  ReduxFormBulkUploadTemplateQuestionsRetryLoad,
} from './types.ts';

export const templateChange = (
  template: ISelectionListItem<IListItemLabel, FormTemplate>,
): ReduxFormBulkUploadTemplateChange => ({
  type: 'ui.forms/bulk-upload/download-template/TEMPLATE_CHANGE',
  payload: { template },
});

export const templateQuestionsLoad =
  (): ReduxFormBulkUploadTemplateQuestionsLoad => ({
    type: 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOAD',
    payload: {},
  });

export const templateQuestionsLoaded = (
  exportableQuestions: ExportableQuestion[],
): ReduxFormBulkUploadTemplateQuestionsLoadSuccess => ({
  type: 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOADED',
  payload: { exportableQuestions },
});

export const templateQuestionsLoadError =
  (): ReduxFormBulkUploadTemplateQuestionsLoadError => ({
    type: 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOAD_ERROR',
    payload: {},
  });

export const templateQuestionsRetryLoad =
  (): ReduxFormBulkUploadTemplateQuestionsRetryLoad => ({
    type: 'ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_RETRY_LOAD',
    payload: {},
  });

export const downloadTemplate = (): ReduxFormBulkUploadTemplateDownload => ({
  type: 'ui.forms/bulk-upload/download-template/DOWNLOAD_TEMPLATE',
  payload: {},
});

export const clearDownloadTemplate =
  (): ReduxFormBulkUploadDownloadTemplateClear => ({
    type: 'ui.forms/bulk-upload/download-template/CLEAR',
    payload: {},
  });

export const selectSectionHeading = (
  id: string,
  to: boolean,
): ReduxFormBulkUploadSelectionSectionHeading => ({
  type: 'ui.forms/bulk-upload/download-template/SELECT_SECTION_HEADING',
  payload: { id, to },
});

export const selectQuestion = (
  id: string,
  to: boolean,
): ReduxFormBulkUploadSelectQuestion => ({
  type: 'ui.forms/bulk-upload/download-template/SELECT_QUESTION',
  payload: { id, to },
});

export const exportTemplateClick = (
  template: BulkUploadFormTemplateExported,
  selectedQuestions: ExportableQuestion[],
): ReduxFormBulkUploadDownloadTemplateExportClick => ({
  type: 'ui.forms/bulk-upload/download-template/EXPORT_CLICK',
  payload: { template, selectedQuestions },
});

export const exportTemplateCancel =
  (): ReduxFormBulkUploadDownloadTemplateExportCancel => ({
    type: 'ui.forms/bulk-upload/download-template/EXPORT_CANCEL',
    payload: {},
  });

export const toggleShowHiddenFields =
  (): ReduxFormBulkUploadDownloadTemplateToggleShowHiddenFields => ({
    type: 'ui.forms/bulk-upload/download-template/TOGGLE_SHOW_HIDDEN_FIELDS',
    payload: {},
  });
