import { BulkUploadClickThroughColumn } from '../../../components/BulkUploadJobsGrid/types.ts';
import {
  IBulkUploadCreatedInstance,
  ReduxFormsBulkUploadClickThroughLoad,
  ReduxFormsBulkUploadClickThroughLoaded,
  ReduxFormsBulkUploadClickThroughLoadError,
  ReduxFormsBulkUploadClickThroughLoadMore,
  ReduxFormsBulkUploadClickThroughOrderByChanged,
  ReduxFormsBulkUploadCreateClickThrough,
  ReduxFormsBulkUploadFailedClickThroughClicked,
  ReduxFormsBulkUploadSucceededClickThroughClicked,
  ReduxFormsBulkUploadClickThroughLoadMoreError,
} from './types.ts';
import { OrderBy, PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import { FormsBulkUploadValidateFileUploadRow } from '../import-file-validation/types.ts';

export const failedClickThroughClick = (
  jobId: string,
): ReduxFormsBulkUploadFailedClickThroughClicked => ({
  type: 'ui.forms/bulk-upload/click-through/FAILED_CLICKED',
  payload: { jobId },
});

export const succeededClickThroughClick = (
  jobId: string,
): ReduxFormsBulkUploadSucceededClickThroughClicked => ({
  type: 'ui.forms/bulk-upload/click-through/SUCCEEDED_CLICKED',
  payload: { jobId },
});

export const loadClickThrough = (
  jobId: string,
  type: BulkUploadClickThroughColumn,
  isLoadingMore = false,
): ReduxFormsBulkUploadClickThroughLoad => ({
  type: 'ui.forms/bulk-upload/click-through/LOAD',
  payload: { jobId, type, isLoadingMore },
});

export const loadClickThroughError = (
  message: string,
): ReduxFormsBulkUploadClickThroughLoadError => ({
  type: 'ui.forms/bulk-upload/click-through/LOAD_ERROR',
  payload: { message },
});

export const loadedClickThrough = (
  instances:
    | IBulkUploadCreatedInstance[]
    | FormsBulkUploadValidateFileUploadRow[],
  pageInfo: PageInfoResponse,
  hasLoadedMore = false,
): ReduxFormsBulkUploadClickThroughLoaded => ({
  type: 'ui.forms/bulk-upload/click-through/LOADED',
  payload: { instances, pageInfo, hasLoadedMore },
});

export const createClickThrough = (
  jobId: string,
  type: BulkUploadClickThroughColumn,
): ReduxFormsBulkUploadCreateClickThrough => ({
  type: 'ui.forms/bulk-upload/click-through/CREATE',
  payload: { jobId, type },
});

export const orderByChanged = (
  orderBy: OrderBy,
): ReduxFormsBulkUploadClickThroughOrderByChanged => ({
  type: 'ui.forms/bulk-upload/click-through/ORDER_BY_CHANGED',
  payload: { orderBy },
});

export const loadMore = (): ReduxFormsBulkUploadClickThroughLoadMore => ({
  type: 'ui.forms/bulk-upload/click-through/LOAD_MORE',
  payload: {},
});

export const loadMoreClickThroughError = (
  message: string,
): ReduxFormsBulkUploadClickThroughLoadMoreError => ({
  type: 'ui.forms/bulk-upload/click-through/LOAD_MORE_ERROR',
  payload: { message },
});
