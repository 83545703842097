import gql from 'graphql-tag';
import { from, EMPTY, Observable, filter, mergeMap, concatAll } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { ReduxFormInstancePermissionsLoad } from '../types.ts';
import { permissionsLoaded } from './actions.ts';
import { isEditor } from '../../../util/util.instance.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';

export function loadPermissionsEpic(
  action$: Observable<ReduxFormInstancePermissionsLoad>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/instance/PERMISSIONS_LOAD'),
    filter((action) => !isEditor(action.payload.instanceId)),
    mergeMap(async (action) => {
      const { instanceId } = action.payload;

      try {
        const instance = await getInstanceStatusAndPermissions(
          client,
          instanceId,
        );
        if (instance)
          return from([
            permissionsLoaded(
              instanceId,
              instance.permissions,
              instance.status,
            ),
          ]);
      } catch (error) {
        log.error(
          `Unable to query for instance status and permissions for instance with id ${instanceId}`,
          error,
        );
      }

      return EMPTY;
    }),
    concatAll(),
  );
}

const getInstanceStatusAndPermissions = async (
  client: IGraphQLClient,
  instanceId: string,
) => {
  const query = gql`
    query FormInstancePermissions($instanceId: ID!) {
      forms {
        formInstance(id: $instanceId) {
          id
          status
          permissions {
            edit
            archive
            cancel
            assignedToSignOff
            assignedToRevertSignOff
            subjectSignOff
            subjectRevertSignOff
            appeal
          }
        }
      }
    }
  `;

  return (
    await client.query<any>({
      query,
      variables: { instanceId },
      fetchPolicy: 'network-only',
    })
  ).data.forms.formInstance;
};
