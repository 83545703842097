import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { QueryArguments } from '../../../redux/goalsActionsGrid/goalsActionsGridSlice.ts';
import { FormAction, FormGoal } from '../types.ts';
import { createRowData, isGoal } from '../util.ts';
import { queryActionsPage, queryAllActionsPages } from './actions.query.ts';
import { queryAllGoalsPages, queryGoalsPage } from './goals.query.ts';
import { QueryVariables } from './types.ts';

export const fetchGoalsAndActions = async (
  client: IGraphQLClient,
  args: QueryArguments,
  timezone: string,
) => {
  const {
    actionLabel,
    dueByDirection,
    entityId,
    entityType,
    issueActionLabel,
    orderBy,
    sheetType,
    showCompleted,
    startDate,
    endDate,
    module,
  } = args;

  const orderBys = [
    orderBy,
    { fieldName: 'dueBy', direction: dueByDirection },
  ].filter(Boolean);

  const variables: QueryVariables = {
    orderBy: orderBys.filter(({ fieldName }) => fieldName !== 'type'),
    personId: entityType === 'PERSON' ? entityId : undefined,
    teamId: entityType === 'TEAM' ? entityId : undefined,
    status: {
      include: [],
      exclude: showCompleted ? ['Cancelled'] : ['Completed', 'Cancelled'],
    },
    startDate,
    endDate,
  };

  const options = { client, variables, actionLabel, issueActionLabel };

  const goalsResponse =
    sheetType === 'DASHBOARD'
      ? queryGoalsPage(options)
      : queryAllGoalsPages(options);

  const actionsResponse =
    sheetType === 'DASHBOARD'
      ? queryActionsPage(options)
      : queryAllActionsPages(options);

  const results = [
    ...(await goalsResponse).goals,
    ...(await actionsResponse).actions,
  ];

  const sorted = results.sort(
    (a: FormGoal | FormAction, b: FormGoal | FormAction) => {
      for (const field of orderBys) {
        const fieldName =
          field.fieldName === 'type'
            ? 'typeLabel'
            : (field.fieldName as keyof (FormGoal | FormAction));

        if (a[fieldName] < b[fieldName])
          return field.direction === 'Ascending' ? -1 : 1;

        if (a[fieldName] > b[fieldName])
          return field.direction === 'Ascending' ? 1 : -1;
      }
      return 0;
    },
  );

  const goalsPageInfo = (await goalsResponse).pageInfo;
  const actionsPageInfo = (await actionsResponse).pageInfo;
  const hasNextPage = goalsPageInfo.hasNextPage || actionsPageInfo.hasNextPage;

  const records = sheetType === 'DASHBOARD' ? sorted.slice(0, 10) : sorted;
  const includeTypeColumn = module === 'coaching';
  const rowData = (records ?? [])
    .map((record) => {
      const row = createRowData(record, includeTypeColumn, timezone);

      if (!isGoal(record)) return [row];
      if (!record.actions?.length) return [row];

      const childActionRows = record.actions.map((action) =>
        createRowData(action, includeTypeColumn, timezone),
      );
      return [row, ...childActionRows];
    })
    .flat();

  return {
    hasNextPage,
    totalCount: goalsPageInfo.totalCount + actionsPageInfo.totalCount,
    records,
    rowData,
  };
};
