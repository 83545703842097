import { FormActionStatusWithOverdue } from '@se/data/forms/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable } from 'redux-observable';
import { Observable, filter, mergeMap } from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { toLocalDate } from '../utils/dates.ts';
import * as formEditGoalSlice from './formEditGoalSlice.ts';
import {
  FormGoalStatus,
  IFormGoalItem,
  IFormGoalPermissions,
} from './types.ts';

/**
 * Listens for the LOAD action, queries for the form-goal and loads it.
 */
export function loadGoalEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formEditGoalSlice.loadGoal.match),
    mergeMap(async (action) => {
      const { goalId } = action.payload;

      const goal = await queryGoal(client, goalId);
      return goal
        ? formEditGoalSlice.loadedGoal(goal)
        : formEditGoalSlice.loadGoalError();
    }),
  );
}

// Retrieve the data.
const queryGoal = async (
  client: IGraphQLClient,
  goalId: string,
): Promise<IFormGoalItem | undefined> => {
  const response = await client.query<{
    forms: {
      formGoal: {
        id: string;
        assignedTo: { id: string; name: string };
        formInstance: { id?: string };
        description: string;
        status: FormGoalStatus;
        dueBy: string;
        createdAt: string;
        completedAt?: string;
        goalCategory?: { id: string; name: string };
        permissions: IFormGoalPermissions;
        additionalFields?: GoalAdditionalField[];
        actions: Array<{ status: FormActionStatusWithOverdue }>;
      };
    };
  }>({
    query: gql`
      query FormGoal($goalId: ID!) {
        forms {
          formGoal(id: $goalId) {
            id
            assignedTo {
              id
              name
            }
            formInstance {
              id
            }
            description
            status
            dueBy
            createdAt
            completedAt
            goalCategory {
              id
              name
            }
            permissions {
              edit
              cancel
              revert
              close
            }
            additionalFields {
              key
              value
              label
              type
            }
            actions {
              status
            }
          }
        }
      }
    `,
    variables: { goalId },
    fetchPolicy: 'network-only',
  });

  const data = response.data?.forms.formGoal;
  if (!data) {
    log.error(
      new Error(`Failed to retrieve form goal [${goalId}] from graphql.`),
    );
    return undefined;
  }

  return {
    id: data.id,
    description: data.description,
    assignedTo: {
      id: data.assignedTo?.id,
      name: data.assignedTo?.name,
    },
    goalCategory: data.goalCategory,
    formInstanceId: data.formInstance?.id,
    dueBy: data.dueBy ? toLocalDate(data.dueBy) : '',
    createdAt: data.createdAt ? toLocalDate(data.createdAt) : '',
    completedAt: data.completedAt ? toLocalDate(data.completedAt) : '',
    status: { value: data.status },
    permissions: { ...data.permissions },
    additionalFields: data.additionalFields,
    hasActiveChildActions: data.actions.some(
      (action) => action.status === 'Open' || action.status === 'Overdue',
    ),
  };
};
