import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { concatAll, filter, map, mergeMap, Observable, of } from 'rxjs';
import { instanceScheduleSlice } from '../instanceSchedule/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as editInstanceScheduleSlice from './editInstanceScheduleSlice.ts';

export const editInstanceScheduleEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  createInstanceScheduleEpic,
  loadInstanceSchedulesOnScheduleSavedEpic,
  updateNotStartedInstanceScheduleEpic,
  updateStartedInstanceScheduleEpic,
);

function loadInstanceSchedulesOnScheduleSavedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(editInstanceScheduleSlice.saved.match),
    map(() =>
      instanceScheduleSlice.loadInstanceSchedules({ loadNextPage: false }),
    ),
  );
}

function createInstanceScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editInstanceScheduleSlice.save.match),
    filter(() => !state$.value.editInstanceSchedule.original),
    mergeMap(async () => {
      try {
        const draft = state$.value.editInstanceSchedule.draft;

        const fieldErrors = {
          name: draft.name ? undefined : 'This field is required.',
          template: draft.template ? undefined : 'This field is required.',
          frequency: draft.frequency ? undefined : 'This field is required.',
          dueDateExpression: draft.dueDateExpression
            ? undefined
            : 'This field is required.',
          startDate: draft.startDate ? undefined : 'This field is required.',
          endDate:
            draft.endDate < draft.startDate
              ? 'End date must be after start date.'
              : undefined,
          distributionList: draft.distributionList
            ? undefined
            : 'This field is required.',
        };

        const isError = Object.values(fieldErrors).some(Boolean);

        if (isError)
          return of(editInstanceScheduleSlice.setFieldErrors(fieldErrors));

        const scheduleId = uuid.generate();

        const response = await formsMutation.createInstanceSchedule(client, {
          scheduleId,
          updates: draft,
        });

        return response.isSuccess
          ? of(editInstanceScheduleSlice.saved({ reason: 'Created' }))
          : of(
              editInstanceScheduleSlice.setGlobalError(
                'An error occurred while trying to save the schedule. Please try again later.',
              ),
            );
      } catch (err) {
        log.error('Unable to save new instance schedule', err);
        return of(
          editInstanceScheduleSlice.setGlobalError(
            'An error occurred while trying to save the schedule. Please try again later.',
          ),
        );
      }
    }),
    concatAll(),
  );
}

function updateNotStartedInstanceScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editInstanceScheduleSlice.save.match),
    filter(
      () => state$.value.editInstanceSchedule.original?.status === 'NotStarted',
    ),
    mergeMap(async () => {
      const draft = state$.value.editInstanceSchedule.draft;

      try {
        const fieldErrors = {
          name: draft.name ? undefined : 'This field is required.',
          template: draft.template ? undefined : 'This field is required.',
          frequency: draft.frequency ? undefined : 'This field is required.',
          dueDateExpression: draft.dueDateExpression
            ? undefined
            : 'This field is required.',
          startDate: draft.startDate ? undefined : 'This field is required.',
          endDate:
            draft.endDate < draft.startDate
              ? 'End date must be after start date.'
              : undefined,
          distributionList: draft.distributionList
            ? undefined
            : 'This field is required.',
        };

        const isError = Object.values(fieldErrors).some(Boolean);

        if (isError)
          return of(editInstanceScheduleSlice.setFieldErrors(fieldErrors));

        const updateResponse = await formsMutation.updateInstanceSchedule(
          client,
          { scheduleId: draft.id, updates: draft },
        );

        if (!updateResponse.isSuccess)
          return of(
            editInstanceScheduleSlice.setGlobalError(
              'An error occurred while trying to save the schedule. Please try again later.',
            ),
          );

        if (draft.status !== 'Inactive')
          return of(editInstanceScheduleSlice.saved({ reason: 'Updated' }));

        const deactivateResponse =
          await formsMutation.deactivateInstanceScheduleStatus(
            client,
            draft.id,
          );

        return deactivateResponse.isSuccess
          ? of(editInstanceScheduleSlice.saved({ reason: 'Deactivated' }))
          : of(
              editInstanceScheduleSlice.setGlobalError(
                'There was a problem deactivating the schedule. Please try again later.',
              ),
            );
      } catch (err) {
        log.error(`Unable to update instance schedule ${draft.id}`, err);
        return of(
          editInstanceScheduleSlice.setGlobalError(
            'An error occurred while trying to save the schedule. Please try again later.',
          ),
        );
      }
    }),
    concatAll(),
  );
}

function updateStartedInstanceScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(editInstanceScheduleSlice.save.match),
    filter(
      () => state$.value.editInstanceSchedule.original?.status === 'Started',
    ),
    mergeMap(async () => {
      const draft = state$.value.editInstanceSchedule.draft;
      const original = state$.value.editInstanceSchedule.original;

      try {
        if (draft.endDate < draft.startDate)
          return of(
            editInstanceScheduleSlice.setFieldErrors({
              endDate: 'End date must be after start date.',
            }),
          );

        if (draft.endDate !== original.endDate) {
          const updateEndDateResponse =
            await formsMutation.updateInstanceScheduleEndDate(client, {
              scheduleId: draft.id,
              endDate: draft.endDate,
            });

          if (!updateEndDateResponse.isSuccess) {
            return of(
              editInstanceScheduleSlice.setGlobalError(
                'An error occurred while trying to save the end date on the schedule. Please try again later.',
              ),
            );
          }
        }

        if (draft.status !== 'Inactive')
          return of(editInstanceScheduleSlice.saved({ reason: 'Updated' }));

        const deactivateResponse =
          await formsMutation.deactivateInstanceScheduleStatus(
            client,
            draft.id,
          );

        return deactivateResponse.isSuccess
          ? of(editInstanceScheduleSlice.saved({ reason: 'Deactivated' }))
          : of(
              editInstanceScheduleSlice.setGlobalError(
                'There was a problem deactivating the schedule. Please try again later.',
              ),
            );
      } catch (err) {
        log.error(`Unable to update instance schedule ${draft.id}`, err);
        return of(
          editInstanceScheduleSlice.setGlobalError(
            'An error occurred while trying to save the schedule. Please try again later.',
          ),
        );
      }
    }),
    concatAll(),
  );
}
