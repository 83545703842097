/** @jsxImportSource @emotion/react */
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { is } from 'ramda';
import { useCallback, useMemo } from 'react';
import { SelectAnswerOptionDefinition } from '../../parse/types/input.types.ts';
import { formInstanceAnswerChange } from '../../redux/form-instance/answer/actions.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { FormAnswer, ISelectAnswerLine } from '../../types/types.ts';
import { SelectAnswer } from '../SelectAnswer/SelectAnswer.tsx';

export interface ISelectAnswerContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  isVisible: boolean;
  line: ISelectAnswerLine;
}

export const SelectAnswerContainer: React.FC<ISelectAnswerContainerProps> = ({
  canUpdateInstance,
  instanceId,
  isPdfExport,
  isVisible,
  line,
}) => {
  const dispatch = useFormsDispatch();

  const newAnswerId = useMemo(() => {
    if (!isVisible) return;
    return uuid.generate();
  }, [isVisible]);

  const answer = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id];
  });

  const answerValueId = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    return instance?.answers?.[line.id]?.value?.toString();
  });

  const error = useFormsSelector(
    (state) =>
      state.formInstance.instances[instanceId]?.questionErrors?.[line.id],
  );

  const updateAnswer = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      if (!isVisible) return;

      const toAnswer: FormAnswer = {
        id: answer?.id ?? newAnswerId,
        value: to.id.toString(),
        lineId: line.id,
        type: 'inputs',
        subType: 'dropdownAnswer',
        displayValue: to.content?.text?.toString(),
        group: line.group,
      };

      dispatch(
        formInstanceAnswerChange(
          instanceId,
          answer?.id
            ? {
                type: 'UPDATE',
                toAnswer,
                fromAnswer: answer,
              }
            : {
                type: 'CREATE',
                toAnswer,
                creationPreviouslyFailed: Boolean(error),
              },
          true,
        ),
      );
    },
    [
      answer,
      dispatch,
      error,
      instanceId,
      isVisible,
      line.group,
      line.id,
      newAnswerId,
    ],
  );

  const selections = useMemo(() => {
    return line.options.map((option) =>
      isRichSelectOption(option)
        ? {
            id: option.id ?? option.label,
            icon: option.icon,
            content: {
              text: option.label ?? option.id,
              description: option.description,
            },
            value: option.id ?? option.label,
          }
        : { id: option, content: { text: option }, value: option },
    );
  }, [line.options]);

  return (
    <SelectAnswer
      error={error}
      floatingText={line.floatingText}
      helperText={line.helperText}
      id={line.id}
      isEnabled={canUpdateInstance}
      onChange={updateAnswer}
      renderText={isPdfExport}
      selections={selections}
      value={answerValueId}
    />
  );
};

const isRichSelectOption = (
  option: string | SelectAnswerOptionDefinition,
): option is SelectAnswerOptionDefinition => !is(String, option);
