import { FormActionPlanAction, FormIssue } from '@se/data/forms/types.ts';
import { GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

export const complianceIssueValidationErrorActions = (
  state: GlobalFormsState,
) => {
  if (state.tenantState.tenant?.module !== 'compliance') return;
  if (!state.formIssue.issues.length) return;

  return state.formIssue.issues
    .map((issue) => {
      const notesMissing = Boolean(!issue.notes && issue.notesRequired);
      const classificationMissing = Boolean(
        issue.classificationOptions?.required &&
          issue.classificationOptions?.options?.length &&
          !issue.classifications?.length,
      );

      if (!notesMissing && !classificationMissing) return undefined;

      return issueSlice.complianceErrors({
        answerId: issue.answerId,
        classification: classificationMissing ? 'This is required.' : undefined,
        notes: notesMissing ? 'This is required.' : undefined,
      });
    })
    .filter(Boolean);
};

export const complianceIssueErrorCount = (
  state: issueSlice.IssueState,
  parentSectionLineId: string,
) => {
  if (!state) return 0;
  if (!parentSectionLineId) return 0;
  if (!state.issues.length) return 0;

  const issues = state.issues.filter(
    (issue) => issue.parentSectionLineId === parentSectionLineId,
  );
  if (!issues?.length) return 0;

  const notesErrors = issues.reduce(
    (errorCount, issue) =>
      issue.errors.compliance.notes ? errorCount + 1 : errorCount,
    0,
  );
  const classificationErrors = issues.reduce(
    (errorCount, issue) =>
      issue.errors.compliance.classifications ? errorCount + 1 : errorCount,
    0,
  );

  return notesErrors + classificationErrors;
};

export const coachingIssueErrorCount = (
  state: issueSlice.IssueState,
  parentSectionLineId: string,
) => {
  if (!state) return 0;
  if (!parentSectionLineId) return 0;
  if (!state.issues.length) return 0;

  const validateCoachingRequired =
    parentSectionLineId === 'issueCoachingPlan-coachingRequired';

  const validateCoachingRecommended =
    parentSectionLineId === 'issueCoachingPlan-coachingRecommended';

  if (!validateCoachingRequired && !validateCoachingRecommended) return 0;

  const isCoachingRecommendedIssue = (issue: FormIssue) =>
    validateCoachingRecommended &&
    issue.coaching.requirement === 'RECOMMENDED' &&
    issue.coaching.specifyCauseAndCoaching.isSelected;

  const isCoachingRequiredIssue = (issue: FormIssue) =>
    validateCoachingRequired && issue.coaching.requirement === 'MANDATORY';

  const issues = state.issues.filter(
    (issue) =>
      isCoachingRequiredIssue(issue) || isCoachingRecommendedIssue(issue),
  );
  if (!issues?.length) return 0;

  return issues.reduce(
    (errorCount, issue) =>
      errorCount + Object.values(issue.errors.coaching).filter(Boolean).length,
    0,
  );
};

export const issueCoachingPlanValidationErrorActions = (
  instanceId: string,
  state: GlobalFormsState,
) => {
  if (!instanceId) return;
  if (!state) return;

  const instance = state.formInstance.instances[instanceId];
  if (!instance) return;
  if (!state.formIssue.issues.length) return;

  return state.formIssue.issues
    .filter((issue) => issue.coaching.specifyCauseAndCoaching.isSelected)
    .map((issue) => {
      const issueActions = Object.values(state.formActionPlanV2.actions).filter(
        (action) => action.issueId === issue.id,
      );

      const primaryCause = primaryCauseRequired(issue);
      const primaryCauseNotes = primaryCauseNotesRequired(issue);
      const coachingConversation = coachingConversationRequired(issue);
      const actionsRequiredSelection = actionsRequiredSelectionRequired(issue);
      const noActionsReason = noActionsReasonRequired(issue);
      const coachingIssueAction = coachingIssueActionRequired(
        issue,
        issueActions,
      );
      const noOpenActionsWithNoActionSelected =
        noOpenActionsWithNoActionSelectedRequired(issue, issueActions);

      if (
        !primaryCause &&
        !primaryCauseNotes &&
        !coachingConversation &&
        !actionsRequiredSelection &&
        !noActionsReason &&
        !coachingIssueAction &&
        !noOpenActionsWithNoActionSelected
      )
        return;

      const validationMessage = (required?: boolean) =>
        required ? 'This is required.' : undefined;

      return issueSlice.coachingErrors({
        issueId: issue.id,
        primaryCause: validationMessage(primaryCause),
        primaryCauseNotes: validationMessage(primaryCauseNotes),
        coachingConversation: validationMessage(coachingConversation),
        actionsRequiredSelection: validationMessage(actionsRequiredSelection),
        noActionsReason: validationMessage(noActionsReason),
        coachingIssueAction: validationMessage(coachingIssueAction),
        noOpenActionsWithNoActionSelected: validationMessage(
          noOpenActionsWithNoActionSelected,
        ),
      });
    })
    .filter(Boolean);
};

const primaryCauseRequired = (issue: FormIssue) =>
  !issue.issueCauses.causes.some((cause) => cause.isPrimary);

const coachingConversationRequired = (issue: FormIssue) =>
  !issue.coachingConversation;

const primaryCauseNotesRequired = (issue: FormIssue) =>
  Boolean(
    issue.issueCauses.causes.some((cause) => cause.isPrimary && !cause.notes),
  );

const actionsRequiredSelectionRequired = (issue: FormIssue) =>
  issue.actionsRequired !== true && issue.actionsRequired !== false;

const noActionsReasonRequired = (issue: FormIssue) =>
  issue.actionsRequired === false && !issue.noActionsReason;

const coachingIssueActionRequired = (
  issue: FormIssue,
  actions: FormActionPlanAction[],
) =>
  issue.actionsRequired === true &&
  !actions.some((action) => action.status !== 'Cancelled');

const noOpenActionsWithNoActionSelectedRequired = (
  issue: FormIssue,
  actions: FormActionPlanAction[],
) =>
  issue.actionsRequired === false &&
  actions.some((action) => ['Open', 'Overdue'].includes(action.status));
