/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import {
  AppealEventHandler,
  FormInstanceStatus,
  IFormInstanceSignOff,
  RevertSignoffEventHandler,
  SignoffEventHandler,
} from '../../../redux/form-instance/types.ts';
import { SignOffButton } from './SignOffButton.tsx';

export interface ISignoffParticipant {
  authorisedByName?: string;
  canAppealAsManager: boolean;
  canAppealAsSubject: boolean;
  cannotAppeal: boolean;
  canRevert: boolean;
  canSignOff: boolean;
  errorPreventSignOff?: string;
  instanceStatus: FormInstanceStatus;
  onAppealClick?: AppealEventHandler;
  onlyManagerCanAppeal: boolean;
  onRevertSignoffClick: RevertSignoffEventHandler;
  onSignoffClick: SignoffEventHandler;
  participantDescription?: string;
  participantId?: string;
  participantName?: string;
  reviewerSignOff?: IFormInstanceSignOff;
  signOff?: IFormInstanceSignOff;
  signOffRequired: boolean;
  type: 'REVIEWER' | 'SUBJECT';
  typeLabel: string;
}

export const SignoffParticipant = ({
  authorisedByName,
  canAppealAsManager,
  canAppealAsSubject,
  cannotAppeal,
  canRevert,
  canSignOff,
  errorPreventSignOff,
  instanceStatus,
  onAppealClick,
  onlyManagerCanAppeal,
  onRevertSignoffClick,
  onSignoffClick,
  participantDescription,
  participantId,
  participantName,
  reviewerSignOff,
  signOff,
  signOffRequired,
  type,
  typeLabel,
}: ISignoffParticipant) => {
  const handleSignOff = useCallback(
    () => onSignoffClick?.({ personType: type, personId: participantId }),
    [onSignoffClick, participantId, type],
  );

  const handleAppeal = useCallback(
    () => onAppealClick({ personType: type, personId: participantId }),
    [onAppealClick, participantId, type],
  );

  const handleRevertSignOff = useCallback(
    () => onRevertSignoffClick({ personType: type, personId: participantId }),
    [onRevertSignoffClick, participantId, type],
  );

  const label = useMemo(() => {
    if (!signOff && !canSignOff && signOffRequired) return 'Pending';
    if (signOff) return 'Signed-Off';
    if (instanceStatus === 'Appealed' && canSignOff && signOffRequired)
      return 'Cancel Appeal';

    if (cannotAppeal) return 'Sign-Off';
    return 'Respond';
  }, [canSignOff, cannotAppeal, instanceStatus, signOff, signOffRequired]);

  const elSignoffButton = signOffRequired && (
    <SignOffButton
      canAppealAsManager={canAppealAsManager}
      canAppealAsSubject={canAppealAsSubject}
      errorTooltip={errorPreventSignOff}
      instanceStatus={instanceStatus}
      label={label}
      onAppeal={handleAppeal}
      onlyManagerCanAppeal={reviewerSignOff && onlyManagerCanAppeal}
      onRevert={handleRevertSignOff}
      onSignoff={handleSignOff}
      showAppeal={!cannotAppeal}
      showRevert={canRevert}
      showSignOff={canSignOff}
      signOff={signOff}
    />
  );

  const elNotRequired = !signOff && !signOffRequired && (
    <Text size={12} color={-0.3} italic={true}>
      {'Sign-off not required.'}
    </Text>
  );

  const displayName = authorisedByName ?? participantName ?? typeLabel;
  const subtitle = authorisedByName
    ? `on behalf of ${participantName}`
    : participantDescription;

  const elSignedOffAt = signOff?.occurredAt && (
    <Text size={12} color={-0.4}>
      {`Signed off at ${moment(signOff.occurredAt).format('h:mma')}, ${moment(
        signOff.occurredAt,
      ).format('D MMM YY')}`}
    </Text>
  );

  return (
    <div css={styles.base}>
      <div css={styles.personBadge}>
        <Icons.accountCircle size={48} fill={color.format(-0.35)} />
      </div>
      <div css={styles.bodyOuter}>
        <Text
          uppercase={true}
          opacity={0.2}
          weight={FontWeight.black}
          size={12}
          textShadow={[-1, -0.2]}
          style={styles.typeLabel}
        >
          {typeLabel}
        </Text>
        <Text opacity={0.6} size={20} ellipsis={true}>
          {displayName}
        </Text>
        <Text opacity={0.6} size={14} style={styles.title} ellipsis={true}>
          {subtitle}
        </Text>
        <div css={styles.actionOuter}>
          {elSignoffButton}
          {elNotRequired}
          {elSignedOffAt}
        </div>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    gap: 15,
    flex: '1 1 280px',
    minWidth: 280,
    justifyContent: 'center',
  }),
  bodyOuter: css({
    maxWidth: 205,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  }),
  personBadge: css({
    marginTop: 5,
    boxSizing: 'border-box',
    border: `solid 4px ${color.format(-0.08)}`,
    borderRadius: 54,
    width: 54,
    height: 54,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  title: css({
    paddingLeft: 2,
  }),
  actionOuter: css({
    marginTop: 15,
  }),
  typeLabel: css({
    marginBottom: 4,
  }),
};
