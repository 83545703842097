import { formsQuery } from '@se/data/forms/query/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map, mergeMap } from 'rxjs';
import { hasIssueCoaching, isEditor } from '../../util/util.instance.ts';
import {
  ReduxFormInstanceServerLoaded,
  ReduxFormInstanceSubjectChange,
  ReduxFormInstanceSubjectChanged,
} from '../form-instance/types.ts';
import { formActionSlice } from '../formAction/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as issueSlice from './issueSlice.ts';

export function createCoachingIssueActionCompleteEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) => {
      if (!action.payload.isSuccess) return false;
      if (action.payload.operation !== 'CREATE') return false;
      if (!action.payload.updated) return false;
      if (!action.payload.updated.issue) return false;

      const instanceId = action.payload.instanceId;
      if (isEditor(instanceId)) return false;

      const instance = state$.value.formInstance.instances[instanceId];
      return hasIssueCoaching(instance);
    }),
    map((action) =>
      issueSlice.addCoachingIssueActionToIssue({
        issueId: action.payload.updated.issue.id,
        instanceId: action.payload.instanceId,
      }),
    ),
  );
}

export function updateCoachingIssueActionCompleteEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) => {
      if (!action.payload.isSuccess) return false;
      if (action.payload.operation !== 'UPDATE') return false;

      const updatedAction = action.payload.updated;
      if (!updatedAction) return false;
      if (!updatedAction.issue) return false;

      const instanceState = Object.entries(
        state$.value.formInstance.instances,
      )?.[0];
      if (!instanceState) return false;

      const [instanceId, instance] = instanceState;
      if (!instanceId) return false;
      if (!instance) return false;
      if (isEditor(instanceId)) return false;

      return hasIssueCoaching(instance);
    }),
    map((action) =>
      issueSlice.updateCoachingIssueActionOnIssue({
        issueId: action.payload.updated.issue.id,
        instanceId: action.payload.instanceId,
      }),
    ),
  );
}

export function setIsLoadingOnCoachingPlanEpic(
  action$: Observable<ReduxFormInstanceSubjectChange>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGE'),
    filter((action) => {
      const instanceId = action.payload.instanceId;
      if (isEditor(instanceId)) return false;

      if (action.payload.change.type === 'DELETE') return false;

      const instance = state$.value.formInstance.instances[instanceId];
      return hasIssueCoaching(instance);
    }),
    map(() => issueSlice.loadIssues()),
  );
}

export function loadCoachingPlanIssuesOnSubjectChangedEpic(
  action$: Observable<ReduxFormInstanceSubjectChanged>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/SUBJECT_CHANGED'),
    filter((action) => {
      const { instanceId, change } = action.payload;
      if (isEditor(instanceId)) return false;

      if (change.type === 'DELETE') return false;
      if (change.toSubject.kind !== 'Person') return false;

      const instance = state$.value.formInstance.instances[instanceId];
      return hasIssueCoaching(instance);
    }),
    map((action) =>
      issueSlice.loadIssueCoachingPlan(action.payload.instanceId),
    ),
  );
}

export function clearCoachingPlanIssuesOnInstanceLoadEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    filter(
      (action) =>
        !isEditor(action.payload.instanceId) &&
        !hasIssueCoaching(action.payload.instance),
    ),
    map(() => issueSlice.clearIssues()),
  );
}

export function loadCoachingPlanIssuesOnInstanceLoadEpic(
  action$: Observable<ReduxFormInstanceServerLoaded>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/SERVER_LOADED'),
    filter(
      (action) =>
        !isEditor(action.payload.instanceId) &&
        hasIssueCoaching(action.payload.instance),
    ),
    map((action) =>
      from([
        issueSlice.loadIssues(),
        issueSlice.loadIssueCoachingPlan(action.payload.instanceId),
      ]),
    ),
    concatAll(),
  );
}

export function loadCoachingPlanIssuesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(issueSlice.loadIssueCoachingPlan.match),
    filter((action) => !isEditor(action.payload)),
    mergeMap(async (action) => {
      const coachingInstanceId = action.payload;

      const issuesResponse = await formsQuery.getFormAnswerIssues(
        client,
        coachingInstanceId,
      );

      if (!issuesResponse.isSuccess) return issueSlice.loadError();

      return issueSlice.loadedIssues({
        canView: issuesResponse.data.canView,
        issues: issuesResponse.data.issues,
        issueCauses: issuesResponse.data.issueCauses,
      });
    }),
  );
}
