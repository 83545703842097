/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ErrorRetry } from '@seeeverything/ui.primitives/src/components/ErrorRetry/index.ts';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { FormActionPlanContainer } from '../components/FormActionPlan/FormActionPlanContainer.tsx';
import { FormListItemWrapped } from '../components/FormListItem/FormListItemWrapped.tsx';
import { SectionTitle } from '../components/SectionTitle/SectionTitle.tsx';
import { useHandleAnswerVisibility } from '../hooks/useHandleAnswerVisibility.ts';
import { formActionPlanSlice } from '../redux/formActionPlan/index.ts';
import { useFormsDispatch, useFormsSelector } from '../redux/store.ts';
import { IActionPlanLine } from '../types/types.ts';

export interface IActionPlanFactoryContainerProps {
  canUpdateInstance: boolean;
  instanceId: string;
  isPdfExport: boolean;
  line: IActionPlanLine;
}

export const ActionPlanFactoryContainer: React.FC<
  IActionPlanFactoryContainerProps
> = ({ instanceId, isPdfExport, line }) => {
  const dispatch = useFormsDispatch();

  const isVisible = useHandleAnswerVisibility({
    instanceId,
    questionKeys: [],
    showOnAnswer: line.showOnAnswer,
    showOnReportingDateMonth: line.showOnReportingDateMonth,
    showOnSwitch: line.showOnSwitch,
  });

  const handleLoadActionPlan = useCallback(
    () => dispatch(formActionPlanSlice.loadActionPlan({ instanceId })),
    [dispatch, instanceId],
  );

  const isLoading = useFormsSelector(
    (state) => state.formActionPlanV2.isLoading,
  );

  const isLoadError = useFormsSelector(
    (state) => state.formActionPlanV2.loadError,
  );

  return (
    <FormListItemWrapped
      bullet={line.isDetachable ? 'none' : 'block'}
      bulletLeft={line.isDetachable ? undefined : 0}
      bulletTop={line.isDetachable ? undefined : -12}
      highlightOnHover={false}
      id={'actionPlan'}
      isPdfExport={isPdfExport}
      key={`actionPlan-${instanceId}-${line.id}`}
      parentId={line.parentId}
      marginTop={0}
      marginBottom={15}
      isVisible={line.isEnabled && isVisible}
    >
      {!line.isDetachable && (
        <div css={css({ marginTop: 60, paddingBottom: 40 })}>
          <SectionTitle text={line.title} />
        </div>
      )}
      {isLoadError && (
        <ErrorRetry
          onClick={handleLoadActionPlan}
          message={
            'There was a problem loading your action plan. Click here to try again.'
          }
          style={styles.error}
        />
      )}
      {!isLoadError && isLoading && (
        <SkeletonDetailedItemsList numberOfSkeletonItems={3} />
      )}
      {!isLoadError && !isLoading && (
        <FormActionPlanContainer
          instanceId={instanceId}
          typeFilter={line.actionPlanType}
          includeCategories={line.includeCategories}
          isPdfExport={isPdfExport}
        />
      )}
    </FormListItemWrapped>
  );
};

const styles = {
  error: {
    border: `dashed 1px ${COLORS.ORANGE_DARK}`,
    borderRadius: 3,
    backgroundColor: COLORS.ORANGE_TRANSPARENT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0',
  },
};
