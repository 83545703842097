import { isAnyOf } from '@reduxjs/toolkit';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import * as automatedActionSlice from '../automatedAction/automatedActionSlice.ts';
import { ReduxFormInstanceAnswerChange } from '../form-instance/types.ts';
import { formActionSlice } from '../formAction/index.ts';
import { issueSlice } from '../issue/index.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export const automatedActionEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  automatedActionCreatedEpic,
  loadingAutomatedActionOnAnswerChangeEpic,
  loadingAutomatedActionOnClassificationChangeEpic,
);

function loadingAutomatedActionOnAnswerChangeEpic(
  action$: Observable<ReduxFormInstanceAnswerChange>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/ANSWER/CHANGE'),
    map((action) =>
      automatedActionSlice.loadingAutomatedActionsForAnswer({
        answerId:
          action.payload.change.type === 'CREATE'
            ? action.payload.change.toAnswer.id
            : action.payload.change.fromAnswer.id,
      }),
    ),
  );
}

function loadingAutomatedActionOnClassificationChangeEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        issueSlice.addClassification.match,
        issueSlice.deleteClassification.match,
        issueSlice.saveClassifications.match,
      ),
    ),
    map((action) =>
      automatedActionSlice.loadingAutomatedActionsForAnswer({
        answerId: action.payload.answerId,
      }),
    ),
  );
}

function automatedActionCreatedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    filter((action) => {
      return action.payload.isSuccess && action.payload.operation === 'CREATE';
    }),
    map((action) => {
      return automatedActionSlice.createdAction({
        automatedActionId: action.payload.updated.answerAutomatedActionId,
        actionId: action.payload.actionId,
      });
    }),
  );
}
