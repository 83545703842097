import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import momentTz from 'moment-timezone';
import { QueryResult } from '../../types.ts';
import {
  FormActionPlanAction,
  FormActionPlanGoal,
  FormActionStatus,
  FormActionStatusWithOverdue,
  FormGoalStatus,
} from '../types.ts';

export type FormInstanceActionPlanDto = {
  actions: FormActionPlanAction[];
  goals: FormActionPlanGoal[];
};

type ActionResponse = {
  id: string;
  assignedTo: { id: string; name: string };
  status: FormActionStatusWithOverdue;
  description?: string;
  dueBy?: string;
  formInstanceId?: string;
  completedAt?: string;
  createdAt: string;
  issueId?: string;
  goalId?: string;
};

type GoalResponse = {
  id: string;
  assignedTo: { id: string; name: string };
  formInstance: { id: string };
  description: string;
  status: FormGoalStatus;
  dueBy: string;
  createdAt: string;
  completedAt: string;
  actions: Array<{
    id: string;
    assignedTo: { id: string; name: string };
    description: string;
    dueBy: string;
    formInstanceId: string;
    status: FormActionStatus;
    completedAt: string;
    createdAt: string;
    issueId?: string;
  }>;
  goalCategory: { id: string; name: string };
};

export const getFormInstanceActionPlan = async (
  client: IGraphQLClient,
  instanceId: string,
  personId: string,
  timezone: string,
): Promise<QueryResult<FormInstanceActionPlanDto>> => {
  try {
    const response = await client.query<{
      personGoals: { formGoals: { nodes: GoalResponse[] } };
      instanceGoals: { formGoals: { nodes: GoalResponse[] } };
      instanceActions: {
        formInstance: { actions: { nodes: ActionResponse[] } };
      };
      personActions: {
        formActions: { nodes: ActionResponse[] };
      };
    }>({
      query: gql`
        query FormInstanceActionPlan($personId: ID!, $instanceId: ID!) {
          personGoals: forms {
            formGoals(
              assignedToPersonId: $personId
              pagination: { size: 100, pageNumber: 1 }
            ) {
              nodes {
                ...GoalFragment
              }
            }
          }
          instanceGoals: forms {
            formGoals(
              formInstanceId: $instanceId
              pagination: { size: 100, pageNumber: 1 }
            ) {
              nodes {
                ...GoalFragment
              }
            }
          }
          instanceActions: forms {
            formInstance(id: $instanceId) {
              id
              actions(hasParentGoal: false) {
                nodes {
                  id
                  assignedTo {
                    id
                    name
                  }
                  completedAt
                  createdAt
                  description
                  dueBy
                  formInstanceId
                  issueId
                  status
                }
              }
            }
          }
          personActions: forms {
            formActions(hasParentGoal: false, assignedToPersonId: $personId) {
              nodes {
                id
                assignedTo {
                  id
                  name
                }
                completedAt
                createdAt
                description
                dueBy
                formInstanceId
                issueId
                status
              }
            }
          }
        }

        fragment GoalFragment on FormGoal {
          id
          assignedTo {
            id
            name
          }
          formInstance {
            id
          }
          description
          status
          dueBy
          createdAt
          completedAt
          actions {
            id
            assignedTo {
              id
              name
            }
            description
            dueBy
            formInstanceId
            status
            completedAt
            createdAt
          }
          goalCategory {
            id
            name
          }
        }
      `,
      variables: { instanceId, personId },
      fetchPolicy: 'network-only',
    });

    if (!response.data) {
      log.error(
        `Failed to retrieve form action plan for instance ${instanceId} and person id ${personId}`,
      );
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    const instanceActions =
      response.data.instanceActions.formInstance.actions.nodes;

    const personActions = response.data.personActions.formActions.nodes;

    const personGoalActions = response.data.personGoals.formGoals.nodes
      .map((goal) => goal.actions.map((a) => ({ ...a, goalId: goal.id })))
      .flat();

    const instanceGoalActions = response.data.instanceGoals.formGoals.nodes
      .map((goal) => goal.actions.map((a) => ({ ...a, goalId: goal.id })))
      .flat();

    const responseActions = [
      ...personActions,
      ...instanceActions,
      ...personGoalActions,
      ...instanceGoalActions,
    ]
      .filter(Boolean)
      .sort((a, b) => {
        if (['Completed', 'Cancelled'].includes(a.status)) return 1;
        if (['Completed', 'Cancelled'].includes(b.status)) return -1;

        if (!a.dueBy && !b.dueBy) return a.description < b.description ? -1 : 1;

        if (!a.dueBy) return -1;
        if (!b.dueBy) return 1;

        return momentTz(a.dueBy).tz(timezone) < momentTz(b.dueBy).tz(timezone)
          ? -1
          : 1;
      })
      .map((action) => ({
        id: action.id,
        description: action.description,
        assignedToName: action.assignedTo?.name,
        completedAt: action?.completedAt,
        createdAt: action?.createdAt,
        dueBy: action.dueBy
          ? momentTz(action.dueBy).tz(timezone).toISOString()
          : undefined,
        formInstance: action.formInstanceId && {
          id: action.formInstanceId,
          templateName: '',
        },
        status: action.status,
        issueId: action.issueId,
        goalId: action.goalId,
      }));

    const responseGoals = [
      ...response.data.personGoals.formGoals.nodes,
      ...response.data.instanceGoals.formGoals.nodes,
    ]
      .sort((a, b) => {
        if (['Completed', 'Cancelled'].includes(a.status)) return 1;
        if (['Completed', 'Cancelled'].includes(b.status)) return -1;

        if (!a.dueBy && !b.dueBy) return a.description < b.description ? -1 : 1;

        if (!a.dueBy) return -1;
        if (!b.dueBy) return 1;

        return momentTz(a.dueBy) < momentTz(b.dueBy) ? -1 : 1;
      })
      .map((goal) => ({
        id: goal.id,
        assignedToName: goal.assignedTo?.name,
        formInstance: goal.formInstance && {
          id: goal.formInstance.id,
          templateName: '',
        },
        description: goal.description,
        status: goal.status,
        dueBy: goal.dueBy
          ? momentTz(goal.dueBy).tz(timezone).toISOString()
          : undefined,
        createdAt: goal.createdAt,
        completedAt: goal.completedAt,
        goalCategory: goal.goalCategory,
      }));

    return {
      isSuccess: true,
      data: {
        actions: responseActions,
        goals: responseGoals,
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form instance action plan instance id ${instanceId} person id ${personId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
