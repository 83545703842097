import {
  getReadableSize,
  getExtension,
} from '@seeeverything/ui.util/src/file/file.ts';
import { IFormRejectedFile } from './types.ts';
import moment from 'moment';

export const rejectedFileMetadata =
  (maxSize: number) =>
  (metadata: File): IFormRejectedFile => {
    return {
      name: metadata.name,
      extension: getExtension(metadata.name, undefined),
      size: metadata.size,
      displaySize: getReadableSize(metadata.size),
      reason: metadata.size > maxSize ? 'size' : 'type',
    };
  };

export function uploadedFileMetadata(
  id: string,
  file: File,
  uploadedByName: string,
) {
  return {
    ...coreFileMetadata(id, file, uploadedByName),
    canDelete: true,
    canDownload: true,
    uploadedDate: moment().toISOString(),
  };
}

export function uploadFileMetadata(
  id: string,
  file: File,
  uploadedByName: string,
) {
  return {
    ...coreFileMetadata(id, file, uploadedByName),
    canDelete: false,
    canDownload: false,
  };
}

function coreFileMetadata(id: string, metadata: File, uploadedByName: string) {
  return {
    id,
    displaySize: getReadableSize(metadata.size),
    size: metadata.size,
    extension: getExtension(metadata.name),
    name: metadata.name,
    uploadedByName,
  };
}
