/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useMemo, useState } from 'react';
import { FilterField } from './components/FilterField.tsx';

export type PreviousResponse = {
  heading: string;
  description: string;
};

export interface IPreviousResponsesDropdownProps {
  children: React.ReactNode;
  dropdownTitle?: string;
  emptyMessage?: string;
  isFilterable?: boolean;
  isLoading?: boolean;
  linkLabel: string;
  onAnswersDropdownShow?: () => void;
  onFilterChange?: (to: string) => void;
  previousResponses?: PreviousResponse[];
}

export const PreviousResponsesDropdown: React.FC<
  IPreviousResponsesDropdownProps
> = ({
  children,
  dropdownTitle,
  emptyMessage,
  isFilterable,
  isLoading,
  linkLabel,
  onAnswersDropdownShow,
  onFilterChange,
  previousResponses = [],
}) => {
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);

  const [filter, setFilter] = useState('');

  const handleShowDropdown = useCallback(() => {
    onAnswersDropdownShow?.();
    setIsDropdownShowing(true);
  }, [onAnswersDropdownShow]);

  const handleHideDropdown = useCallback(() => setIsDropdownShowing(false), []);

  const handleFilterChanged = useCallback(
    (to: string) => {
      onFilterChange?.(to);
      setFilter(to);
    },
    [onFilterChange],
  );

  const elLoading = isLoading && (
    <SkeletonDetailedItemsList style={styles.skeletonDetailedItemsList} />
  );

  const filteredPreviousResponses = useMemo(() => {
    if (isLoading) return [];
    return previousResponses.filter((pr) =>
      str.isFuzzyMatch(filter, [pr.description, pr.heading]),
    );
  }, [filter, isLoading, previousResponses]);

  const elPreviousResponses = filteredPreviousResponses
    .filter((pr) => str.isFuzzyMatch(filter, [pr.description, pr.heading]))
    .map((pr, index) => (
      <div css={styles.previousResponse} key={`previousResponse-${index}`}>
        <Text color={color.format(-0.9)} size={16}>
          {pr.heading}
        </Text>
        <Text color={color.format(-0.6)} size={13}>
          {pr.description}
        </Text>
      </div>
    ));

  const elEmpty = !isLoading && !filteredPreviousResponses.length && (
    <Text italic={true} color={color.format(-0.5)}>
      {emptyMessage}
    </Text>
  );

  return (
    <div css={styles.base}>
      {children}
      <TextLink onClick={handleShowDropdown} size={12}>
        {linkLabel}
      </TextLink>
      {isDropdownShowing && (
        <Popup onHide={handleHideDropdown} hideOnClick={false}>
          <div css={styles.popup}>
            <div css={styles.dropdownTitle}>
              <Text
                weight={900}
                color={color.format(-0.5)}
                size={12}
                uppercase={true}
                ellipsis={true}
              >
                {dropdownTitle}
              </Text>
              {isFilterable && (
                <FilterField
                  filter={filter}
                  onFilterChange={handleFilterChanged}
                />
              )}
            </div>
            {elLoading}
            {!isLoading && (
              <div css={styles.content}>
                {elPreviousResponses}
                {elEmpty}
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    position: 'relative',
    flex: '1 1 275px',
  }),
  popup: css({
    width: 800,
    maxWidth: '100%',
    backgroundColor: 'white',
    border: `solid 1px ${color.format(-0.2)}`,
    display: 'flex',
    flexDirection: 'column',
  }),
  skeletonDetailedItemsList: css({
    overflow: 'auto',
    maxHeight: 200,
    width: '100%',
  }),
  previousResponse: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    ':hover': {
      backgroundColor: color.format(-0.05),
      padding: 10,
      margin: -10,
    },
  }),
  content: css({
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  dropdownTitle: css({
    minHeight: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 12px',
    backgroundColor: 'white',
    borderBottom: `5px solid ${color.format(-0.1)}`,
  }),
};
