export type BulkUploadClickThroughColumn =
  | BulkUploadColumns.FAILED
  | BulkUploadColumns.SUCCEEDED;

export enum BulkUploadColumns {
  UPLOADED_AT = 'createdAt',
  TEMPLATE_NAMES = 'templateNames',
  UPLOADED_BY = 'createdByName',
  JOB_STATUS = 'uploadStatus',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
