import {
  decodeBase64,
  encodeBase64,
} from '@seeeverything/ui.util/src/str/str.base64.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { isEmpty, isNil, last } from 'ramda';
import { log } from '../log/log.ts';

/**
 * Overall check if a url path is valid.
 */
export const isUrlPathValid = (urlPath?: string) =>
  parseChips(urlPath).length > 0;

/**
 * Returns true if the supplied URL path resolves to the person's dashboard.
 */
export const isAuthenticatedUserDashboard = (
  urlPath?: string,
  userId?: string,
) => {
  if (!urlPath || !userId) {
    return false;
  }

  const chips = parseChips(urlPath);
  if (!chips?.length) {
    return false;
  }

  const lastChip = last(chips);
  return lastChip.type === 'people' && lastChip.value === userId;
};

export const containsAnyTeamOrPersonDashboard = (urlPath?: string) => {
  if (!urlPath) return false;

  const chips = parseChips(urlPath);
  if (!chips?.length) return false;

  return chips.some((chip) => ['people', 'team'].includes(chip.type));
};

export type ChipType =
  | 'auditLog'
  | 'bu'
  | 'bulkUploadFailed'
  | 'bulkUploadSucceeded'
  | 'clickThroughGrid'
  | 'commentsList'
  | 'forms'
  | 'formsDesignerTemplate'
  | 'goalsActionsGrid'
  | 'gridV2'
  | 'logout'
  | 'people'
  | 'settings'
  | 'team';

export type Chip<T extends string = ChipType> = {
  type: T;
  value?: string;
  label?: string;
};

/**
 * Gets the valid chip parts from the supplied URL path.
 */
export const parseChips = <T extends string = ChipType>(
  urlPath = '',
): Chip<T>[] =>
  urlPath
    .split('/')
    .map<Chip<T>>((part) => {
      const [type, value, label] = part.split(':');
      if (isEmpty(type) || type === 'module') {
        return undefined;
      }

      return {
        type: type as T,
        value: value === '' ? undefined : value,
        label: label === '' ? undefined : decodeBase64(label) ?? label,
      };
    })
    .filter((part) => !isNil(part));

// To assist with managing future breaking changes with URL version compatibility.
const VERSION_COMPATIBILITY_TAG = 'v=1';

export type GenerateUrlPathOptions = {
  tenant: string;
  module: ModuleType;
  chips: Chip[];
  dateFilterId?: string;
  dateFilterCustomStartDate?: string;
  dateFilterCustomEndDate?: string;
  columnFilters?: Record<string, string[]>;
  columnSorts?: Record<string, string>;
};

export const generateUrlPath = ({
  tenant,
  module,
  chips = [],
  dateFilterId,
  dateFilterCustomStartDate,
  dateFilterCustomEndDate,
  columnFilters,
  columnSorts,
}: GenerateUrlPathOptions) => {
  try {
    if (!tenant || !module) return '';

    const filteredChips = (chips ?? []).filter(({ type }) => type !== 'bu');

    const queryPath = filteredChips
      .reduce((acc: string, { type, value = '', label = '' }: Chip) => {
        if (value === '') return acc;

        return `${acc}/${type}:${value}:${encodeBase64(label, {
          shouldReplaceWordChars: true,
          removeQuestionMarks: true,
        })}`;
      }, '')
      .substring(1); // Clear leading slash.

    const encodedTenant = encodeURIComponent(tenant);
    const encodedQueryPath = encodeURIComponent(queryPath);
    const encodedDateFilter = dateFilterId
      ? encodeURIComponent(encodeBase64(dateFilterId))
      : '';
    const encodedDateStart = dateFilterCustomStartDate
      ? encodeURIComponent(encodeBase64(dateFilterCustomStartDate))
      : '';
    const encodedDateEnd = dateFilterCustomEndDate
      ? encodeURIComponent(encodeBase64(dateFilterCustomEndDate))
      : '';

    const encodedColumnFilters = columnFilters
      ? encodeURIComponent(encodeBase64(JSON.stringify(columnFilters)))
      : '';

    const encodedColumnSorts = columnSorts
      ? encodeURIComponent(encodeBase64(JSON.stringify(columnSorts)))
      : '';

    const urlParts = [
      `tenant=${encodedTenant}`,
      `module=${module}`,
      `dateFilter=${encodedDateFilter}`,
      `dateStart=${encodedDateStart}`,
      `dateEnd=${encodedDateEnd}`,
      `path=${encodedQueryPath}`,
      encodedColumnFilters && `columnFilters=${encodedColumnFilters}`,
      encodedColumnSorts && `columnSorts=${encodedColumnSorts}`,
      `${VERSION_COMPATIBILITY_TAG}`,
    ].filter(Boolean);

    return `?${urlParts.join('&')}`;
  } catch {
    return '';
  }
};

export type UrlQueryParameters = {
  tenant: string;
  module: ModuleType;
  path: string;
  chips: Chip[];
  dateFilter?: string;
  dateStart?: string;
  dateEnd?: string;
  columnFilters?: Record<string, string[]>;
  columnSorts?: Record<string, string>;
};

export const getUrlQueryParameters = (url: string): UrlQueryParameters => {
  const params = new URLSearchParams(url);

  const result: UrlQueryParameters = {} as UrlQueryParameters;

  const versionParam = params.get('v');
  if (versionParam !== '1') return;

  const tenantParam = params.get('tenant');
  if (!tenantParam) return;
  result.tenant = tenantParam;

  const moduleParam = params.get('module');
  if (!moduleParam) return;
  result.module = moduleParam as ModuleType;

  try {
    const pathParam = params.get('path');
    if (!pathParam) return;

    const chips = parseChips(pathParam);
    if (!chips.length) return;

    result.chips = chips;
    result.path = pathParam;
  } catch (e) {
    log.error('Error parsing url path', e);
    return;
  }

  try {
    const columnFiltersParam = params.get('columnFilters');
    if (columnFiltersParam)
      result.columnFilters = JSON.parse(decodeBase64(columnFiltersParam));
  } catch (e) {
    log.error('Error parsing url column filters', e);
  }

  try {
    const columnSortsParam = params.get('columnSorts');
    if (columnSortsParam)
      result.columnSorts = JSON.parse(decodeBase64(columnSortsParam));
  } catch (e) {
    log.error('Error parsing url column sorts', e);
  }

  try {
    const dateFilterParam = params.get('dateFilter');
    if (dateFilterParam) result.dateFilter = decodeBase64(dateFilterParam);
  } catch (e) {
    log.error('Error parsing url date filter', e);
  }

  try {
    const dateStartParam = params.get('dateStart');
    if (dateStartParam) result.dateStart = decodeBase64(dateStartParam);
  } catch (e) {
    log.error('Error parsing url date start', e);
  }

  try {
    const dateEndParam = params.get('dateEnd');
    if (dateEndParam) result.dateEnd = decodeBase64(dateEndParam);
  } catch (e) {
    log.error('Error parsing url date end', e);
  }

  return result;
};
