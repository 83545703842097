/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IItemTrackProps {
  continueToNextTrack: boolean;
  height?: number;
}

export const ItemTrack: React.FC<IItemTrackProps> = ({
  continueToNextTrack,
  height = 46,
}) => {
  return (
    <div css={styles.base}>
      <span css={[styles.track, { height }]} />
      {continueToNextTrack && <span css={styles.trackContinue} />}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  track: css({
    marginLeft: 10,
    height: 46,
    minWidth: 22,
    borderLeft: `solid 2px ${color.format(-0.2)}`,
    borderBottom: `solid 2px ${color.format(-0.2)}`,
  }),
  trackContinue: css({
    marginLeft: 10,
    height: 43,
    minWidth: 22,
    borderLeft: `solid 2px ${color.format(-0.2)}`,
  }),
};
