import * as types from './types.ts';

export function showModalDialog(
  payload: types.IShowModalDialogAction['payload'],
): types.IShowModalDialogAction {
  return {
    type: 'ui.shell/modalDialog/SHOW',
    payload,
  };
}

export function hideModalDialog(): types.IHideModalDialogAction {
  return {
    type: 'ui.shell/modalDialog/HIDE',
    payload: {},
  };
}
