import gql from 'graphql-tag';
import { combineEpics, StateObservable, ofType } from 'redux-observable';
import { Observable, mergeMap } from 'rxjs';
import { downloadFileInFrame } from '@seeeverything/ui.util/src/download/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  formsBulkUploadFailedRowsDownloadLinkReady,
  formsBulkUploadFailedRowsDownloadComplete,
  formsBulkUploadFailedRowsDownloadFailed,
} from './actions.ts';
import {
  ReduxFormsBulkUploadFailedRowsRequestDownloadLink,
  ReduxFormsBulkUploadFailedRowsDownloadLinkReady,
} from './types.ts';
import { FormReduxAction } from '../../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  formsBulkUploadFailedRowsRequestDownloadLinkEpic,
  formsBulkUploadFailedRowsDownloadLinkReadyEpic,
);

function formsBulkUploadFailedRowsRequestDownloadLinkEpic(
  action$: Observable<ReduxFormsBulkUploadFailedRowsRequestDownloadLink>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/jobs/failed-rows/download/REQUEST_DOWNLOAD_LINK',
    ),
    mergeMap(async ({ payload: { jobId } }) => {
      try {
        const response = await client.query<IFormBulkFailedDownloadRequest>({
          query: queryFormBulkFailedDownloadRequest,
          variables: { jobId },
          fetchPolicy: 'network-only',
        });

        if (response.errors) throw response.errors;

        return formsBulkUploadFailedRowsDownloadLinkReady(
          jobId,
          response.data.forms.formsBulkUploadJobHistoryFailedDownloadLink,
        );
      } catch (error) {
        log.error(
          new Error(
            `GraphQL Error: Error requesting download link for job with id '${jobId}': ${error?.message}`,
          ),
        );
        return formsBulkUploadFailedRowsDownloadFailed(jobId, 'REQUEST');
      }
    }),
  );
}

function formsBulkUploadFailedRowsDownloadLinkReadyEpic(
  action$: Observable<ReduxFormsBulkUploadFailedRowsDownloadLinkReady>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/jobs/failed-rows/download/DOWNLOAD_LINK_READY',
    ),
    mergeMap(async ({ payload: { jobId, uri } }) => {
      try {
        downloadFileInFrame(uri);
        return formsBulkUploadFailedRowsDownloadComplete(jobId);
      } catch (error) {
        log.error(
          new Error(
            `GraphQL Error: Error downloading bulk failures with job id '${jobId}': ${error?.message}`,
          ),
        );
        return formsBulkUploadFailedRowsDownloadFailed(jobId, 'DOWNLOAD');
      }
    }),
  );
}

const queryFormBulkFailedDownloadRequest = gql`
  query FormBulkFailedDownloadRequest($jobId: ID!) {
    forms {
      formsBulkUploadJobHistoryFailedDownloadLink(jobId: $jobId)
    }
  }
`;

interface IFormBulkFailedDownloadRequest {
  forms: {
    formsBulkUploadJobHistoryFailedDownloadLink: string;
  };
}
