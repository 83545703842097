import { ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { isEditor } from '../../../util/util.instance.ts';
import {
  ReduxFormInstanceReviewerChange,
  ReduxFormInstanceReviewerChanged,
  ReduxFormInstanceSubjectChange,
  ReduxFormInstanceSubjectChanged,
} from '../types.ts';
import { loadPermissions, permissionsLoaded } from './actions.ts';

export function participantChangedFormsEditorEpic(
  action$: Observable<
    ReduxFormInstanceReviewerChange | ReduxFormInstanceSubjectChange
  >,
) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/ANSWER/REVIEWER_CHANGE',
      'ui.forms/instance/ANSWER/SUBJECT_CHANGE',
    ),
    filter((action) => isEditor(action.payload.instanceId)),
    map((action) =>
      permissionsLoaded(
        action.payload.instanceId,
        {
          appeal: false,
          archive: false,
          assignedToSignOff: true,
          assignedToRevertSignOff: false,
          cancel: false,
          edit: true,
          subjectRevertSignOff: false,
          subjectSignOff: true,
        },
        'InProgress',
      ),
    ),
  );
}

/**
 * Initiates a `permissions changed` action off of the back of a `saved` action,
 * to update permissions from the service.
 */
export function loadPermissionsOnParticipantChangedEpic(
  action$: Observable<
    ReduxFormInstanceReviewerChanged | ReduxFormInstanceSubjectChanged
  >,
) {
  return action$.pipe(
    ofType(
      'ui.forms/instance/ANSWER/REVIEWER_CHANGED',
      'ui.forms/instance/ANSWER/SUBJECT_CHANGED',
    ),
    filter((action) => !isEditor(action.payload.instanceId)),
    map((action) => loadPermissions(action.payload.instanceId)),
  );
}
