import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';

type LoadMoreClickedPayload = {
  dashboardId: string;
  dateRangeLabel: string;
  endDate: string;
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  startDate: string;
};

export type QueryArguments = {
  key: 'goalsAndActions';
  actionLabel: string;
  dueByDirection: 'Ascending' | 'Descending';
  endDate: string;
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  issueActionLabel: string;
  module: ModuleType;
  orderBy: OrderBy;
  sheetType: 'DASHBOARD' | 'FULL_SHEET';
  showCompleted: boolean;
  startDate: string;
};

type DownloadGridPayload = {
  columnHeaderLabels: string[];
  queryArgs: QueryArguments;
  title: string;
};

export type GoalsActionsGridState = {
  reloadFlag: boolean;
  orderBy?: OrderBy;
  orderDueByDirection: OrderBy['direction'];
  showCompleted: boolean;
};

const DEFAULT_STATE: GoalsActionsGridState = {
  reloadFlag: false,
  orderDueByDirection: 'Ascending',
  showCompleted: false,
};

const slice = createSlice({
  name: 'libs/forms/goalsActionsGrid',
  initialState: DEFAULT_STATE,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadGrid(state, action: PayloadAction<DownloadGridPayload>) {
      return state;
    },
    columnOrderBy(
      state,
      action: PayloadAction<{
        columnId: string;
        direction: 'Ascending' | 'Descending';
      }>,
    ) {
      const columnId = action.payload.columnId;
      if (columnId === 'dueBy') {
        state.orderDueByDirection = action.payload.direction;
        state.orderBy = undefined;
      } else {
        state.orderBy = {
          fieldName: action.payload.columnId,
          direction: action.payload.direction,
        };
      }
    },
    reloadGrid(state) {
      state.reloadFlag = !state.reloadFlag;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadMoreClicked(state, _: PayloadAction<LoadMoreClickedPayload>) {
      return state;
    },
    showCompleted(state, action: PayloadAction<boolean>) {
      state.showCompleted = action.payload;
    },
  },
});

export const {
  columnOrderBy,
  downloadGrid,
  loadMoreClicked,
  reloadGrid,
  showCompleted,
} = slice.actions;
export const reducer = slice.reducer;
export type State = GoalsActionsGridState;
