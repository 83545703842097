import { isAnyOf } from '@reduxjs/toolkit';
import {
  FetchPolicyType,
  IGraphQLClient,
} from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { ModuleType, OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, concatAll, filter, map, mergeMap, of } from 'rxjs';
import * as editDistributionListSlice from '../editDistributionList/editDistributionListSlice.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as distributionListSlice from './distributionListSlice.ts';
import {
  DistributionListItem,
  DistributionListTeamRuleDetail,
  DistributionListType,
} from './types.ts';

export const distributionListEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadDistributionListByIdEpic,
  loadDistributionListPostSaveEpic,
  loadDistributionListOnFiltersChangedEpic,
  loadDistributionListsEpic,
);

function loadDistributionListByIdEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(distributionListSlice.loadDistributionList.match),
    mergeMap(async (action) => {
      try {
        const id = action.payload;
        const result =
          await client.query<GraphQLGetDistributionListByIdResponse>({
            fetchPolicy: 'network-only',
            query: gql`
              query DistributionListById($id: ID!) {
                orgHierarchy {
                  distributionList(id: $id) {
                    id
                    createdAt
                    createdByName
                    isActive
                    name
                    ruleExcludePositionTitles
                    ruleExcludeTeams {
                      id
                      name
                    }
                    ruleIncludePositionTitles
                    ruleIncludePositionTitlesContaining
                    ruleIncludePositionTitlesNotContaining
                    ruleIncludeTeams {
                      id
                      name
                    }
                    ruleIncludeTeamsBelow {
                      team {
                        id
                        name
                      }
                      hierarchyLevel
                    }
                    ruleIncludePositionTitles
                    ruleExcludePositionTitles
                    ruleIncludePositionTitlesContaining
                    ruleIncludePositionTitlesNotContaining
                    type
                    updatedAt
                    updatedByName
                  }
                }
              }
            `,
            variables: { id },
          });

        const resultItem = result.data.orgHierarchy.distributionList;
        const listItem: DistributionListItem = {
          id: resultItem.id,
          canEdit: true,
          canSave: true,
          created: resultItem.createdAt,
          lastUpdatedBy: resultItem.updatedByName,
          lastUpdatedOn: resultItem.updatedAt,
          listName: resultItem.name,
          ruleExcludeTeams: resultItem.ruleExcludeTeams.length
            ? resultItem.ruleExcludeTeams
            : undefined,
          ruleIncludeTeams: resultItem.ruleIncludeTeams.length
            ? resultItem.ruleIncludeTeams
            : undefined,
          ruleIncludeTeamsBelow: resultItem.ruleIncludeTeamsBelow.length
            ? resultItem.ruleIncludeTeamsBelow.map((rule) => ({
                id: rule.team.id,
                hierarchyLevel: rule.hierarchyLevel,
                name: rule.team.name,
              }))
            : undefined,
          ruleIncludePositionTitles: resultItem.ruleIncludePositionTitles.length
            ? resultItem.ruleIncludePositionTitles
            : undefined,
          ruleExcludePositionTitles: resultItem.ruleExcludePositionTitles.length
            ? resultItem.ruleExcludePositionTitles
            : undefined,
          ruleIncludePositionTitlesContaining: resultItem
            .ruleIncludePositionTitlesContaining.length
            ? resultItem.ruleIncludePositionTitlesContaining
            : undefined,
          ruleIncludePositionTitlesNotContaining: resultItem
            .ruleIncludePositionTitlesNotContaining.length
            ? resultItem.ruleIncludePositionTitlesContaining
            : undefined,
          status: resultItem.isActive ? 'Active' : 'Inactive',
          type: resultItem.type,
        };

        return of(distributionListSlice.loadedDistributionList(listItem));
      } catch (error) {
        log.error(
          new Error(`GraphQL Error: Failed to load distribution list by id`),
        );
        return of(distributionListSlice.loadDistributionListError());
      }
    }),
    concatAll(),
  );
}

function loadDistributionListPostSaveEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(editDistributionListSlice.saveDistributionListSuccess.match),
    map((action) =>
      distributionListSlice.loadDistributionList(action.payload.id),
    ),
  );
}

/**
 * Epic to filter distribution list items.
 */
function loadDistributionListOnFiltersChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        distributionListSlice.showInactiveDistributionLists.match,
        distributionListSlice.sortDistributionLists.match,
      ),
    ),
    map(() =>
      distributionListSlice.loadDistributionLists({ loadNextPage: false }),
    ),
  );
}

/**
 * Load a paged list distribution lists with filters
 */
function loadDistributionListsEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(distributionListSlice.loadDistributionLists.match),
    mergeMap(async (action) => {
      const module = state$.value.tenantState.tenant?.module;
      const { loadNextPage } = action.payload;
      const pageNumber = loadNextPage
        ? state$.value.formDistributionList.currentPage + 1
        : 1;
      const distributionLists = await getDistributionLists({
        client,
        pageNumber,
        module,
        showAll: state$.value.formDistributionList.showAll,
        orderBy: state$.value.formDistributionList.orderBy,
      });

      if (!distributionLists)
        return of(distributionListSlice.loadDistributionListsFailed());

      const { hasNextPage, currentPage } = distributionLists.pagination;

      return of(
        distributionListSlice.loadedDistributionLists({
          currentPage,
          hasNextPage,
          listItems: distributionLists.data,
        }),
      );
    }),
    concatAll(),
  );
}

type GraphQLDistributionListRuleTeam = {
  id: string;
  name: string;
};

type GraphQLDistributionList = {
  id: string;
  createdAt: string;
  createdByName: string;
  isActive: boolean;
  name: string;
  ruleExcludeTeams: GraphQLDistributionListRuleTeam[];
  ruleIncludeTeams: GraphQLDistributionListRuleTeam[];
  ruleIncludeTeamsBelow: [
    { team: GraphQLDistributionListRuleTeam; hierarchyLevel: number },
  ];
  ruleIncludePositionTitles: string[];
  ruleExcludePositionTitles: string[];
  ruleIncludePositionTitlesContaining: string[];
  ruleIncludePositionTitlesNotContaining: string[];
  type: DistributionListType;
  updatedAt: string;
  updatedByName: string;
};

type GraphQLGetDistributionListsResponse = {
  orgHierarchy: {
    distributionLists: {
      nodes: GraphQLDistributionList[];
      pageInfo: {
        hasNextPage: boolean;
      };
    };
  };
};
interface IGetDistributionListOptions {
  client: IGraphQLClient;
  module: ModuleType;
  fetchPolicy?: FetchPolicyType;
  orderBy: OrderBy;
  pageNumber?: number;
  pageSize?: number;
  showAll?: boolean;
}

async function getDistributionLists({
  client,
  fetchPolicy = 'network-only',
  orderBy,
  pageNumber = 1,
  pageSize = 100,
  module,
  showAll,
}: IGetDistributionListOptions) {
  try {
    const typeFilter = (() => {
      switch (module) {
        case 'coaching':
          return 'Person';
        case 'cadence':
          return 'Team';
        default:
          break;
      }
    })();

    const response = await client.query<GraphQLGetDistributionListsResponse>({
      fetchPolicy,
      query: distributionListQuery,
      variables: {
        pageSize,
        pageNumber,
        orderBy,
        typeFilter,
        showAll,
      },
    });

    const { distributionLists } = response.data.orgHierarchy;

    return {
      pagination: {
        hasNextPage: Boolean(distributionLists.pageInfo.hasNextPage),
        currentPage: pageNumber,
        showAll,
      },
      data: distributionLists.nodes.map(toDistributionListItem),
    };
  } catch (err) {
    log.error(
      new Error(`GraphQL Error: Failed to fetch Distribution Lists: ${err}`),
    );
    return undefined;
  }
}

const toDistributionListItem = (
  node: GraphQLDistributionList,
): DistributionListItem => ({
  id: node.id,
  canSave: true,
  canEdit: true,
  created: node.createdAt,
  lastUpdatedBy: node.updatedByName,
  lastUpdatedOn: node.updatedAt,
  listName: node.name,
  ruleExcludeTeams: node.ruleExcludeTeams.length
    ? node.ruleExcludeTeams
    : undefined,
  ruleIncludeTeams: node.ruleIncludeTeams.length
    ? node.ruleIncludeTeams
    : undefined,
  ruleIncludeTeamsBelow: node.ruleIncludeTeamsBelow.length
    ? node.ruleIncludeTeamsBelow.map((rule) => ({
        id: rule.team.id,
        hierarchyLevel: rule.hierarchyLevel,
        name: rule.team.name,
      }))
    : undefined,
  ruleIncludePositionTitles: node.ruleIncludePositionTitles.length
    ? node.ruleIncludePositionTitles
    : undefined,
  ruleExcludePositionTitles: node.ruleExcludePositionTitles.length
    ? node.ruleExcludePositionTitles
    : undefined,
  ruleIncludePositionTitlesContaining: node.ruleIncludePositionTitlesContaining
    .length
    ? node.ruleIncludePositionTitlesContaining
    : undefined,
  ruleIncludePositionTitlesNotContaining: node
    .ruleIncludePositionTitlesNotContaining.length
    ? node.ruleIncludePositionTitlesNotContaining
    : undefined,
  status: node.isActive ? 'Active' : 'Inactive',
  type: node.type,
});

const distributionListQuery = gql`
  query DistributionLists(
    $pageSize: Int!
    $pageNumber: Int!
    $orderBy: [OrderByInput!]
    $typeFilter: String
    $showAll: Boolean
  ) {
    orgHierarchy {
      distributionLists(
        pagination: { size: $pageSize, pageNumber: $pageNumber }
        orderBy: $orderBy
        typeFilter: $typeFilter
        showAll: $showAll
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          id
          createdAt
          createdByName
          isActive
          name
          ruleExcludeTeams {
            id
            name
          }
          ruleIncludeTeams {
            id
            name
          }
          ruleIncludeTeamsBelow {
            team {
              id
              name
            }
            hierarchyLevel
          }
          ruleIncludePositionTitles
          ruleExcludePositionTitles
          ruleIncludePositionTitlesContaining
          ruleIncludePositionTitlesNotContaining
          type
          updatedAt
          updatedByName
        }
      }
    }
  }
`;

type GraphQLGetDistributionListByIdResponse = {
  orgHierarchy: {
    distributionList: {
      id: string;
      createdAt: string;
      createdByName: string;
      isActive: boolean;
      name: string;
      ruleExcludeTeams: DistributionListTeamRuleDetail[];
      ruleIncludeTeams: DistributionListTeamRuleDetail[];
      ruleIncludeTeamsBelow: [
        { team: DistributionListTeamRuleDetail; hierarchyLevel: number },
      ];
      ruleIncludePositionTitles: string[];
      ruleExcludePositionTitles: string[];
      ruleIncludePositionTitlesContaining: string[];
      ruleIncludePositionTitlesNotContaining: string[];
      type: DistributionListType;
      updatedAt: string;
      updatedByName: string;
    };
  };
};
