import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PersonSelection } from './types.ts';

export type UsePeopleQueryOptions = {
  inputFilter?: string;
  category?: string;
  isEnabled: boolean;
};

export const usePeopleQuery = (
  client: IGraphQLClient,
  args: UsePeopleQueryOptions,
) => {
  const { data, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    enabled: args.isEnabled,
    queryKey: [
      {
        key: 'peopleDropdown.people',
        inputFilter: args.inputFilter,
        category: args.category,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const response = await orgHierarchyQuery.getPeople(client, {
        name: queryKey[0].inputFilter?.toLowerCase(),
        includeInactive: false,
        pageNumber: 1,
      });

      if (!response.isSuccess) return [];

      return response.data.people.map((person) =>
        toDropdownItem(
          {
            id: person.id,
            email: person.email,
            firstName: person.firstName,
            isActive: person.isActive,
            lastName: person.lastName,
            positionTitle: person.positionTitle,
            type: 'OTHER',
          },
          queryKey[0].category,
        ),
      );
    },
  });

  return {
    people: data,
    isFetchingPeople: isFetching,
  };
};

const toDropdownItem = (
  person: PersonSelection,
  category?: string,
): ISelectionListItem<IListItemLabel, PersonSelection> => ({
  id: person.id,
  icon: Icons.face,
  value: person,
  category,
  content: {
    description: [person.positionTitle, person.email]
      .filter(Boolean)
      .join(' - '),
    text: `${person.firstName} ${person.lastName}`.trim(),
  },
});
