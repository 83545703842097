import { IFormsBulkUploadRejectedFile } from './types.ts';
import { file as fileUtil } from '@seeeverything/ui.util/src/file/index.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';

const coreFileMetadata = (file: File) => ({
  displaySize: fileUtil.getReadableSize(file.size),
  extension: fileUtil.getExtension(file.name),
  id: uuid.generate(),
  name: file.name,
  size: file.size,
  uploadedByName: '',
});

export const rejectedFileMetadata =
  (maxSize: number) =>
  (file: File): IFormsBulkUploadRejectedFile => ({
    name: file.name,
    extension: fileUtil.getExtension(file.name, undefined),
    size: file.size,
    displaySize: fileUtil.getReadableSize(file.size),
    reason: file.size > maxSize ? 'size' : 'type',
  });

export const uploadFileMetadata = (file: File) => ({
  ...coreFileMetadata(file),
  canDelete: false,
  canDownload: false,
});

export const uploadedFileMetadata = (file: File) => ({
  ...coreFileMetadata(file),
  canDelete: true,
  canDownload: false,
});
