/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

export const ChartFigure: React.FC = () => {
  return (
    <div css={styles.chartFigure}>
      <Bar height={55} />
      <Bar height={90} />
      <Bar height={75} />
    </div>
  );
};

interface IBarProps {
  height: number;
}

const Bar: React.FC<IBarProps> = ({ height }) => {
  const computedStyle = React.useMemo(
    () => css([styles.bar, css({ height })]),
    [height],
  );
  return <div css={computedStyle} />;
};

const styles = {
  chartFigure: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
  }),
  bar: css({
    width: 35,
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: 'solid 1px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,
    margin: '0px 7px 5px 7px',
  }),
};
