import { combineEpics } from 'redux-observable';
import { epics as downloadTemplateEpics } from './download-template/index.ts';
import { epics as failedRowsDownloadEpics } from './failed-rows-download/index.ts';
import { epics as bulkUploadClickThroughEpics } from './click-through/index.ts';
import { FormReduxAction } from '../types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(downloadTemplateEpics, bulkUploadClickThroughEpics, failedRowsDownloadEpics);
