import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import { reloadInstanceAnswersEpic } from './answer/epic.reloadAnswers.ts';
import { formInstanceReportingDateChangeEpic } from './answer/epic.reportingDate.ts';
import { addNewUploadToFormInstanceEpic } from './instance/epic.addFileToFormInstance.ts';
import { cancelFormInstanceEpic } from './instance/epic.cancelFormInstance.ts';
import { clearFormValidationErrorsOnIssueChanged } from './instance/epic.clearFormValidationErrorsOnComplianceIssueChanged.ts';
import { newInstanceEpic } from './instance/epic.create.ts';
import { exportToPdfEpic } from './instance/epic.exportInstanceToPdf.ts';
import {
  clearFormsTrackedDataIdsEpic,
  loadInstanceEpic,
} from './instance/epic.load.ts';
import { loadPermissionsEpic } from './instance/epic.loadPermissions.ts';
import {
  loadPermissionsOnParticipantChangedEpic,
  participantChangedFormsEditorEpic,
} from './instance/epic.participantChanged.ts';
import { removeDeletedFileFromFormInstanceEpic } from './instance/epic.removeFileFromFormInstance.ts';
import { revertSignoffEpic } from './instance/epic.revertSignoff.ts';
import { signoffSendToServerEpic } from './instance/epic.serverSignoff.ts';
import {
  updateAllAttendeesAttendanceEpic,
  updateAttendeeAttendanceEpic,
} from './instance/epic.updateAttendance.ts';
import { updateAttendeeFollowUpEpic } from './instance/epic.updateFollowUp.ts';
import {
  addSecondSignoffEpic,
  showErrorBannerOnInstanceSignoffFieldsErrorEpic,
  validateForFirstSignoffEpic,
} from './instance/epic.validateSignoff.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  addNewUploadToFormInstanceEpic,
  addSecondSignoffEpic,
  cancelFormInstanceEpic,
  clearFormsTrackedDataIdsEpic,
  clearFormValidationErrorsOnIssueChanged,
  exportToPdfEpic,
  formInstanceReportingDateChangeEpic,
  loadInstanceEpic,
  loadPermissionsEpic,
  loadPermissionsOnParticipantChangedEpic,
  newInstanceEpic,
  participantChangedFormsEditorEpic,
  reloadInstanceAnswersEpic,
  removeDeletedFileFromFormInstanceEpic,
  revertSignoffEpic,
  showErrorBannerOnInstanceSignoffFieldsErrorEpic,
  signoffSendToServerEpic,
  updateAllAttendeesAttendanceEpic,
  updateAttendeeAttendanceEpic,
  updateAttendeeFollowUpEpic,
  validateForFirstSignoffEpic,
);
