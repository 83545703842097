import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import {
  DistributionListRuleType,
  DistributionListTeamRuleDetail,
} from '../distributionList/types.ts';
import { DistributionListRule, IDistributionListItemDraft } from './types.ts';

export async function createDistributionList(
  client: IGraphQLClient,
  id: string,
  draft: Partial<IDistributionListItemDraft>,
) {
  const result = await client.mutate<{
    orgHierarchy: { createDistributionList: { ok: boolean } };
  }>({
    mutation: gql`
      mutation CreateDistributionList(
        $id: ID!
        $listName: String!
        $ruleExcludeTeams: [ID]
        $ruleIncludeTeams: [ID]
        $ruleIncludeTeamsBelow: [DistributionListTeamBelowInput]
        $ruleIncludePositionTitles: [String]
        $ruleExcludePositionTitles: [String]
        $ruleIncludePositionTitlesContaining: [String]
        $ruleIncludePositionTitlesNotContaining: [String]
        $type: DistributionListType!
      ) {
        orgHierarchy {
          createDistributionList(
            input: {
              id: $id
              name: $listName
              ruleExcludeTeams: $ruleExcludeTeams
              ruleIncludeTeams: $ruleIncludeTeams
              ruleIncludeTeamsBelow: $ruleIncludeTeamsBelow
              ruleIncludePositionTitles: $ruleIncludePositionTitles
              ruleExcludePositionTitles: $ruleExcludePositionTitles
              ruleIncludePositionTitlesContaining: $ruleIncludePositionTitlesContaining
              ruleIncludePositionTitlesNotContaining: $ruleIncludePositionTitlesNotContaining
              type: $type
            }
          ) {
            ok
          }
        }
      }
    `,
    variables: {
      id,
      listName: draft.listName,
      type: draft.type,
      ...toGqlVariables(draft.rules),
    },
  });

  return result?.data?.orgHierarchy?.createDistributionList.ok
    ? draft
    : undefined;
}

export async function updateDistributionList(
  client: IGraphQLClient,
  id: string,
  draft: Partial<IDistributionListItemDraft>,
): Promise<boolean> {
  try {
    const result = await client.mutate<{
      orgHierarchy: { updateDistributionList: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateDistributionList(
          $id: ID!
          $name: String!
          $ruleExcludeTeams: [ID]
          $ruleIncludeTeams: [ID]
          $ruleIncludeTeamsBelow: [DistributionListTeamBelowInput]
          $ruleIncludePositionTitles: [String]
          $ruleExcludePositionTitles: [String]
          $ruleIncludePositionTitlesContaining: [String]
          $ruleIncludePositionTitlesNotContaining: [String]
          $type: DistributionListType!
        ) {
          orgHierarchy {
            updateDistributionList(
              input: {
                id: $id
                name: $name
                ruleExcludeTeams: $ruleExcludeTeams
                ruleIncludeTeams: $ruleIncludeTeams
                ruleIncludeTeamsBelow: $ruleIncludeTeamsBelow
                ruleIncludePositionTitles: $ruleIncludePositionTitles
                ruleExcludePositionTitles: $ruleExcludePositionTitles
                ruleIncludePositionTitlesContaining: $ruleIncludePositionTitlesContaining
                ruleIncludePositionTitlesNotContaining: $ruleIncludePositionTitlesNotContaining
                type: $type
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        id,
        name: draft.listName,
        type: draft.type,
        ...toGqlVariables(draft.rules),
      },
    });

    return result?.data?.orgHierarchy?.updateDistributionList.ok;
  } catch (err) {
    const error = new Error(
      `GraphQL Error - Failed to update distribution list | Distribution List ID = ${id}`,
    );
    log.error(error);
    throw error;
  }
}

const toGqlVariables = (rules: DistributionListRule[]) => {
  return {
    ruleExcludeTeams: (
      rules.find((rule) => rule.type === DistributionListRuleType.ExcludeTeams)
        ?.selections as DistributionListTeamRuleDetail[]
    )?.map((selection) => selection.id),
    ruleIncludeTeams: (
      rules.find((rule) => rule.type === DistributionListRuleType.IncludeTeams)
        ?.selections as DistributionListTeamRuleDetail[]
    )?.map((selection) => selection.id),
    ruleIncludeTeamsBelow: rules
      .filter(
        (rule) => rule.type === DistributionListRuleType.IncludeTeamsBelow,
      )
      ?.map((rule) => {
        const selection = rule.selections[0] as DistributionListTeamRuleDetail;
        return {
          teamId: selection?.id,
          hierarchyLevel: selection?.hierarchyLevel,
        };
      }),
    ruleExcludePositionTitles: rules.find(
      (rule) => rule.type === 'Exclude Position Titles',
    )?.selections,
    ruleIncludePositionTitles: rules.find(
      (rule) => rule.type === 'Include Position Titles',
    )?.selections,
  };
};
