import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { FormAnswerAutomatedAction } from '../types.ts';

export type UpdateFormAnswerIssueClassificationsArgs = {
  instanceId: string;
  answerId: string;
  classifications: string[];
  issueId: string;
};

export const updateFormAnswerIssueClassifications = async (
  client: IGraphQLClient,
  args: UpdateFormAnswerIssueClassificationsArgs,
): Promise<
  MutationResult<
    { answerAutomatedActions: FormAnswerAutomatedAction[] } | undefined
  >
> => {
  try {
    const response = await client.mutate<{
      forms: {
        updateFormAnswerIssueClassifications: {
          ok: boolean;
          automatedActions?: FormAnswerAutomatedAction[];
        };
      };
    }>({
      mutation: gql`
        mutation UpdateFormAnswerIssueClassifications(
          $answerId: ID!
          $instanceId: ID!
          $classifications: [String!]
        ) {
          forms {
            updateFormAnswerIssueClassifications(
              input: {
                instanceId: $instanceId
                answerId: $answerId
                classifications: $classifications
              }
            ) {
              ok
              automatedActions {
                id
                description
                label
                createdActionId
              }
            }
          }
        }
      `,
      variables: {
        answerId: args.answerId,
        instanceId: args.instanceId,
        classifications: args.classifications,
      },
    });

    if (!response.data.forms.updateFormAnswerIssueClassifications)
      return { isSuccess: false };

    const automatedActions =
      response.data.forms.updateFormAnswerIssueClassifications.automatedActions;

    return {
      isSuccess: true,
      data: {
        answerAutomatedActions: (automatedActions ?? []).map((a) => ({
          id: a.id,
          description: a.description,
          label: a.label,
          createdActionId: a.createdActionId,
          issueId: args.issueId,
        })),
      },
    };
  } catch (err) {
    log.error(
      `Unable to update form answer issue classifications for instance ${args.instanceId} and answer ${args.answerId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};
