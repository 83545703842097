import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { StatusBarType } from '../../components/StatusBar/types.ts';
import { SheetScheduleType } from '../sheetSchedules/sheetSchedulesSlice.ts';
import {
  IAddSheetAction,
  IRemoveSheetAction,
  ISheet,
  ISheetChangeModuleAction,
  ISheetHideAlertDialogAction,
  ISheetHideStatusBarAction,
  ISheetShowAlertDialogAction,
  ISheetShowStatusBarAction,
  ISheetToolbarClickAction,
  ISheetToolbarDropdownClickAction,
  ISheetsChangeDateRangeAction,
  ISheetsClearAction,
  ISheetsClearDateRangeAction,
  ISheetsDateRangeCanceledAction,
  ISheetsDateRangeConfirmedAction,
  ISheetsDateRangeFieldRequiredAction,
  SheetToolbarTool,
} from './types.ts';

export function clearDateRange(
  context: 'DASHBOARDS',
): ISheetsClearDateRangeAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CLEAR_DATE_RANGE',
    payload: { context },
  };
}

export function changeDateRange(
  kind: 'START' | 'END',
  to: string,
  context: 'DASHBOARDS',
): ISheetsChangeDateRangeAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CHANGE_DATE_RANGE',
    payload: { kind, to, context },
  };
}

export function cancelDateRange(
  context: 'DASHBOARDS',
): ISheetsDateRangeCanceledAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CANCELED',
    payload: { context },
  };
}

export function confirmDateRange(
  context: 'DASHBOARDS',
): ISheetsDateRangeConfirmedAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/CONFIRMED',
    payload: { context },
  };
}

export function dateRangeRequired(
  context: 'DASHBOARDS',
  required: 'START_DATE' | 'END_DATE',
): ISheetsDateRangeFieldRequiredAction {
  return {
    type: 'ui.shell/sheets/DATE_RANGE/FIELD_REQUIRED',
    payload: { context, required },
  };
}

export function addSheet(sheet: ISheet): IAddSheetAction {
  return {
    type: 'ui.shell/sheets/ADD_SHEET',
    payload: { sheet },
  };
}

export function removeSheet(): IRemoveSheetAction {
  return {
    type: 'ui.shell/sheets/REMOVE_SHEET',
    payload: {},
  };
}

export function clear(): ISheetsClearAction {
  return {
    type: 'ui.shell/sheets/CLEAR',
    payload: {},
  };
}

export function toolbarClick(
  toolId: string | number | SheetToolbarTool,
): ISheetToolbarClickAction {
  return {
    type: 'ui.shell/sheets/TOOLBAR_CLICK',
    payload: { toolId },
  };
}

export function toolbarDropdownClick(
  sheet: ISheet,
  toolId: string | number,
  itemId: string | number,
): ISheetToolbarDropdownClickAction {
  return {
    type: 'ui.shell/sheets/TOOLBAR_DROPDOWN_CLICK',
    payload: { sheet, toolId, itemId },
  };
}

export function showStatusBar(
  kind: StatusBarType,
  message: string,
  autoHideDuration = 5000,
): ISheetShowStatusBarAction {
  return {
    type: 'ui.shell/sheets/SHOW_STATUS_BAR',
    payload: { kind, message, autoHideDuration },
  };
}

export function showAlertDialog(
  id: SheetScheduleType,
): ISheetShowAlertDialogAction {
  return {
    type: 'ui.shell/sheets/SHOW_ALERT_DIALOG',
    payload: { id },
  };
}

export function hideAlertDialog(
  id: SheetScheduleType,
  reason: 'Confirm' | 'Cancel',
): ISheetHideAlertDialogAction {
  return {
    type: 'ui.shell/sheets/HIDE_ALERT_DIALOG',
    payload: { id, reason },
  };
}

export function hideStatusBar(): ISheetHideStatusBarAction {
  return {
    type: 'ui.shell/sheets/HIDE_STATUS_BAR',
    payload: {},
  };
}

export const changeModule = (
  from: ModuleType,
  to: ModuleType,
  source: ISheetChangeModuleAction['payload']['source'],
): ISheetChangeModuleAction => ({
  type: 'ui.shell/sheets/CHANGE_MODULE',
  payload: { from, to, source },
});
