import {
  addFileToInstance,
  appealStatusSet,
  attendeeAttendanceUpdated,
  attendeeFollowUpUpdated,
  attendeeSaved,
  attendeeUpdateAllAttending,
  clearErrorsForAnswerKeys,
  create,
  deleteFileFromInstance,
  errorCreatingInstance,
  errorLoading,
  flagGeneralErrorOnInstance,
  flagValidationErrorOnInstance,
  load,
  loaded,
  localRevertSignoffSuccess,
  localSignoffSuccess,
  permissionsLoaded,
  permissionsLoading,
  setInstancePermissionsLoading,
} from './instance/reducers.ts';
import { FormInstanceAction, IFormInstanceState } from './types.ts';

export const DEFAULT_STATE: IFormInstanceState = {
  errors: {
    validation: {
      ids: [],
    },
    create: {},
    load: {},
    showGeneralError: false,
  },
  instances: {},
};

/**
 * Reducer.
 */
export function reducer(
  state: IFormInstanceState = DEFAULT_STATE,
  action: FormInstanceAction,
): IFormInstanceState {
  switch (action.type) {
    case 'ui.forms/instance/ANSWER/CHANGE': {
      const { instanceId, change } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;

      const lineId =
        change.type === 'DELETE'
          ? change.fromAnswer.lineId
          : change.toAnswer.lineId;

      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            answers: {
              ...instance.answers,
              [lineId]: change.toAnswer,
            },
            questionErrors: {
              ...instance.questionErrors,
              [lineId]: undefined,
            },
          },
        },
        errors: {
          ...state.errors,
          validation: {
            ids: state.errors.validation.ids.filter((id) => id !== instanceId),
          },
        },
      };
    }

    case 'ui.forms/instance/APPEALED_STATUS_SET':
      return appealStatusSet(state, action);

    case 'ui.forms/instance/CLEAR_VALIDATION_MESSAGE': {
      const instanceId = action.payload.instanceId;
      const instance = state.instances[instanceId];
      if (!instance) return state;

      return {
        ...state,
        errors: {
          ...state.errors,
          validation: {
            ids: state.errors.validation.ids.filter((id) => id !== instanceId),
          },
        },
      };
    }

    case 'ui.forms/instance/SERVER_LOAD':
      return load(state, action);

    case 'ui.forms/instance/SERVER_LOADED':
      return loaded(state, action);

    case 'ui.forms/instance/ERROR_LOADING':
      return errorLoading(state, action);

    case 'ui.forms/instance/ERROR_CREATING':
      return errorCreatingInstance(state, action);

    case 'ui.forms/instance/PERMISSIONS_LOAD':
      return permissionsLoading(state, action);

    case 'ui.forms/instance/PERMISSIONS_LOADED':
      return permissionsLoaded(state, action);

    case 'ui.forms/instance/FLAG_VALIDATION_ERROR':
      return flagValidationErrorOnInstance(state, action);

    case 'ui.forms/instance/FLAG_GENERAL_ERROR':
      return flagGeneralErrorOnInstance(state);

    case 'ui.forms/instance/USER_REVERT_SIGNOFF':
    case 'ui.forms/instance/USER_SIGNOFF':
    case 'ui.forms/instance/SERVER_SIGNOFF':
      return setInstancePermissionsLoading(state, action);

    case 'ui.forms/instance/SERVER_REVERT_SIGNOFF_SUCCESS':
      return localRevertSignoffSuccess(state, action);

    case 'ui.forms/instance/SERVER_SIGNOFF_SUCCESS':
      return localSignoffSuccess(state, action);

    case 'ui.forms/instance/ADD_FILE_TO_INSTANCE':
      return addFileToInstance(state, action);

    case 'ui.forms/instance/CLEAR_ERRORS_FOR_ANSWER_KEYS':
      return clearErrorsForAnswerKeys(state, action);

    case 'ui.forms/instance/DELETE_FILE_FROM_INSTANCE':
      return deleteFileFromInstance(state, action);

    case 'ui.forms/instance/attendance/UPDATE_ALL_ATTENDANCE':
      return attendeeUpdateAllAttending(state, action);

    case 'ui.forms/instance/attendance/UPDATE_ATTENDEE_ATTENDANCE':
      return attendeeAttendanceUpdated(state, action);

    case 'ui.forms/instance/attendance/UPDATE_ATTENDEE_FOLLOW_UP_ACTION':
      return attendeeFollowUpUpdated(state, action);

    case 'ui.forms/instance/attendance/ATTENDEE_SAVED':
      return attendeeSaved(state, action);

    case 'ui.forms/instance/SERVER_CREATE':
      return create(state, action);

    case 'ui.forms/instance/ANSWER/RELOADED_INSTANCE_ANSWERS': {
      const { instanceId, answers } = action.payload;
      return {
        ...state,
        instances: {
          ...state.instances,
          [action.payload.instanceId]: {
            ...state.instances[instanceId],
            answers,
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/CHANGE_ERROR': {
      const { instanceId, failedChange, revertState } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;

      const answer = revertState
        ? failedChange.fromAnswer
        : failedChange.toAnswer;

      const lineId =
        failedChange.type === 'DELETE'
          ? failedChange.fromAnswer.lineId
          : failedChange.toAnswer.lineId;

      if (failedChange.type === 'CREATE' && !revertState) answer.id = undefined;

      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            answers: {
              ...instance.answers,
              [lineId]: answer,
            },
            questionErrors: {
              ...instance.questionErrors,
              [lineId]: 'There was a problem saving this response.',
            },
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/REQUIRED_ERROR': {
      const { instanceId, questionKeys } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            questionErrors: questionKeys.reduce(
              (acc, key) => ({ ...acc, [key]: 'This is required.' }),
              instance.questionErrors,
            ),
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/CLEAR_QUESTION_ERROR': {
      const { instanceId, questionKey } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            questionErrors: {
              ...instance.questionErrors,
              [questionKey]: undefined,
            },
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/REVIEWER_CHANGE': {
      const { instanceId } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            reviewer: action.payload.change.toReviewer,
            answers: {
              ...instance.answers,
              assignedTo: action.payload.change.toAnswer,
            },
            questionErrors: {
              ...instance.questionErrors,
              assignedTo: undefined,
            },
            permissionsIsLoading: true,
          },
        },
        errors: {
          ...state.errors,
          validation: {
            ids: state.errors.validation.ids.filter((id) => id !== instanceId),
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/REVIEWER_CHANGE_ERROR': {
      const { instanceId } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            reviewer: action.payload.failedChange.fromReviewer,
            answers: {
              ...instance.answers,
              assignedTo: action.payload.failedChange.fromAnswer,
            },
            questionErrors: {
              ...instance.questionErrors,
              assignedTo: 'There was a problem saving this response.',
            },
            permissionsIsLoading: false,
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/SUBJECT_CHANGE': {
      const { instanceId } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            subject: action.payload.change.toSubject,
            answers: {
              ...instance.answers,
              subject: action.payload.change.toAnswer,
            },
            questionErrors: {
              ...instance.questionErrors,
              subject: undefined,
            },
            permissionsIsLoading: true,
          },
        },
        errors: {
          ...state.errors,
          validation: {
            ids: state.errors.validation.ids.filter((id) => id !== instanceId),
          },
        },
      };
    }

    case 'ui.forms/instance/ANSWER/SUBJECT_CHANGE_ERROR': {
      const { instanceId } = action.payload;
      const instance = state.instances[instanceId];
      if (!instance) return state;
      return {
        ...state,
        instances: {
          ...state.instances,
          [instanceId]: {
            ...instance,
            subject: action.payload.failedChange.fromSubject,
            answers: {
              ...instance.answers,
              subject: action.payload.failedChange.fromAnswer,
            },
            questionErrors: {
              ...instance.questionErrors,
              subject: 'There was a problem saving this response.',
            },
            permissionsIsLoading: false,
          },
        },
      };
    }

    default:
      return state;
  }
}
