/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { changes$ } from '@seeeverything/ui.util/src/local-storage/events.rx.ts';
import { filter } from 'rxjs';
import { prop } from '@seeeverything/ui.util/src/local-storage/prop.ts';

type UseLocalStorageSetValue<T = string> = ((current?: T) => T) | T;

/**
 * Hook for providing a stateful integration to local storage.
 * @param {string} key Local storage key.
 * @param {string} initialValue Initial/default value.
 *
 * @example
 * const [name, setName] = useLocalStorage<string>("name", "Bob");
 * return (
 *   <div>
 *     <input
 *       type={'text'}
 *       placeholder={'Enter your name'}
 *       value={name}
 *       onChange={(e) => setName(e.target.value)}
 *     />
 *   </div>
 * );
 */
export function useLocalStorage<T = string>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') return initialValue;

    try {
      const item = prop<T>(key);
      return item ?? initialValue;
    } catch (error) {
      log.error(error);
      return initialValue;
    }
  });

  const setValue = (value: UseLocalStorageSetValue<T>) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      prop(key, valueToStore);
    } catch (error) {
      log.error(error);
    }
  };

  useEffect(() => {
    const subscription = changes$
      .pipe(filter((change) => change.key === key))
      .subscribe((change) => setStoredValue(change.value));

    return () => subscription.unsubscribe();
  }, [key]);

  return [storedValue, setValue] as const;
}
