/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

export const SkeletonActionPlan: React.FC = () => {
  const elRow = (
    <div css={styles.row}>
      <Skeleton animation={'wave'} width={'40%'} height={30} />
      <Skeleton animation={'wave'} width={'30%'} height={30} />
    </div>
  );

  return (
    <div css={styles.base}>
      {elRow}
      {elRow}
      {elRow}
      {elRow}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: 'solid 1px #e0e0e0',
  }),
};
