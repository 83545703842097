/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionPlanGoal } from '@se/data/forms/types.ts';
import { useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { getCategoryOverdueLabel } from '../util.ts';
import { AddGoal } from './AddGoal.tsx';
import { Category } from './Category.tsx';
import { GoalContainer } from './GoalContainer.tsx';

export interface IGoalCategoryContainerProps {
  canCreateGoals: boolean;
  goals: FormActionPlanGoal[];
  id: string;
  instanceId: string;
  isDefaultExpanded: boolean;
  isPdfExport: boolean;
  name: string;
}

export const GoalCategoryContainer: React.FC<IGoalCategoryContainerProps> = ({
  canCreateGoals,
  goals,
  id,
  instanceId,
  isDefaultExpanded,
  isPdfExport,
  name,
}) => {
  const actionLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const numberOfOverdueGoals = goals.filter(
    (goal) => goal.status === 'Overdue',
  ).length;

  const numberOfOverdueActions = useFormsSelector(
    (state) =>
      Object.values(state.formActionPlanV2.actions).filter((action) =>
        goals.some(
          (goal) => goal.id === action.goalId && action.status === 'Overdue',
        ),
      ).length,
  );

  const overdueLabel = useMemo(() => {
    if (numberOfOverdueGoals === 0 && numberOfOverdueActions === 0) return;

    return getCategoryOverdueLabel(
      numberOfOverdueGoals,
      numberOfOverdueActions,
      actionLabel,
    );
  }, [actionLabel, numberOfOverdueActions, numberOfOverdueGoals]);

  return (
    <Category
      overdueLabel={overdueLabel}
      title={`${name} (${goals.length})`}
      id={id}
      isDefaultExpanded={isDefaultExpanded}
    >
      <div css={css({ display: 'flex', flexDirection: 'column', gap: 15 })}>
        {goals.map((goal) => (
          <GoalContainer
            id={goal.id}
            instanceId={instanceId}
            isPdfExport={isPdfExport}
            key={`Goal-${goal.id}`}
            source={{ type: 'FORM', instanceId }}
          />
        ))}
        {canCreateGoals && (
          <AddGoal
            message={goals.length ? 'Add another goal.' : 'Add a new goal.'}
            instanceId={instanceId}
            goalCategory={{ id, name }}
          />
        )}
      </div>
    </Category>
  );
};
