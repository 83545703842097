import {
  IFormFile,
  IFormFileUpload,
  IFormFileUploaded,
  IFormFileDownloadRequested,
  IFormFileDownloadComplete,
  IFormFileDownloadError,
  IFormFileDeleteRequest,
  IFormFileDeleted,
  IFormFileDeleteError,
  IFormFileUploadError,
  IFormFileCopyFromFormInstance,
  IFormFileUploadRejected,
} from './types.ts';
import { rejectedFileMetadata } from './fileMetadata.ts';

export function fileUploadRejected(
  files: File[],
  maxSize: number,
): IFormFileUploadRejected {
  const toRejectedFile = rejectedFileMetadata(maxSize);
  return {
    type: 'ui.forms/file/UPLOAD_REJECTED',
    payload: {
      rejections: files.map(toRejectedFile),
    },
  };
}

export function fileUpload(
  payload: IFormFileUpload['payload'],
): IFormFileUpload {
  return {
    type: 'ui.forms/file/UPLOAD',
    payload,
  };
}

export function fileUploaded(
  fileId: string,
  metadata: IFormFile,
): IFormFileUploaded {
  return {
    type: 'ui.forms/file/UPLOADED',
    payload: { fileId, metadata },
  };
}

export function fileUploadError(
  fileId: string,
  errorMessage: string,
): IFormFileUploadError {
  return {
    type: 'ui.forms/file/UPLOAD_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileDownloadRequest(
  fileId: string,
  formInstanceId: string,
): IFormFileDownloadRequested {
  return {
    type: 'ui.forms/file/DOWNLOAD_REQUEST',
    payload: { fileId, formInstanceId },
  };
}

export function fileDownloadComplete(
  fileId: string,
): IFormFileDownloadComplete {
  return {
    type: 'ui.forms/file/DOWNLOAD_COMPLETE',
    payload: {
      fileId,
    },
  };
}

export function fileDownloadError(
  fileId: string,
  errorMessage: string,
): IFormFileDownloadError {
  return {
    type: 'ui.forms/file/DOWNLOAD_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileDeleteRequest(
  fileId: string,
  formInstanceId: string,
): IFormFileDeleteRequest {
  return {
    type: 'ui.forms/file/DELETE_REQUEST',
    payload: { fileId, formInstanceId },
  };
}

export function fileDeleted(
  fileId: string,
  formInstanceId: string,
): IFormFileDeleted {
  return {
    type: 'ui.forms/file/DELETED',
    payload: { fileId, formInstanceId },
  };
}

export function fileDeleteError(
  fileId: string,
  errorMessage: string,
): IFormFileDeleteError {
  return {
    type: 'ui.forms/file/DELETE_ERROR',
    payload: { fileId, errorMessage },
  };
}

export function fileCopyFromFormInstance(
  filesMetadata: IFormFile[],
): IFormFileCopyFromFormInstance {
  return {
    type: 'ui.forms/file/COPY_FROM_FORM_INSTANCE',
    payload: { filesMetadata },
  };
}
