/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo } from 'react';
import { formEditGoalSlice } from '../../../redux/form-edit-goal/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

export interface IAddGoalProps {
  error?: boolean;
  goalCategory?: FormGoalCategory;
  goalId?: string;
  instanceId: string;
  issueId?: string;
  message: string;
}

export const AddGoal: React.FC<IAddGoalProps> = ({
  error,
  goalCategory,
  instanceId,
  message,
}) => {
  const dispatch = useFormsDispatch();

  const instanceSubject = useFormsSelector(
    (state) => state.formInstance.instances[instanceId]?.subject,
  );

  const goalAdditionalFields = useFormsSelector(
    (state) => state.tenantState.tenant?.configuration.goalAdditionalFields,
  );

  const assignedTo = useMemo(
    () =>
      instanceSubject?.kind === 'Person'
        ? { id: instanceSubject.id, name: instanceSubject.name }
        : undefined,
    [instanceSubject],
  );

  const handleAddGoal = useCallback(() => {
    dispatch(
      formEditGoalSlice.createDraft({
        formInstanceId: instanceId,
        assignedTo,
        goalCategory,
        additionalFields: goalAdditionalFields?.map((goalField) => ({
          ...goalField,
          value: '',
        })),
        source: { type: 'FORM', instanceId },
      }),
    );
  }, [assignedTo, dispatch, goalAdditionalFields, goalCategory, instanceId]);

  const computedStyles = {
    base: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'flex-start',
      color: error ? COLORS.ERROR_RED : COLORS.BLUE,
    },
  };

  return (
    <TextButton
      textButtonStyle={computedStyles.base}
      onClick={handleAddGoal}
      icon={Icons.plus}
      iconStyle={error ? { fill: COLORS.ERROR_RED } : undefined}
      cursor={'inherit'}
      textStyle={styles.buttonTextStyle}
      highlightOnHover={true}
      color={error ? COLORS.ERROR_RED : COLORS.BLUE}
      dataTest={'forms-actionPlan-addGoalButton'}
    >
      {message}
    </TextButton>
  );
};

const styles = {
  buttonTextStyle: css({
    ...CommonStyles.MaterialCubicTransitions,
    marginLeft: 4,
  }),
};
