import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { GoalScheduleDraft } from '../query/goalScheduleTypes.ts';

export type CreateGoalScheduleArgs = {
  scheduleId: string;
  updates: GoalScheduleDraft;
};

export const createGoalSchedule = async (
  client: IGraphQLClient,
  args: CreateGoalScheduleArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { createGoalSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CreateGoalSchedule(
          $scheduleId: ID!
          $startDate: DateTime!
          $endDate: DateTime
          $distributionListId: ID!
          $goalCategoryId: ID
          $goalDescription: String!
          $goalDueDate: DateTime!
          $goalAdditionalFields: [CreateFormGoalAdditionalFieldInput!]
        ) {
          forms {
            createGoalSchedule(
              input: {
                id: $scheduleId
                startDate: $startDate
                endDate: $endDate
                distributionListId: $distributionListId
                goalCategoryId: $goalCategoryId
                goalDescription: $goalDescription
                goalDueDate: $goalDueDate
                goalAdditionalFields: $goalAdditionalFields
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        scheduleId: args.scheduleId,
        startDate: args.updates.startDate,
        endDate: args.updates.endDate,
        distributionListId: args.updates.distributionList.id,
        goalCategoryId: args.updates.goalCategory.id,
        goalDescription: args.updates.goalDescription,
        goalDueDate: args.updates.goalDueDate,
        goalAdditionalFields: args.updates.goalAdditionalFields,
      },
    });

    if (!response.data.forms.createGoalSchedule.ok) throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to create goal schedule for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export type UpdateGoalScheduleArgs = {
  scheduleId: string;
  updates: GoalScheduleDraft;
};

export const updateGoalSchedule = async (
  client: IGraphQLClient,
  args: UpdateGoalScheduleArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateGoalSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateGoalSchedule(
          $scheduleId: ID!
          $startDate: DateTime!
          $endDate: DateTime
          $distributionListId: ID!
          $goalCategoryId: ID
          $goalDescription: String!
          $goalDueDate: DateTime!
          $goalAdditionalFields: [CreateFormGoalAdditionalFieldInput!]
        ) {
          forms {
            updateGoalSchedule(
              input: {
                id: $scheduleId
                startDate: $startDate
                endDate: $endDate
                distributionListId: $distributionListId
                goalCategoryId: $goalCategoryId
                goalDescription: $goalDescription
                goalDueDate: $goalDueDate
                goalAdditionalFields: $goalAdditionalFields
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        scheduleId: args.scheduleId,
        startDate: args.updates.startDate,
        endDate: args.updates.endDate,
        distributionListId: args.updates.distributionList.id,
        goalCategoryId: args.updates.goalCategory.id,
        goalDescription: args.updates.goalDescription,
        goalDueDate: args.updates.goalDueDate,
        goalAdditionalFields: args.updates.goalAdditionalFields,
      },
    });

    if (!response.data.forms.updateGoalSchedule.ok) throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to update goal schedule for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export type UpdateGoalScheduleEndDateArgs = {
  scheduleId: string;
  endDate: string;
};

export const updateGoalScheduleEndDate = async (
  client: IGraphQLClient,
  args: UpdateGoalScheduleEndDateArgs,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateGoalScheduleEndDate: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateGoalScheduleEndDate(
          $scheduleId: ID!
          $endDate: DateTime
        ) {
          forms {
            updateGoalScheduleEndDate(
              input: { id: $scheduleId, endDate: $endDate }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        scheduleId: args.scheduleId,
        endDate: args.endDate,
      },
    });

    if (!response.data.forms.updateGoalScheduleEndDate.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to update goal schedule end date for id ${args.scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export const deactivateGoalSchedule = async (
  client: IGraphQLClient,
  scheduleId: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { deactivateGoalSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation DeactivateGoalSchedule($scheduleId: ID!) {
          forms {
            deactivateGoalSchedule(id: $scheduleId) {
              ok
            }
          }
        }
      `,
      variables: { scheduleId },
    });

    if (!response.data.forms.deactivateGoalSchedule.ok)
      throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to deactivate goal schedule ${scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};

export const activateGoalSchedule = async (
  client: IGraphQLClient,
  scheduleId: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { activateGoalSchedule: { ok: boolean } };
    }>({
      mutation: gql`
        mutation ActivateGoalSchedule($scheduleId: ID!) {
          forms {
            activateGoalSchedule(id: $scheduleId) {
              ok
            }
          }
        }
      `,
      variables: { scheduleId },
    });

    if (!response.data.forms.activateGoalSchedule.ok) throw new Error('Not ok');

    return { isSuccess: true };
  } catch (err) {
    log.error(
      `Unable to activate goal schedule ${scheduleId} - ${err.message}`,
    );
    return { isSuccess: false };
  }
};
