/** @jsxImportSource @emotion/react */
// eslint-disable-next-line import/extensions
import MaterialAccordionDetails from '@mui/material/AccordionDetails';
import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';

export interface IAccordionDetailsProps {
  style?: CSSProperties;
  children?: ReactNode;
}

/**
 * Items rendered under an `AccordionSummary` when an `Accordion` is expanded.
 *
 * @see https://material-ui.com/components/accordion/
 * @see https://material-ui.com/api/accordion-details/
 */
export const AccordionDetails = ({
  children,
  style,
}: IAccordionDetailsProps) => {
  const styles = useStyles(style)();

  return (
    <MaterialAccordionDetails classes={{ root: styles.root }}>
      {children}
    </MaterialAccordionDetails>
  );
};

const useStyles = (style: CSSProperties) => {
  return makeStyles({ root: { ...style } });
};
