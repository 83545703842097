import gql from 'graphql-tag';
import { Observable, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { fileDeleted, fileDeleteError } from './actions.ts';
import { IFormFileDeleteRequest } from './types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

/**
 * Responsible for uploading a file on the server (via GraphQL).
 */
export function deleteDocumentFromInstance(
  action$: Observable<IFormFileDeleteRequest>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/file/DELETE_REQUEST'),
    mergeMap(async (action) => {
      const { fileId, formInstanceId } = action.payload;

      try {
        const result = await mutate(client, {
          fileId,
          formInstanceId,
        });

        if (
          (result.errors && result.errors.length) ||
          !result.data ||
          !(result.data as any).forms.removeDocumentFromForm.ok
        ) {
          throw new Error();
        }

        return fileDeleted(fileId, formInstanceId);
      } catch (err) {
        log.error('Error occurred while trying to delete file', err);
        return fileDeleteError(fileId, 'Could not delete file.');
      }
    }),
  );
}

type MutationInputs = {
  fileId: string;
  formInstanceId: string;
};

async function mutate(client: IGraphQLClient, inputs: MutationInputs) {
  const mutation = gql`
    mutation DeleteFileFromForm($fileId: ID!, $formInstanceId: ID!) {
      forms {
        removeDocumentFromForm(
          input: { documentId: $fileId, formInstanceId: $formInstanceId }
        ) {
          ok
        }
      }
    }
  `;

  return client.mutate<any>({
    mutation,
    variables: { ...inputs },
  });
}
