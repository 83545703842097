/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IFooProps {
  children?: React.ReactNode;
  style?: SerializedStyles;
}

/**
 * A dummy UI placeholder.
 */
export const Foo: React.FC<IFooProps> = ({ children, style }) => {
  return (
    <div data-test={'foo'} css={[styles.base, style]}>
      {children}
    </div>
  );
};

const styles = {
  base: css({
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    border: `dashed 1px ${color.format(-0.3)}`,
    borderRadius: 3,
    padding: 8,
    fontSize: 14,
    boxSizing: 'border-box',
    color: color.format(-0.8),
  }),
};
