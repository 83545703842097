import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FormActionPlanAction,
  FormActionPlanGoal,
} from '@se/data/forms/types.ts';
import { statusWithOverdue } from '../utils/status.ts';

export type FormActionPlanState = {
  actions: Record<string, FormActionPlanAction>;
  isLoadingAction: Record<string, boolean>;
  isLoadingGoal: Record<string, boolean>;
  goals: Record<string, FormActionPlanGoal>;
  isLoading: boolean;
  loadError: boolean;
};

const DEFAULT_STATE: FormActionPlanState = {
  actions: {},
  goals: {},
  isLoadingAction: {},
  isLoadingGoal: {},
  isLoading: false,
  loadError: false,
};

const slice = createSlice({
  name: 'libs/forms/actionPlan',
  initialState: DEFAULT_STATE,
  reducers: {
    upsertActions(
      state,
      action: PayloadAction<{
        actions: FormActionPlanAction[];
        timezone: string;
      }>,
    ) {
      const timezone = action.payload.timezone;
      state.isLoading = false;
      state.loadError = false;
      action.payload.actions.forEach((a) => {
        state.actions[a.id] = {
          id: a.id,
          goalId: a.goalId,
          issueId: a.issueId,
          formInstance: a.formInstance,
          description: a.description,
          dueBy: a.dueBy,
          assignedToName: a.assignedToName,
          createdAt: a.createdAt,
          completedAt: a.completedAt,
          status: statusWithOverdue(a.status, timezone, a.dueBy),
        };
        state.isLoadingAction[a.id] = false;
      });
    },
    upsertGoals(
      state,
      action: PayloadAction<{ goals: FormActionPlanGoal[]; timezone: string }>,
    ) {
      const timezone = action.payload.timezone;
      state.isLoading = false;
      state.loadError = false;
      action.payload.goals.forEach((goal) => {
        state.goals[goal.id] = {
          id: goal.id,
          description: goal.description,
          dueBy: goal.dueBy,
          formInstance: goal.formInstance,
          assignedToName: goal.assignedToName,
          status: statusWithOverdue(goal.status, timezone, goal.dueBy),
          createdAt: goal.createdAt,
          completedAt: goal.completedAt,
          goalCategory: goal.goalCategory ?? {
            id: 'UNCATEGORIZED',
            name: 'Uncategorized',
          },
        };
        state.isLoadingGoal[goal.id] = false;
      });
    },
    loaded(state) {
      state.isLoading = false;
      state.loadError = false;
    },
    loadError(state) {
      state.isLoading = false;
      state.loadError = true;
    },
    loadActionPlan(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ instanceId: string }>,
    ) {
      state.actions = {};
      state.goals = {};
      state.isLoadingAction = {};
      state.isLoadingGoal = {};
      state.loadError = false;
      state.isLoading = true;
    },
  },
});

export const { loaded, loadError, loadActionPlan, upsertActions, upsertGoals } =
  slice.actions;
export const reducer = slice.reducer;
export type State = FormActionPlanState;
