/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Markdown } from '../../Markdown/Markdown.tsx';

export interface IDisabledTextFieldProps {
  label?: string;
  value?: string;
}

export const DisabledTextField: React.FC<IDisabledTextFieldProps> = ({
  label,
  value,
}) => {
  const computedStyles = {
    base: css({
      display: 'flex',
      flexDirection: 'column',
      minHeight: 41,
      justifyContent: value ? 'space-between' : 'flex-end',
      borderBottom: 'dotted 1px #949494',
      paddingBottom: 6,
      gap: 8,
    }),
  };

  return (
    <div css={computedStyles.base}>
      <Text size={value ? 12 : undefined} color={'#888888'}>
        {label}
      </Text>
      {value && (
        <Markdown text={value} color={'#888888'} style={styles.markdown} />
      )}
    </div>
  );
};

const styles = {
  markdown: css({
    overflowWrap: 'anywhere',
  }),
};
