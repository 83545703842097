import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import moment from 'moment';

type Response = {
  forms: {
    formInstances: {
      pageInfo: { hasNextPage: boolean };
      nodes: [
        {
          answers: { nodes: [{ id: string; value: string }] };
          assignedTo: { id: string; name: string };
          createdAt: string;
          id: string;
          template: { id: string; name: string };
        },
      ];
    };
  };
};

type UsePreviousAnswersOptions = {
  instanceId: string;
  subjectId?: string;
  templateId: string;
  inputId: string;
  fromDifferentTemplateInputId?: string;
  fromDifferentTemplateTemplateName?: string;
  fromBeforeDate?: string;
  previousAnswersEnabled: boolean;
};

export const usePreviousAnswers = (
  client: IGraphQLClient,
  args: UsePreviousAnswersOptions,
) => {
  const { data, isFetching } = useQuery({
    enabled: Boolean(args.previousAnswersEnabled && args.subjectId),
    queryKey: [
      {
        key: `previousAnswers-${args.inputId}`,
        instanceId: args.instanceId,
        subjectId: args.subjectId,
        templateId: args.templateId,
        inputId: args.inputId,
        fromDifferentTemplateInputId: args.fromDifferentTemplateInputId,
        fromDifferentTemplateTemplateName:
          args.fromDifferentTemplateTemplateName,
        fromBeforeDate: args.fromBeforeDate,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const differentTemplateName =
        queryKey[0].fromDifferentTemplateTemplateName;

      const differentTemplateId =
        differentTemplateName &&
        (
          await client.query<{ forms: { formTemplateByName: { id: string } } }>(
            {
              query: gql`
                query FormTemplateByName($templateName: String!) {
                  forms {
                    formTemplateByName(name: $templateName) {
                      id
                    }
                  }
                }
              `,
              variables: { templateName: differentTemplateName },
              fetchPolicy: 'cache-first',
            },
          )
        )?.data.forms.formTemplateByName?.id;

      if (differentTemplateName && !differentTemplateId) return [];

      const response = await client.query<Response>({
        query: gql`
          query FormTemplatePreviousAnswers(
            $subjectId: ID
            $templateId: ID
            $answerKey: String
            $fromBeforeDate: DateTime
          ) {
            forms {
              formInstances(
                pagination: { pageNumber: 1, size: 5 }
                orderBy: { fieldName: "createdAt", direction: Descending }
                templateId: $templateId
                subjectId: $subjectId
                status: { include: Completed }
                createdAt: { lessThan: $fromBeforeDate }
              ) {
                pageInfo {
                  hasNextPage
                }
                nodes {
                  id
                  template {
                    id
                    name
                  }
                  answers(
                    key: $answerKey
                    orderBy: { fieldName: "createdAt", direction: Descending }
                  ) {
                    nodes {
                      id
                      value
                    }
                  }
                  assignedTo {
                    id
                    name
                  }
                  createdAt
                }
              }
            }
          }
        `,
        variables: {
          instanceId: queryKey[0].instanceId,
          subjectId: queryKey[0].subjectId,
          templateId: differentTemplateId ?? queryKey[0].templateId,
          answerKey:
            queryKey[0].fromDifferentTemplateInputId ?? queryKey[0].inputId,
          fromBeforeDate: queryKey[0].fromBeforeDate,
        },
        fetchPolicy: 'network-only',
      });

      return response.data.forms.formInstances.nodes
        .filter(
          (previousAnswer) => previousAnswer.answers.nodes?.[0]?.value ?? '',
        )
        .map((previousAnswer) => {
          const formattedDate = moment(previousAnswer.createdAt).format(
            "D MMM 'YY",
          );
          const heading = `${previousAnswer.template.name} with ${previousAnswer.assignedTo.name} on ${formattedDate}`;

          const answerValue = previousAnswer.answers.nodes?.[0]?.value ?? '';
          const displayValue = `${heading}\n${answerValue}`;

          return {
            id: previousAnswer.id,
            heading,
            value: displayValue,
            description: answerValue,
          };
        });
    },
  });

  return {
    previousAnswers: data,
    isFetchingPreviousAnswers: isFetching,
  };
};
