import gql from 'graphql-tag';
import { Observable, EMPTY, of, mergeMap, filter, concatAll } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxFormInstanceExportPdf } from '../types.ts';
import { pdfExportError } from './actions.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { downloadFileInFrame } from '@seeeverything/ui.util/src/download/index.ts';

type QueryInstanceExportResult = {
  forms?: {
    formInstanceExport?: {
      ticket?: string;
      fileName?: string;
      documentKey?: string;
      downloadUrl?: string;
    };
  };
};

export const exportToPdfEpic = (
  action$: Observable<ReduxFormInstanceExportPdf>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) => {
  return action$.pipe(
    ofType('ui.forms/instance/EXPORT_INSTANCE_TO_PDF'),
    filter(
      (action) =>
        typeof window !== 'undefined' && Boolean(action.payload.instanceId),
    ),
    mergeMap(async ({ payload }) => {
      const { instanceId, fileName } = payload;

      try {
        const response = await client.query<QueryInstanceExportResult>({
          query: gql`
            query FormInstanceExport($instanceId: ID!, $fileName: String!) {
              forms {
                formInstanceExport(
                  instanceId: $instanceId
                  fileName: $fileName
                ) {
                  downloadUrl
                }
              }
            }
          `,
          fetchPolicy: 'network-only',
          variables: { instanceId, fileName },
        });

        const downloadUrl =
          response.data?.forms?.formInstanceExport?.downloadUrl;
        if (downloadUrl) downloadFileInFrame(downloadUrl);

        return EMPTY;
      } catch (err) {
        log.error(
          `A problem occurred trying to generate a PDF export for instance with id '${instanceId}'`,
          err,
        );
      }

      return of(pdfExportError());
    }),
    concatAll(),
  );
};
