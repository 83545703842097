import { DashboardV2ReduxState } from '@seeeverything/ui.dashboards/src/redux/types.ts';
import { FormsDesignerRootState } from '@seeeverything/ui.forms.designer/src/redux/store.ts';
import { GlobalFormsState } from '@seeeverything/ui.forms/src/redux/store.ts';
import {
  GlobalReduxEpicDependenciesType,
  GlobalReduxStateType,
} from '@seeeverything/ui.util/src/redux/types.ts';
import {
  TypedUseSelectorHook,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import { IShellState } from './types.ts';

export type GlobalShellState = GlobalReduxStateType<
  IShellState &
    GlobalFormsState &
    DashboardV2ReduxState &
    FormsDesignerRootState
>;
export type GlobalShellEpicDependencies = GlobalReduxEpicDependenciesType;

export const useShellDispatch = () => useDispatch<any>();
export const useShellSelector: TypedUseSelectorHook<GlobalShellState> = (
  selector,
) => useSelector(selector, shallowEqual);
