/** @jsxImportSource @emotion/react */
import React from 'react';
import ReactPlayer, { Config } from 'react-player';
import { CSSProperties } from '@material-ui/core/styles/withStyles.js';
import { css, SerializedStyles } from '@emotion/react';
import { AspectRatioType } from './types.ts';
import { Spinner } from '../Spinner/index.ts';
import { ErrorRetry } from '../ErrorRetry/index.ts';

export interface IVideoPlayerProps {
  url: string;
  error?: string;
  aspectRatio?: AspectRatioType;
  width?: number;
  playing?: boolean;
  style?: SerializedStyles;
  containerStyle?: CSSProperties; // Applied to the container of the <video> tag.
  videoStyle?: CSSProperties; // Applied directly to the <video> tag.
  onError?: (err: any) => void;
  onRefreshClick?: () => void;
  onReady?: () => void;
}

const DEFAULT_ASPECT_RATIO: AspectRatioType = '16:9';

/**
 * Component for streaming video in the client. Wrapper on `react-player`.
 *
 * @see https://www.npmjs.com/package/react-player
 */
export const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  aspectRatio = DEFAULT_ASPECT_RATIO,
  url,
  error,
  style,
  containerStyle,
  videoStyle,
  playing,
  onError,
  onRefreshClick,
  onReady,
}) => {
  const [isReady, setIsReady] = React.useState(false);

  const playerConfig = React.useMemo(() => {
    return {
      attributes: {
        style: {
          outline: 0, // Turns off focus outline on the video component.
          width: '100%',
          height: '100%',
          ...videoStyle,
        },
      },
    } as Config;
  }, [videoStyle]);

  const handleReady = () => {
    setIsReady(true);
    onReady?.();
  };

  const paddingTop = React.useMemo(
    () => paddingForAspect(aspectRatio),
    [aspectRatio],
  );

  const computedStyles = React.useMemo(
    () => ({
      errorBase: {
        position: 'relative' as const,
        paddingTop: `${paddingTop}%`,
      },
      base: css({
        padding: `${paddingTop}% 1px 1px 0`, // Over scan the video slightly to ensure containers are filled.
      }),
    }),
    [paddingTop],
  );

  if (error) {
    return (
      <ErrorRetry
        style={{
          ...computedStyles.errorBase,
          ...videoStyle,
        }}
        onClick={onRefreshClick}
        message={error}
      />
    );
  }

  return (
    <div css={[styles.base, computedStyles.base, style]}>
      {!isReady && <Spinner color={'#ffffff'} />}
      <ReactPlayer
        url={url}
        controls={true}
        onError={onError}
        width={'100%'}
        height={'100%'}
        stopOnUnmount={true}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          ...containerStyle,
        }}
        config={playerConfig}
        playing={playing}
        onReady={handleReady}
      />
    </div>
  );
};

// Creates a fixed aspect ratio, responsive size video player.
const paddingForAspect = (ratio: AspectRatioType) => {
  const aspectRatio = (widthAspect: number, heightAspect: number) =>
    100 / (widthAspect / heightAspect);

  switch (ratio) {
    case '16:9':
      return aspectRatio(16, 9);
    case '3:2':
      return aspectRatio(3, 2);
    case '4:3':
      return aspectRatio(4, 3);
    default:
      return aspectRatio(16, 9);
  }
};

const styles = {
  base: css({
    position: 'relative',
    width: '100%',
    background: 'black',
  }),
};
