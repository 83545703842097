/** @jsxImportSource @emotion/react */
import { isValidElement } from 'react';
import { is } from 'ramda';
import { css, SerializedStyles } from '@emotion/react';
import { Text, ITextProps } from '../Text/Text.tsx';
import { IIcon, Icons, IIconProps } from '../Icon/index.ts';
import { IListItemLabel } from '../SelectionList/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IIconContentProps {
  style?: SerializedStyles;

  icon?: string | IIcon;
  iconStyles?: SerializedStyles;
  iconProps?: IIconProps;
  emptySelectionLabel?: string;

  content: React.ReactNode | IListItemLabel;
  contentStyle?: SerializedStyles;
  textStyle?: SerializedStyles;
  textProps?: Partial<ITextProps>;
  dataTest?: string;
  isEnabled?: boolean;
}

export const IconContent: React.FC<IIconContentProps> = ({
  content,
  contentStyle,
  dataTest,
  emptySelectionLabel = 'Nothing to select.',
  icon,
  iconProps,
  style,
  textProps,
  textStyle,
}) => {
  const Icon = icon
    ? typeof icon === 'string'
      ? Icons[icon]
      : (icon as IIcon)
    : undefined;

  const isContentString = is(String, content);

  const text = isContentString
    ? (content as string)
    : (content as IListItemLabel)?.text ?? emptySelectionLabel;

  const secondaryText = isContentString
    ? undefined
    : (content as IListItemLabel)?.secondaryText;

  const description = isContentString
    ? undefined
    : (content as IListItemLabel)?.description;

  const elContent = isValidElement(content) ? (
    content
  ) : (
    <div css={[styles.contentBase, contentStyle]}>
      <div css={styles.textBase}>
        {text && (
          <Text
            cursor={'pointer'}
            style={[styles.text, textStyle]}
            ellipsis={true}
            {...textProps}
          >
            {text}
          </Text>
        )}
        {secondaryText && (
          <Text
            cursor={'pointer'}
            color={color.format(-0.4)}
            ellipsis={true}
            {...textProps}
          >
            {secondaryText}
          </Text>
        )}
      </div>
      {description && (
        <Text size={12} ellipsis={true} cursor={'pointer'}>
          {description}
        </Text>
      )}
    </div>
  );

  return (
    <div css={[styles.base, style]} data-test={dataTest}>
      {Icon && <Icon {...iconProps} />}
      {elContent}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
  }),
  contentBase: css({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 15,
    overflow: 'hidden',
  }),
  textBase: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  }),
  text: css({
    whiteSpace: 'nowrap',
  }),
};
