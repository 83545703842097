import {
  AppealResponse,
  FormInstanceStatus,
  IFormInstance,
  IFormInstancePermissions,
  ReduxFormInstanceAddFileToInstance,
  ReduxFormInstanceAppealStatusSet,
  ReduxFormInstanceAttendanceAllAttendedChanged,
  ReduxFormInstanceAttendeeSaved,
  ReduxFormInstanceAttendeeUpdateAttendance,
  ReduxFormInstanceAttendeeUpdateFollowUpAction,
  ReduxFormInstanceClearErrors,
  ReduxFormInstanceClearValidationMessage,
  ReduxFormInstanceDeleteFileFromInstance,
  ReduxFormInstanceErrorLoading,
  ReduxFormInstanceExportPdf,
  ReduxFormInstanceFlagGeneralError,
  ReduxFormInstanceFlagValidationError,
  ReduxFormInstanceInterfaceConfirmRevertSignoff,
  ReduxFormInstancePdfExportError,
  ReduxFormInstancePermissionsLoad,
  ReduxFormInstancePermissionsLoaded,
  ReduxFormInstanceServerCancel,
  ReduxFormInstanceServerCancelled,
  ReduxFormInstanceServerCreate,
  ReduxFormInstanceServerCreateError,
  ReduxFormInstanceServerLoad,
  ReduxFormInstanceServerLoaded,
  ReduxFormInstanceServerRevertSignoffSuccess,
  ReduxFormInstanceServerSignoff,
  ReduxFormInstanceServerSignoffSuccess,
  ReduxFormInstanceUserRevertSignoff,
  ReduxFormInstanceUserSignoff,
  Signature,
} from '../types.ts';

/**
 * Create a new instance with the given template props.
 */
export const serverCreate = ({
  instanceId,
  templateId,
  templateDefinitionId,
  categoryName,
  templateName,
}: ReduxFormInstanceServerCreate['payload']): ReduxFormInstanceServerCreate => ({
  type: 'ui.forms/instance/SERVER_CREATE',
  payload: {
    instanceId,
    templateId,
    templateDefinitionId,
    categoryName,
    templateName,
  },
});

export const errorCreating = ({
  instanceId,
  templateId,
  templateDefinitionId,
  categoryName,
  templateName,
}: ReduxFormInstanceServerCreateError['payload']): ReduxFormInstanceServerCreateError => ({
  type: 'ui.forms/instance/ERROR_CREATING',
  payload: {
    instanceId,
    templateId,
    templateDefinitionId,
    categoryName,
    templateName,
  },
});

export const formInstanceClearValidationMessage = (
  instanceId: string,
): ReduxFormInstanceClearValidationMessage => {
  return {
    type: 'ui.forms/instance/CLEAR_VALIDATION_MESSAGE',
    payload: { instanceId },
  };
};

/**
 * Request loading of a form instance from graphQL.
 */
export const serverLoad = (
  instanceId: string,
  clearErrors?: boolean,
): ReduxFormInstanceServerLoad => {
  return {
    type: 'ui.forms/instance/SERVER_LOAD',
    payload: { instanceId, clearErrors },
  };
};

/**
 * Form instance is loaded.
 */
export const serverLoaded = (
  instanceId: string,
  instance: IFormInstance,
): ReduxFormInstanceServerLoaded => {
  return {
    type: 'ui.forms/instance/SERVER_LOADED',
    payload: { instanceId, instance },
  };
};

/**
 * Unable to load a form instance.
 */
export const errorLoading = (
  instanceId: string,
  type: 'not-found' | 'bad-form-template',
): ReduxFormInstanceErrorLoading => {
  return {
    type: 'ui.forms/instance/ERROR_LOADING',
    payload: { instanceId, type },
  };
};

/**
 * Request to load permissions on a form instance.
 */
export const loadPermissions = (
  instanceId: string,
): ReduxFormInstancePermissionsLoad => {
  return {
    type: 'ui.forms/instance/PERMISSIONS_LOAD',
    payload: { instanceId },
  };
};

/**
 * Permissions were (re)loaded from the service.
 */
export const permissionsLoaded = (
  instanceId: string,
  permissions: IFormInstancePermissions,
  status: FormInstanceStatus,
): ReduxFormInstancePermissionsLoaded => {
  return {
    type: 'ui.forms/instance/PERMISSIONS_LOADED',
    payload: {
      instanceId,
      permissions,
      status,
    },
  };
};

/**
 * End-user performed a signoff on a form instance.
 */
export const userSignoff = (
  instanceId: string,
  personId: string,
  signature: Signature,
  appealResponse?: AppealResponse,
): ReduxFormInstanceUserSignoff => {
  return {
    type: 'ui.forms/instance/USER_SIGNOFF',
    payload: { instanceId, personId, signature, appealResponse },
  };
};

/**
 * Send signoff event to the server.
 */
export const serverSignoff = (
  instanceId: string,
  personId: string,
  signature: Signature,
  appealResponse?: AppealResponse,
): ReduxFormInstanceServerSignoff => {
  return {
    type: 'ui.forms/instance/SERVER_SIGNOFF',
    payload: {
      instanceId,
      personId,
      signature,
      appealResponse,
    },
  };
};

/**
 * User is removing their signoff on an instance.
 */
export const userRevertedSignoff = (
  instanceId: string,
  personId: string,
  signature: Signature,
  userReason?: string,
): ReduxFormInstanceUserRevertSignoff => {
  return {
    type: 'ui.forms/instance/USER_REVERT_SIGNOFF',
    payload: { instanceId, personId, signature, userReason },
  };
};

/**
 * Flags the instance as having validation error(s).
 */
export const validationFailed = (
  instanceId: string,
): ReduxFormInstanceFlagValidationError => {
  return {
    type: 'ui.forms/instance/FLAG_VALIDATION_ERROR',
    payload: { instanceId },
  };
};

/**
 * Flags the technical error.
 */
export const generalError = (): ReduxFormInstanceFlagGeneralError => {
  return {
    type: 'ui.forms/instance/FLAG_GENERAL_ERROR',
    payload: {},
  };
};

/**
 * Document upload - links the file id to the form instance.
 */
export const addFileToFormInstance = (
  instanceId: string,
  fileId: string,
): ReduxFormInstanceAddFileToInstance => {
  return {
    type: 'ui.forms/instance/ADD_FILE_TO_INSTANCE',
    payload: {
      instanceId,
      fileId,
    },
  };
};

/**
 * Clears validation errors for the given answer keys.
 */
export const clearErrorsForAnswerKeys = (
  instanceId: string,
  answerKeys: string[],
  reason: 'MISMATCH_RECONCILIATION' | 'SCORE_OVERRULE',
): ReduxFormInstanceClearErrors => {
  return {
    type: 'ui.forms/instance/CLEAR_ERRORS_FOR_ANSWER_KEYS',
    payload: {
      instanceId,
      answerKeys,
      reason,
    },
  };
};

/**
 * Remove the link between a form instance and a file.
 */
export const deleteFileFromFormInstance = (
  instanceId: string,
  fileId: string,
): ReduxFormInstanceDeleteFileFromInstance => {
  return {
    type: 'ui.forms/instance/DELETE_FILE_FROM_INSTANCE',
    payload: {
      instanceId,
      fileId,
    },
  };
};

/**
 * Cancel/withdraw a form instance via graphql.
 */
export const cancelFormInstance = (
  instanceId: string,
  confirmMessage: string,
): ReduxFormInstanceServerCancel => {
  return {
    type: 'ui.forms/instance/SERVER_CANCEL',
    payload: {
      instanceId,
      confirmMessage,
    },
  };
};

/**
 * Form instance is cancelled.
 */
export const cancelledFormInstance = (
  instanceId: string,
): ReduxFormInstanceServerCancelled => {
  return {
    type: 'ui.forms/instance/SERVER_CANCELLED',
    payload: {
      instanceId,
    },
  };
};

/**
 * Signoff via graphql was successful.
 */
export const signoffSuccess = (
  instanceId: string,
  personId: string,
  signature: Signature,
  appealResponse?: AppealResponse,
): ReduxFormInstanceServerSignoffSuccess => {
  return {
    type: 'ui.forms/instance/SERVER_SIGNOFF_SUCCESS',
    payload: { instanceId, personId, signature, appealResponse },
  };
};

/**
 * Presents a confirmation prompt to the user to confirm removal of a signoff.
 */
export const confirmRevertSignoff = (
  instanceId: string,
  personId: string,
  signature: Signature,
): ReduxFormInstanceInterfaceConfirmRevertSignoff => {
  return {
    type: 'ui.forms/instance/INTERFACE_CONFIRM_REVERT_SIGNOFF',
    payload: {
      instanceId,
      personId,
      signature,
    },
  };
};

/**
 * Removal of an existing signoff via graphql was successful.
 */
export const revertSignoffSuccess = (
  instanceId: string,
  personId: string,
  signature: Signature,
): ReduxFormInstanceServerRevertSignoffSuccess => {
  return {
    type: 'ui.forms/instance/SERVER_REVERT_SIGNOFF_SUCCESS',
    payload: { instanceId, personId, signature },
  };
};

/**
 * Requests the content to be exported to the supplied filename.
 */
export const exportToPdf = (
  instanceId: string,
  fileName: string,
): ReduxFormInstanceExportPdf => {
  return {
    type: 'ui.forms/instance/EXPORT_INSTANCE_TO_PDF',
    payload: { instanceId, fileName },
  };
};

/**
 * Error occurred while trying to generate a PDF export.
 */
export const pdfExportError = (): ReduxFormInstancePdfExportError => {
  return {
    type: 'ui.forms/instance/PDF_EXPORT_ERROR',
    payload: {},
  };
};

export const updateAttendeeAttendance = (
  instanceId: string,
  attendeeId: string,
  isAttending: boolean,
  error?: string,
): ReduxFormInstanceAttendeeUpdateAttendance => ({
  type: 'ui.forms/instance/attendance/UPDATE_ATTENDEE_ATTENDANCE',
  payload: { instanceId, attendeeId, isAttending, error },
});

export const updateAttendeeFollowUp = (
  instanceId: string,
  attendeeId: string,
  isFollowUpRequired: boolean,
  error?: string,
): ReduxFormInstanceAttendeeUpdateFollowUpAction => ({
  type: 'ui.forms/instance/attendance/UPDATE_ATTENDEE_FOLLOW_UP_ACTION',
  payload: { instanceId, attendeeId, isFollowUpRequired, error },
});

export const updateAllAttendeesAttendance = (
  instanceId: string,
  updateAttendeeIds: string[],
  isAttending: boolean,
): ReduxFormInstanceAttendanceAllAttendedChanged => ({
  type: 'ui.forms/instance/attendance/UPDATE_ALL_ATTENDANCE',
  payload: { instanceId, isAttending, updateAttendeeIds },
});

export const attendeeSaved = (
  instanceId: string,
  attendeeId: string,
): ReduxFormInstanceAttendeeSaved => ({
  type: 'ui.forms/instance/attendance/ATTENDEE_SAVED',
  payload: { instanceId, attendeeId },
});

// Required during migration to RTK used by appeals epics
export const setAppealed = (
  instanceId: string,
): ReduxFormInstanceAppealStatusSet => ({
  type: 'ui.forms/instance/APPEALED_STATUS_SET',
  payload: { instanceId },
});
