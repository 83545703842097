import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy, PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map, mergeMap } from 'rxjs';
import {
  BulkUploadClickThroughColumn,
  BulkUploadColumns,
} from '../../../components/BulkUploadJobsGrid/types.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { FormReduxAction } from '../../types.ts';
import { FormsBulkUploadValidateFileUploadRow } from '../import-file-validation/types.ts';
import {
  loadClickThrough,
  loadClickThroughError,
  loadMoreClickThroughError,
  loadedClickThrough,
} from './actions.ts';
import {
  IBulkUploadCreatedInstance,
  ReduxFormsBulkUploadClickThroughLoad,
  ReduxFormsBulkUploadClickThroughLoadMore,
  ReduxFormsBulkUploadClickThroughOrderByChanged,
  ReduxFormsBulkUploadCreateClickThrough,
} from './types.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadClickThroughAfterCreateEpic,
  loadBulkUploadClickThroughEpic,
  reloadCreatedClickThroughEpic,
);

export function loadClickThroughAfterCreateEpic(
  action$: Observable<ReduxFormsBulkUploadCreateClickThrough>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/click-through/CREATE'),
    map(({ payload: { type, jobId } }) => loadClickThrough(jobId, type)),
  );
}

export function reloadCreatedClickThroughEpic(
  action$: Observable<
    | ReduxFormsBulkUploadClickThroughOrderByChanged
    | ReduxFormsBulkUploadClickThroughLoadMore
  >,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    ofType(
      'ui.forms/bulk-upload/click-through/ORDER_BY_CHANGED',
      'ui.forms/bulk-upload/click-through/LOAD_MORE',
    ),
    filter(() => {
      const { jobId, type } = clickThroughProps(state$.value);
      return Boolean(jobId && type);
    }),
    map(({ type: actionType }) => {
      const { jobId, type: clickThroughType } = clickThroughProps(state$.value);
      return loadClickThrough(
        jobId as string,
        clickThroughType as BulkUploadClickThroughColumn,
        actionType === 'ui.forms/bulk-upload/click-through/LOAD_MORE',
      );
    }),
  );
}

export function loadBulkUploadClickThroughEpic(
  action$: Observable<ReduxFormsBulkUploadClickThroughLoad>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/click-through/LOAD'),
    mergeMap(async ({ payload: { jobId, isLoadingMore, type } }) => {
      const {
        formBulkUpload: { clickThrough },
      } = state$.value;

      const isSucceededClickThrough = type === BulkUploadColumns.SUCCEEDED;

      const pageNumber = clickThrough?.pagination?.currentPage ?? 1;
      const getData = isSucceededClickThrough
        ? getCreatedInstances
        : getFailedRows;

      const result = await getData(
        client,
        jobId,
        clickThrough?.orderBy,
        isLoadingMore ? pageNumber + 1 : pageNumber,
      );

      if (!result) {
        const errorAction = isLoadingMore
          ? loadMoreClickThroughError
          : loadClickThroughError;
        return errorAction(
          `Unable to load ${
            isSucceededClickThrough ? 'created instances' : 'failed rows'
          }. Click here to retry.`,
        );
      }

      return loadedClickThrough(
        result.instances,
        result.pageInfo,
        isLoadingMore,
      );
    }),
  );
}

const clickThroughProps = (state: GlobalFormsState) => {
  const jobId = state.formBulkUpload.clickThrough?.jobId;
  const type = state.formBulkUpload.clickThrough?.type;
  return { jobId, type };
};

const getCreatedInstances = async (
  client: IGraphQLClient,
  jobId: string,
  orderBy?: OrderBy[],
  pageNumber = 1,
) => {
  try {
    const response =
      await client.query<IFormBulkUploadCreatedInstancesResponse>({
        query: queryCreatedInstances,
        variables: { jobId, pageNumber, orderBy, pageSize: 50 },
        fetchPolicy: 'network-only',
      });
    return {
      instances: response.data.forms.formsBulkUploadCreatedInstances.nodes,
      pageInfo: response.data.forms.formsBulkUploadCreatedInstances.pageInfo,
    };
  } catch (error) {
    log.error(
      new Error(`GraphQL Error: Error querying bulk upload created instances for job ${jobId}.
     ${error}`),
    );
    return;
  }
};

const getFailedRows = async (
  client: IGraphQLClient,
  jobId: string,
  orderBy?: OrderBy[],
  pageNumber = 1,
) => {
  try {
    const response = await client.query<IFormBulkUploadFailedInstancesResponse>(
      {
        query: queryBulkUploadFailedInstances,
        variables: { jobId, pageNumber, orderBy, pageSize: 50 },
        fetchPolicy: 'network-only',
      },
    );
    return {
      instances:
        response.data.forms.formsBulkUploadJobHistoryFailedDetails.nodes.map(
          (row): FormsBulkUploadValidateFileUploadRow => ({
            ...row,
            rowNumber: row.rowNumber.toString(),
            status: 'Error',
          }),
        ),
      pageInfo:
        response.data.forms.formsBulkUploadJobHistoryFailedDetails.pageInfo,
    };
  } catch (error) {
    log.error(
      new Error(`GraphQL Error: Error querying bulk upload failed rows for job ${jobId}.
     ${error}`),
    );
    return;
  }
};

const queryCreatedInstances = gql`
  query BulkUploadCreatedInstances(
    $jobId: ID!
    $pageNumber: Int
    $pageSize: Int
    $orderBy: [OrderByInput!]
  ) {
    forms {
      formsBulkUploadCreatedInstances(
        jobId: $jobId
        pagination: { pageNumber: $pageNumber, size: $pageSize }
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          totalCount
          pageSize
          currentPage
          totalPages
        }
        nodes {
          id
          createdAt
          templateName
          assignedToName
          subjectName
          updatedAt
          status
        }
      }
    }
  }
`;

interface IFormBulkUploadCreatedInstancesResponse {
  forms: {
    formsBulkUploadCreatedInstances: {
      pageInfo: PageInfoResponse;
      nodes: IBulkUploadCreatedInstance[];
    };
  };
}

const queryBulkUploadFailedInstances = gql`
  query FormsBulkUploadJobHistoryFailedDetails(
    $jobId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $orderBy: [OrderByInput!]
  ) {
    forms {
      formsBulkUploadJobHistoryFailedDetails(
        jobId: $jobId
        pagination: { size: $pageSize, pageNumber: $pageNumber }
        orderBy: $orderBy
      ) {
        pageInfo {
          hasNextPage
          totalCount
          pageSize
          currentPage
          totalPages
        }
        nodes {
          jobId
          rowNumber
          associatedEntities {
            subject
            assignedTo
            automatedActionIds
          }
          errors {
            code
            payload {
              key
              value
            }
          }
        }
      }
    }
  }
`;

interface IFormBulkUploadFailedInstancesResponse {
  forms: {
    formsBulkUploadJobHistoryFailedDetails: {
      pageInfo: PageInfoResponse;
      nodes: FormsBulkUploadValidateFileUploadRow[];
    };
  };
}
