import { isAnyOf } from '@reduxjs/toolkit';
import { formsMutation } from '@se/data/forms/mutation/index.ts';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { MutationResult } from '@se/data/types.ts';
import { momentTzFromState } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { isValidationError } from '@seeeverything/ui.util/src/graphql/validationError.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  concatMap,
  filter,
  map,
  mergeMap,
  of,
} from 'rxjs';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as automatedActionConfigurationSlice from './automatedActionConfigurationSlice.ts';

export const automatedActionConfigurationEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  initiateLoadTemplateConfigurationsEpic,
  loadTemplateConfigurationsEpic,
  saveAutomatedActionEpic,
);

function initiateLoadTemplateConfigurationsEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        automatedActionConfigurationSlice.selectTemplate.match,
        automatedActionConfigurationSlice.toggleShowAll.match,
      ),
    ),
    map((action) => {
      const isSelectTemplate =
        automatedActionConfigurationSlice.selectTemplate.match(action);

      const templateId = isSelectTemplate
        ? action.payload.id
        : state$.value.automatedActionConfiguration.selectedTemplate?.id;
      if (!templateId) return EMPTY;

      return of(
        automatedActionConfigurationSlice.loadTemplateConfigurations({
          templateId,
          showIsLoading: isSelectTemplate,
        }),
      );
    }),
    concatAll(),
  );
}

type LoadTemplateConfigurationsArgs = {
  client: IGraphQLClient;
  state: GlobalFormsState;
  templateId: string;
  showAll: boolean;
  gridRowId: string;
};

const loadTemplateConfigurations = async ({
  client,
  state,
  templateId,
  showAll,
  gridRowId,
}: LoadTemplateConfigurationsArgs) => {
  const response = await formsQuery.getAutomatedActionConfigurations(client, {
    templateId,
    showAll,
  });

  if (!response.isSuccess)
    return automatedActionConfigurationSlice.loadedTemplateConfigurations({
      templateId,
      gridData: [],
      automatedActions: [],
      gridRowId,
    });

  const momentTz = momentTzFromState(state);

  return automatedActionConfigurationSlice.loadedTemplateConfigurations({
    templateId,
    gridData: response.data.map((a) => {
      const classification =
        a.classification === null ? 'All Classifications' : a.classification;

      return {
        id: `${a.issueLabel}-${classification}`,
        data: [
          a.issueLabel,
          classification,
          a.description ?? '',
          a.updatedAt ? momentTz(a.updatedAt).format('D MMM YYYY') : '',
          a.updatedByName ?? '',
        ],
        value: a,
      };
    }),
    automatedActions: response.data,
    gridRowId,
  });
};

function loadTemplateConfigurationsEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(automatedActionConfigurationSlice.loadTemplateConfigurations.match),
    mergeMap(async (action) => {
      return loadTemplateConfigurations({
        client,
        state: state$.value,
        templateId: action.payload.templateId,
        showAll: state$.value.automatedActionConfiguration.showAll,
        gridRowId: action.payload.gridRowId,
      });
    }),
  );
}

function saveAutomatedActionEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(automatedActionConfigurationSlice.saveAutomatedAction.match),
    concatMap(async ({ payload }) => {
      let response: MutationResult;

      const current = state$.value.automatedActionConfiguration.gridData.find(
        (r) => r.id === payload.gridRowId,
      )?.value;
      if (!current) return EMPTY;

      const unchanged =
        payload.description === current.description ||
        (!payload.description && !current.description);

      if (unchanged) {
        return payload.isDone
          ? of(
              automatedActionConfigurationSlice.cancelEditingRow({
                gridRowId: payload.gridRowId,
              }),
            )
          : of(
              automatedActionConfigurationSlice.clearRowSave({
                gridRowId: payload.gridRowId,
              }),
            );
      }

      if (payload.description && !current.automatedActionId) {
        response = await formsMutation.createAutomatedAction(client, {
          description: payload.description,
          matchIssueLabel: current.issueLabel,
          matchIssueClassification: current.classification,
          templateId: current.templateId,
        });
      }

      if (payload.description && current.automatedActionId) {
        response = await formsMutation.updateAutomatedActionDescription(
          client,
          { description: payload.description, id: current.automatedActionId },
        );
      }

      if (!payload.description && current.automatedActionId) {
        response = await formsMutation.deleteAutomatedAction(
          client,
          current.automatedActionId,
        );
      }

      if (!response.isSuccess) {
        const error = response.error;
        const errorMessage = isValidationError(error)
          ? error.message
          : 'Please try again later.';

        return of(
          automatedActionConfigurationSlice.errorEditingRow({
            gridRowId: payload.gridRowId,
            errorMessage,
          }),
        );
      }

      /**
       * We are using concatMap here to allow save + reload to be performed synchronously.
       * This is to handle the situations where a user quickly changes a value just as the
       * action is about to start saving - this approach means the second save cannot start
       * until the first save and reload is completely finished.
       * If we don't do this, we can run into situations where there are duplicate actions
       * configured (because of UI-generated UUIDs), or attempts to save against a resource
       * that no longer exists.
       * */
      const loadedTemplateActions = await loadTemplateConfigurations({
        client,
        state: state$.value,
        templateId: current.templateId,
        showAll: state$.value.automatedActionConfiguration.showAll,
        gridRowId: payload.gridRowId,
      });

      return of(loadedTemplateActions);
    }),
    concatAll(),
  );
}
