import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticatedUser, ModuleType } from '../../types.ts';
import { TenantConfiguration, TenantFeatures, TenantLocale } from './types.ts';
import {
  getLocale,
  getTenantConfigWithDefaults,
  getTenantFeatures,
} from './util.ts';

type SetConfigurationPayload = {
  id: string;
  module: ModuleType;
  configuration: TenantConfiguration;
};

export type TenantState = {
  isMultiTenant: boolean;
  reportingUrl?: string;
  tenant: {
    id: string;
    module: ModuleType;
    configuration: TenantConfiguration;
    features: TenantFeatures;
    locale: TenantLocale;
    authenticatedUser?: AuthenticatedUser;
    allowedModules: {
      coaching: boolean;
      compliance: boolean;
      cadence: boolean;
    };
  };
};

const DEFAULT_STATE: TenantState = {
  isMultiTenant: false,
  tenant: {
    id: 'UNDEFINED',
    module: 'coaching',
    configuration: getTenantConfigWithDefaults(),
    features: getTenantFeatures(),
    locale: getLocale(),
    allowedModules: {
      coaching: true,
      compliance: false,
      cadence: false,
    },
  },
};

const slice = createSlice({
  name: 'global/tenant',
  initialState: DEFAULT_STATE,
  reducers: {
    tempOverrideGridFilters(state, action: PayloadAction<{ to: boolean }>) {
      if (!state.tenant) return;
      state.tenant.features.DASHBOARDS.TEMP_FILTERS = action.payload.to;
    },
    tempOverrideIsPrimary(state, action: PayloadAction<{ to: boolean }>) {
      if (!state.tenant) return;
      state.tenant.features.ORG_HIERARCHY_EDITOR.TEMP_IS_PRIMARY =
        action.payload.to;
    },
    setAuthenticatedUser(state, action: PayloadAction<AuthenticatedUser>) {
      if (!state.tenant) return;

      state.tenant.authenticatedUser = action.payload;
    },
    setConfiguration(state, action: PayloadAction<SetConfigurationPayload>) {
      const { id, module, configuration } = action.payload;
      state.tenant = {
        id,
        module,
        configuration: getTenantConfigWithDefaults(configuration),
        features: getTenantFeatures(configuration, module),
        locale: getLocale(configuration, module),
        authenticatedUser: state.tenant?.authenticatedUser,
        allowedModules: {
          coaching: Boolean(configuration.modules?.coaching?.isEnabled),
          compliance: Boolean(configuration.modules?.compliance?.isEnabled),
          cadence: Boolean(configuration.modules?.cadence?.isEnabled),
        },
      };
    },
    setModule(state, action: PayloadAction<ModuleType>) {
      if (!state.tenant) return;

      const module = action.payload;
      const configuration = state.tenant.configuration;

      state.tenant.module = module;
      state.tenant.features = getTenantFeatures(configuration, module);
      state.tenant.locale = getLocale(configuration, module);
    },
    setMultiTenant(state, action: PayloadAction<boolean>) {
      state.isMultiTenant = action.payload;
    },
    setReportingUrl(state, action: PayloadAction<string>) {
      state.reportingUrl = action.payload;
    },
  },
});

export const {
  setAuthenticatedUser,
  setConfiguration,
  setModule,
  setMultiTenant,
  setReportingUrl,
  tempOverrideGridFilters,
  tempOverrideIsPrimary,
} = slice.actions;
export const reducer = slice.reducer;
export type State = TenantState;
