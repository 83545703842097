import { getFormAction } from './action.ts';
import { getFormActionAudit } from './actionAudit.ts';
import { getFormActionTemplates } from './actionTemplate.ts';
import { getAutomatedActionConfigurations } from './automatedActionConfigurations.ts';
import { getAutomatedActions } from './automatedActions.ts';
import { getFormAnswer } from './formAnswer.ts';
import { getFormAnswerIssues } from './formAnswerIssue.ts';
import { getFormGoalCategories } from './formGoalCategories.ts';
import { getFormInstanceActionPlan } from './formInstanceActionPlan.ts';
import { getFormPermissions } from './formPermissions.ts';
import { getFormTemplate } from './formTemplate.ts';
import { getFormTemplatesByCategory } from './formTemplateByCategory.ts';
import { getFormTemplateCategories } from './formTemplateCategories.ts';
import { getFormTemplateIssues } from './formTemplateIssues.ts';
import { getFormTemplates } from './formTemplates.ts';
import { getGoalSchedules } from './goalSchedules.ts';
import { getInstanceSchedules } from './instanceSchedules.ts';

export const formsQuery = {
  getAutomatedActionConfigurations,
  getAutomatedActions,
  getFormAction,
  getFormActionAudit,
  getFormActionTemplates,
  getFormAnswer,
  getFormAnswerIssues,
  getFormGoalCategories,
  getFormInstanceActionPlan,
  getFormPermissions,
  getFormTemplate,
  getFormTemplateCategories,
  getFormTemplateIssues,
  getFormTemplates,
  getFormTemplatesByCategory,
  getGoalSchedules,
  getInstanceSchedules,
};
