/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';

/**
 * Hook to test whether an image can be loaded by the client.
 * @param url URL of the image to test.
 *
 * @example
 *
 * const canLoad = useImageLoads('https://foo.bar/img.png');
 *
 * return (
 *   <div>
 *     {canLoad ? `image loads` : `image doesn't load`}
 *   </div>
 * );
 */
export const useImageLoads = (url?: string) => {
  const isCancelled = useRef(false);

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    };
  }, []);

  const [canLoad, setCanLoad] = useState(false);

  if (!url) {
    return false;
  }

  const image = new Image();
  image.onload = () => {
    if (!isCancelled.current) setCanLoad(true);
  };
  image.src = url;
  return canLoad;
};
