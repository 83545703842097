import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { getValidationErrorCode } from '@seeeverything/ui.util/src/graphql/validationError.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { MutationResult } from '../../types.ts';
import { FormActionVerificationStatus } from '../types.ts';

type CreateFormActionsArgs = {
  actionId: string;
  formInstanceId: string;
  assignedToPersonId: string;
  answerAutomatedActionId?: string;
  description: string;
  dueBy: string;
  goalId?: string;
  issueId?: string;
  note?: string;
  actionTemplateId?: string;
};

export const createFormAction = async (
  client: IGraphQLClient,
  args: CreateFormActionsArgs,
): Promise<MutationResult<'FORMS_ACTION_CONTAINS_PLACEHOLDER_TEXT'>> => {
  try {
    const response = await client.mutate<{
      forms: { createFormActionOnFormInstance: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CreateFormActionOnFormInstance(
          $actionId: ID!
          $formInstanceId: ID!
          $assignedToId: ID!
          $answerAutomatedActionId: ID
          $description: String!
          $note: String
          $dueBy: DateTime
          $goalId: ID
          $issueId: ID
          $actionTemplateId: ID
        ) {
          forms {
            createFormActionOnFormInstance(
              input: {
                formActionId: $actionId
                formInstanceId: $formInstanceId
                actionTemplateId: $actionTemplateId
                assignedToPersonId: $assignedToId
                answerAutomatedActionId: $answerAutomatedActionId
                description: $description
                dueBy: $dueBy
                goalId: $goalId
                issueId: $issueId
                note: $note
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: {
        actionId: args.actionId,
        formInstanceId: args.formInstanceId,
        assignedToId: args.assignedToPersonId,
        answerAutomatedActionId: args.answerAutomatedActionId,
        description: args.description,
        dueBy: args.dueBy,
        goalId: args.goalId,
        issueId: args.issueId,
        note: args.note?.trim() ?? undefined,
        actionTemplateId: args.actionTemplateId,
      },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.createFormActionOnFormInstance.ok };
  } catch (error) {
    const errorCode = getValidationErrorCode(error.graphQLErrors?.[0]);
    if (errorCode === 'FORMS_ACTION_CONTAINS_PLACEHOLDER_TEXT')
      return {
        isSuccess: false,
        error: errorCode,
      };

    log.error(
      `Something went wrong trying to create form action on instance ${args.formInstanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionDescription = async (
  client: IGraphQLClient,
  actionId: string,
  description: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionDescription: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionDescription(
          $actionId: ID!
          $description: String!
        ) {
          forms {
            updateFormActionDescription(
              input: { formActionId: $actionId, description: $description }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, description },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.updateFormActionDescription.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action description ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const addFormActionNote = async (
  client: IGraphQLClient,
  actionId: string,
  note: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { addFormActionNote: { ok: boolean } };
    }>({
      mutation: gql`
        mutation AddFormActionNote($actionId: ID!, $note: String!) {
          forms {
            addFormActionNote(input: { id: $actionId, note: $note }) {
              ok
            }
          }
        }
      `,
      variables: { actionId, note },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.addFormActionNote.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to add form action note ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionDueBy = async (
  client: IGraphQLClient,
  actionId: string,
  dueBy: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionDueBy: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionDueBy($actionId: ID!, $dueBy: DateTime!) {
          forms {
            updateFormActionDueBy(
              input: { formActionId: $actionId, dueBy: $dueBy }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, dueBy },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.updateFormActionDueBy.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action dueBy ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionIssueId = async (
  client: IGraphQLClient,
  actionId: string,
  issueId: string | null,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionIssueId: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionIssueId($actionId: ID!, $issueId: ID) {
          forms {
            updateFormActionIssueId(
              input: { formActionId: $actionId, issueId: $issueId }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, issueId },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.updateFormActionIssueId.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action issueId ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionAssignedToPersonId = async (
  client: IGraphQLClient,
  actionId: string,
  assignedToPersonId: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionAssignedTo: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionAssignedTo(
          $actionId: ID!
          $assignedToPersonId: ID!
        ) {
          forms {
            updateFormActionAssignedTo(
              input: {
                formActionId: $actionId
                assignedToPersonId: $assignedToPersonId
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, assignedToPersonId },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.updateFormActionAssignedTo.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action assigned to ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionAnswerText = async (
  client: IGraphQLClient,
  formActionId: string,
  questionId: string,
  value: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionAnswerText: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionAnswerText(
          $formActionId: ID!
          $questionId: ID!
          $value: String
        ) {
          forms {
            updateFormActionAnswerText(
              input: {
                formActionId: $formActionId
                questionId: $questionId
                value: $value
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: { formActionId, questionId, value },
      fetchPolicy: 'network-only',
    });

    return {
      isSuccess: response.data.forms.updateFormActionAnswerText.ok,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action text answer ${formActionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionAnswerDropdown = async (
  client: IGraphQLClient,
  formActionId: string,
  questionId: string,
  selectedOptionIds: string[],
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionAnswerDropdown: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionAnswerDropdown(
          $formActionId: ID!
          $questionId: ID!
          $selectedOptionIds: [ID!]
        ) {
          forms {
            updateFormActionAnswerDropdown(
              input: {
                formActionId: $formActionId
                questionId: $questionId
                selectedOptionIds: $selectedOptionIds
              }
            ) {
              ok
            }
          }
        }
      `,
      variables: { formActionId, questionId, selectedOptionIds },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.updateFormActionAnswerDropdown.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action dropdown answer ${formActionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const completeFormAction = async (
  client: IGraphQLClient,
  actionId: string,
  formInstanceId?: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { completeFormAction: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CompleteFormAction($actionId: ID!, $formInstanceId: ID) {
          forms {
            completeFormAction(
              input: { id: $actionId, formInstanceId: $formInstanceId }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, formInstanceId },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.completeFormAction.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to complete form action ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const cancelFormAction = async (
  client: IGraphQLClient,
  actionId: string,
  formInstanceId?: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { cancelFormAction: { ok: boolean } };
    }>({
      mutation: gql`
        mutation CancelFormAction($actionId: ID!, $formInstanceId: ID) {
          forms {
            cancelFormAction(
              input: { id: $actionId, formInstanceId: $formInstanceId }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, formInstanceId },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.cancelFormAction.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to cancel form action ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const revertFormAction = async (
  client: IGraphQLClient,
  actionId: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { revertFormAction: { ok: boolean } };
    }>({
      mutation: gql`
        mutation RevertFormAction($actionId: ID!) {
          forms {
            revertFormAction(input: { id: $actionId }) {
              ok
            }
          }
        }
      `,
      variables: { actionId },
      fetchPolicy: 'network-only',
    });

    return { isSuccess: response.data.forms.revertFormAction.ok };
  } catch (error) {
    log.error(
      `Something went wrong trying to revert form action ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionVerificationNotes = async (
  client: IGraphQLClient,
  actionId: string,
  verificationNotes: string,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionVerificationNotes: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionVerificationNotes(
          $actionId: ID!
          $verificationNotes: String!
        ) {
          forms {
            updateFormActionVerificationNotes(
              input: { formActionId: $actionId, notes: $verificationNotes }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, verificationNotes },
      fetchPolicy: 'network-only',
    });

    return {
      isSuccess: response.data.forms.updateFormActionVerificationNotes.ok,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action verification notes ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};

export const updateFormActionVerificationStatus = async (
  client: IGraphQLClient,
  actionId: string,
  verificationStatus: FormActionVerificationStatus,
): Promise<MutationResult> => {
  try {
    const response = await client.mutate<{
      forms: { updateFormActionVerificationStatus: { ok: boolean } };
    }>({
      mutation: gql`
        mutation UpdateFormActionVerificationStatus(
          $actionId: ID!
          $verificationStatus: FormActionVerificationStatus!
        ) {
          forms {
            updateFormActionVerificationStatus(
              input: { formActionId: $actionId, status: $verificationStatus }
            ) {
              ok
            }
          }
        }
      `,
      variables: { actionId, verificationStatus },
      fetchPolicy: 'network-only',
    });

    return {
      isSuccess: response.data.forms.updateFormActionVerificationStatus.ok,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to update form action verification status ${actionId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      error,
    };
  }
};
