import {
  FormActionPlanAction,
  FormActionPlanGoal,
} from '@se/data/forms/types.ts';
import moment from 'moment';

export const getCategoryOverdueLabel = (
  overdueGoals: number,
  overdueActions: number,
  actionLabel: string,
) => {
  if (overdueGoals > 0 && overdueActions > 0)
    return `Goals and ${actionLabel}s overdue`;

  if (overdueGoals > 0)
    return overdueGoals === 1
      ? '1 goal overdue'
      : `${overdueGoals} goals overdue`;

  return overdueActions === 1
    ? `1 ${actionLabel} overdue`
    : `${overdueActions} ${actionLabel}s overdue`;
};

export const isActiveInFormLifespan = (
  item: FormActionPlanAction | FormActionPlanGoal,
  formCreatedAtDate?: string,
  formSignOffDate?: string,
) => {
  if (!formCreatedAtDate) return false;
  if (item.status === 'Cancelled') return false;

  const isFormSignedOff = Boolean(formSignOffDate);
  const isCreatedBeforeFormSignOff =
    moment(item.createdAt) < moment(formSignOffDate);

  if (item.status !== 'Completed')
    return !isFormSignedOff || isCreatedBeforeFormSignOff;

  const isCompletedAfterFormCreation =
    moment(item.completedAt) > moment(formCreatedAtDate);

  if (isFormSignedOff)
    return isCompletedAfterFormCreation && isCreatedBeforeFormSignOff;

  return isCompletedAfterFormCreation;
};
