import { FormAnswer } from '../../../types/types.ts';
import {
  FormAnswerByKey,
  ReduxFormInstanceAnswerChangeError,
  ReduxFormInstanceAnswerChange,
  ReduxFormInstanceAnswerChanged,
  ReduxFormInstanceReloadedInstanceAnswers,
  ReduxFormInstanceReloadInstanceAnswers,
  ReduxFormInstanceReportingDateChanged,
  ReduxFormInstanceAnswerRequiredError,
  FormInstanceAnswerChange,
  FormInstanceSubjectChange,
  ReduxFormInstanceSubjectChange,
  ReduxFormInstanceSubjectChanged,
  ReduxFormInstanceSubjectChangeError,
  FormInstanceReviewerChange,
  ReduxFormInstanceReviewerChange,
  ReduxFormInstanceReviewerChanged,
  ReduxFormInstanceReviewerChangeError,
  ReduxFormInstanceAnswerClearQuestionError,
} from '../types.ts';

export const formInstanceAnswerChange = (
  instanceId: string,
  change: FormInstanceAnswerChange,
  revertStateOnError: boolean,
): ReduxFormInstanceAnswerChange => {
  const concurrencyId =
    change.type === 'DELETE'
      ? change.fromAnswer.lineId
      : change.toAnswer.lineId;

  return {
    type: 'ui.forms/instance/ANSWER/CHANGE',
    payload: { instanceId, change, concurrencyId, revertStateOnError },
  };
};

export const formInstanceAnswerChanged = (
  instanceId: string,
  change: FormInstanceAnswerChange,
): ReduxFormInstanceAnswerChanged => ({
  type: 'ui.forms/instance/ANSWER/CHANGED',
  payload: { instanceId, change },
});

export const formInstanceAnswerChangeError = (
  instanceId: string,
  failedChange: FormInstanceAnswerChange,
  revertState: boolean,
): ReduxFormInstanceAnswerChangeError => ({
  type: 'ui.forms/instance/ANSWER/CHANGE_ERROR',
  payload: { instanceId, failedChange, revertState },
});

export const reportingDateChangedSuccess = (
  instanceId: string,
  answer: FormAnswer,
): ReduxFormInstanceReportingDateChanged => {
  return {
    type: 'ui.forms/instance/ANSWER/REPORTING_DATE_CHANGED',
    payload: {
      instanceId,
      answer,
    },
  };
};

export const reloadInstanceAnswers = (
  instanceId: string,
): ReduxFormInstanceReloadInstanceAnswers => ({
  type: 'ui.forms/instance/ANSWER/RELOAD_INSTANCE_ANSWERS',
  payload: { instanceId },
});

export const reloadedInstanceAnswers = (
  instanceId: string,
  answers: FormAnswerByKey,
): ReduxFormInstanceReloadedInstanceAnswers => ({
  type: 'ui.forms/instance/ANSWER/RELOADED_INSTANCE_ANSWERS',
  payload: { instanceId, answers },
});

export const formInstanceReviewerChange = (
  instanceId: string,
  change: FormInstanceReviewerChange,
): ReduxFormInstanceReviewerChange => ({
  type: 'ui.forms/instance/ANSWER/REVIEWER_CHANGE',
  payload: { instanceId, change, concurrencyId: instanceId },
});

export const formInstanceReviewerChanged = (
  instanceId: string,
  change: FormInstanceReviewerChange,
): ReduxFormInstanceReviewerChanged => ({
  type: 'ui.forms/instance/ANSWER/REVIEWER_CHANGED',
  payload: { instanceId, change },
});

export const formInstanceReviewerChangeError = (
  instanceId: string,
  failedChange: FormInstanceReviewerChange,
): ReduxFormInstanceReviewerChangeError => ({
  type: 'ui.forms/instance/ANSWER/REVIEWER_CHANGE_ERROR',
  payload: { instanceId, failedChange },
});

export const formInstanceSubjectChange = (
  instanceId: string,
  change: FormInstanceSubjectChange,
): ReduxFormInstanceSubjectChange => ({
  type: 'ui.forms/instance/ANSWER/SUBJECT_CHANGE',
  payload: { instanceId, change, concurrencyId: instanceId },
});

export const formInstanceSubjectChanged = (
  instanceId: string,
  change: FormInstanceSubjectChange,
): ReduxFormInstanceSubjectChanged => ({
  type: 'ui.forms/instance/ANSWER/SUBJECT_CHANGED',
  payload: { instanceId, change },
});

export const formInstanceSubjectChangeError = (
  instanceId: string,
  failedChange: FormInstanceSubjectChange,
): ReduxFormInstanceSubjectChangeError => ({
  type: 'ui.forms/instance/ANSWER/SUBJECT_CHANGE_ERROR',
  payload: { instanceId, failedChange },
});

export const formInstanceAnswerRequiredError = (
  instanceId: string,
  questionKeys: string[],
): ReduxFormInstanceAnswerRequiredError => ({
  type: 'ui.forms/instance/ANSWER/REQUIRED_ERROR',
  payload: {
    instanceId,
    questionKeys,
  },
});

export const formInstanceAnswerClearQuestionError = (
  instanceId: string,
  questionKey: string,
): ReduxFormInstanceAnswerClearQuestionError => ({
  type: 'ui.forms/instance/ANSWER/CLEAR_QUESTION_ERROR',
  payload: {
    instanceId,
    questionKey,
  },
});
