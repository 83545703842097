import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import {
  FormActionStatusWithOverdue,
  FormIssue,
  IssueCause,
} from '../types.ts';

export type FormAnswerIssuesDto = {
  canView: boolean;
  issues: FormIssue[];
  issueCauses: IssueCause[];
};

type ServerFormIssue = {
  id: string;
  label: string;
  notes?: string;
  classifications?: string[];
  issueCoachingRequired?: boolean;
  actionsRequired?: boolean;
  coachingConversation?: string;
  noActionsRequiredReason?: string;
  issueNotesLabel?: string;
  formSectionName: string;
  formQuestionText: string;
  answer: { id: string; key: string; displayValue: string; value: string };
  answerIssueCauses?: Array<{
    issueId: string;
    isPrimary: boolean;
    notes: string;
    issueCause?: IssueCause;
  }>;
  instance: {
    id: string;
    templateName: string;
    categoryName: string;
    status: string;
    dueDate?: string;
    reviewActions?: Array<{
      id: string;
      dueBy: string;
      status: FormActionStatusWithOverdue;
    }>;
  };
  actions?: Array<{
    id: string;
    assignedTo?: { id: string; name: string };
    description?: string;
    dueBy: string;
    formInstanceId?: string;
    status?: FormActionStatusWithOverdue;
  }>;
};

export const getFormAnswerIssues = async (
  client: IGraphQLClient,
  coachingInstanceId: string,
): Promise<QueryResult<FormAnswerIssuesDto>> => {
  try {
    const response = await client.query<{
      forms: {
        formAnswerIssues: {
          canView: boolean;
          issues: ServerFormIssue[];
        };
        issueCauses: IssueCause[];
      };
    }>({
      query: gql`
        query AnswerIssues($coachingInstanceId: ID!) {
          forms {
            formAnswerIssues(coachingInstanceId: $coachingInstanceId) {
              canView
              issues {
                id
                label
                notes
                classifications
                actionsRequired
                coachingConversation
                noActionsRequiredReason
                issueNotesLabel
                formSectionName
                formQuestionText
                issueCoachingRequired
                answer {
                  id
                  key
                  displayValue
                  value
                }
                answerIssueCauses {
                  issueId
                  isPrimary
                  notes
                  issueCause {
                    id
                    label
                    reasonLabel
                    isActive
                  }
                }
                instance {
                  id
                  templateName
                  categoryName
                  status
                  dueDate
                  reviewActions {
                    id
                    dueBy
                    status
                  }
                }
                actions {
                  id
                  assignedTo {
                    id
                    name
                  }
                  formInstanceId
                  description
                  dueBy
                  status
                }
              }
            }
            issueCauses {
              id
              label
              reasonLabel
              isActive
            }
          }
        }
      `,
      variables: { coachingInstanceId },
      fetchPolicy: 'network-only',
    });

    if (!response.data.forms.formAnswerIssues.canView)
      return {
        isSuccess: true,
        data: { issues: [], issueCauses: [], canView: false },
      };

    if (!response.data.forms.issueCauses?.length) {
      log.error(new Error('No issues causes found for tenant'));
      return {
        isSuccess: false,
        errorReason: 'NOT_FOUND',
      };
    }

    const issueCauses = response.data.forms.issueCauses;

    const issues = (response.data.forms.formAnswerIssues.issues ?? []).map(
      (serverIssue): FormIssue => ({
        actionsRequired: serverIssue.actionsRequired,
        answerId: serverIssue.answer.id,
        classifications: (serverIssue.classifications ?? []).map(
          (value, index) => ({ index, value }),
        ),
        coaching: toCoaching(serverIssue, coachingInstanceId),
        errors: { coaching: {}, compliance: {} },
        formAnswerSelected: serverIssue.answer.displayValue,
        formQuestionText: str.removeMarkdownAndSpecialCharacters(
          serverIssue.formQuestionText,
        ),
        formSectionName: serverIssue.formSectionName,
        id: serverIssue.id,
        instance: {
          id: serverIssue.instance.id,
          name: serverIssue.instance.templateName,
          status: serverIssue.instance.status,
          dueDate: serverIssue.instance.dueDate,
          reviewActions:
            serverIssue.instance.reviewActions?.map(
              ({ id, dueBy, status }) => ({
                id,
                dueBy,
                status,
              }),
            ) ?? [],
        },
        issueCauses: {
          isSpinning: false,
          causes: (serverIssue.answerIssueCauses ?? [])
            .map((cause) => {
              const tenantCauseIndex = issueCauses.findIndex(
                (issueCause) => issueCause.id === cause.issueCause?.id,
              );
              if (tenantCauseIndex === -1)
                return {
                  index: -1,
                  issueCauseId: cause.issueCause?.id,
                  label: cause.issueCause?.label,
                  isPrimary: cause.isPrimary,
                  notes: cause.notes,
                  isSelected: true,
                };
              const issueCause = issueCauses[tenantCauseIndex];
              return {
                index: tenantCauseIndex,
                issueCauseId: issueCause.id,
                label: issueCause.label,
                isPrimary: cause.isPrimary,
                notes: cause.notes,
                isSelected: true,
              };
            })
            .filter(Boolean),
        },
        issueCoachingRequired: serverIssue.issueCoachingRequired,
        issueNotesLabel: serverIssue.issueNotesLabel,
        label: serverIssue.label,
        coachingConversation: serverIssue.coachingConversation,
        noActionsReason: serverIssue.noActionsRequiredReason,
        notes: serverIssue.notes,
        isLoading: false,
      }),
    );

    return { isSuccess: true, data: { issues, canView: true, issueCauses } };
  } catch (error) {
    log.error(
      `Something went wrong trying to query form answer issues for instance ${coachingInstanceId} - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};

const toCoachingRequirement = (coachingRequired: boolean) => {
  if (coachingRequired === true) return 'MANDATORY';
  if (coachingRequired === false) return 'RECOMMENDED';
  return 'NONE';
};

const toCoaching = (
  issue: ServerFormIssue,
  coachingInstanceId: string,
): FormIssue['coaching'] => {
  const coachingRequirement = toCoachingRequirement(
    issue.issueCoachingRequired,
  );
  if (coachingRequirement === 'NONE')
    return {
      requirement: 'NONE',
      specifyCauseAndCoaching: {
        isSelected: false,
        canChange: false,
      },
    };

  if (coachingRequirement === 'MANDATORY')
    return {
      requirement: 'MANDATORY',
      specifyCauseAndCoaching: {
        isSelected: true,
        canChange: false,
      },
    };

  const hasIssueAction = issue.actions?.some(
    (action) =>
      action.status !== 'Cancelled' &&
      action.formInstanceId === coachingInstanceId,
  );

  const hasCause = issue.answerIssueCauses?.length > 0;
  const hasNoActionsReason = Boolean(issue.noActionsRequiredReason);

  const isSelected = hasIssueAction || hasCause || hasNoActionsReason;
  return {
    requirement: 'RECOMMENDED',
    specifyCauseAndCoaching: {
      isSelected,
      canChange: !isSelected,
    },
  };
};
