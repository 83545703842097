/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { useMemo } from 'react';
import { useFormsSelector } from '../../redux/store.ts';
import { GoalCategoriesContainer } from './components/GoalCategoriesContainer.tsx';
import { GoalsContainer } from './components/GoalsContainer.tsx';
import { SkeletonActionPlan } from './components/SkeletonActionPlan.tsx';
import { StandaloneActionsContainer } from './components/StandaloneActionsContainer.tsx';

export interface IFormActionPlanContainerProps {
  includeCategories: string[];
  instanceId: string;
  isPdfExport: boolean;
  typeFilter?: 'ONLY_NEW' | 'ONLY_EXISTING';
}

export const FormActionPlanContainer: React.FC<
  IFormActionPlanContainerProps
> = ({ instanceId, isPdfExport, typeFilter, includeCategories }) => {
  const isLoading = useFormsSelector(
    (state) => state.formActionPlanV2.isLoading,
  );

  const hasInstanceSubject = useFormsSelector((state) =>
    Boolean(state.formInstance.instances[instanceId]?.subject),
  );

  const goalsEnabled = useFormsSelector(
    (state) => state.tenantState.tenant.features.FORMS.GOALS,
  );

  const goalCategoriesEnabled = useFormsSelector(
    (state) => state.tenantState.tenant.features.FORMS.GOAL_CATEGORIES,
  );

  const standaloneActionsEnabled = useFormsSelector(
    (state) =>
      state.tenantState.tenant.features.FORMS.CAN_CREATE_STANDALONE_ACTIONS,
  );

  const actions = useFormsSelector((state) =>
    Object.values(state.formActionPlanV2.actions),
  );

  const standaloneActions = useMemo(() => {
    if (!standaloneActionsEnabled) return [];
    if (goalsEnabled) return [];
    if (goalCategoriesEnabled) return [];

    return actions.filter((action) => {
      if (action.goalId) return false;
      return action.formInstance?.id === instanceId;
    });
  }, [
    actions,
    goalCategoriesEnabled,
    goalsEnabled,
    instanceId,
    standaloneActionsEnabled,
  ]);

  const canCreateInInstance = useFormsSelector((state) => {
    const instance = state.formInstance.instances[instanceId];
    if (!instance) return true; // Form designer

    if (!instance.permissions.edit) return false;

    if (instance.status.status === 'InProgress') return true;
    if (instance.status.status === 'Created') return true;

    return false;
  });

  if (isLoading)
    return (
      <div css={styles.base}>
        <SkeletonActionPlan />
      </div>
    );

  if (!hasInstanceSubject)
    return (
      <div css={styles.contentPlaceholder}>
        <ContentPlaceholder
          kind={'LIST'}
          emptyText={''}
          hintText={'Start by assigning this to a team or member.'}
        />
      </div>
    );

  if (standaloneActionsEnabled && !goalsEnabled && !goalCategoriesEnabled)
    return (
      <StandaloneActionsContainer
        instanceId={instanceId}
        isDefaultExpanded={true}
        actions={standaloneActions}
        canCreateActions={canCreateInInstance}
        typeFilter={typeFilter}
      />
    );

  if (!goalCategoriesEnabled)
    return (
      <GoalsContainer
        instanceId={instanceId}
        isPdfExport={isPdfExport}
        typeFilter={typeFilter}
        canCreateInInstance={canCreateInInstance}
      />
    );

  return (
    <div css={styles.base}>
      <GoalCategoriesContainer
        instanceId={instanceId}
        typeFilter={typeFilter}
        includeCategories={includeCategories}
        canCreateInInstance={canCreateInInstance}
        isPdfExport={isPdfExport}
      />
    </div>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    padding: 12,
  }),
  contentPlaceholder: css({
    position: 'relative',
    height: 200,
    width: '100%',
  }),
};
