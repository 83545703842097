import gql from 'graphql-tag';
import { Observable, mergeMap } from 'rxjs';
import { StateObservable, ofType } from 'redux-observable';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { IFormFileUpload } from './types.ts';
import { fileUploaded, fileUploadError } from './actions.ts';
import { uploadedFileMetadata } from './fileMetadata.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';

/**
 * Responsible for uploading a file on the server (via GraphQL).
 */
export function uploadToServer(
  action$: Observable<IFormFileUpload>,
  _: StateObservable<GlobalFormsState>,
  { uploadClient }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/file/UPLOAD'),
    mergeMap(async (action) => {
      const { fileId, file, formInstanceId, uploadedByName } = action.payload;

      try {
        const result = await mutate(uploadClient, {
          fileId,
          formInstanceId,
          file,
        });

        if (
          (result.errors && result.errors.length) ||
          !result.data ||
          !result.data.forms.attachDocumentToForm.ok
        ) {
          throw new Error();
        }

        return fileUploaded(
          fileId,
          uploadedFileMetadata(fileId, file, uploadedByName),
        );
      } catch (err) {
        log.error('Error occurred while trying to upload file', err);
        return fileUploadError(fileId, 'Could not upload file.');
      }
    }),
  );
}

type AttachDocumentToFormInputs = {
  fileId: string;
  formInstanceId: string;
  file: File;
};

async function mutate(
  uploadClient: IGraphQLClient,
  inputs: AttachDocumentToFormInputs,
) {
  const mutation = gql`
    mutation UploadFileToForm(
      $fileId: ID!
      $formInstanceId: ID!
      $file: Upload!
    ) {
      forms {
        attachDocumentToForm(
          input: {
            documentId: $fileId
            formInstanceId: $formInstanceId
            document: $file
          }
        ) {
          ok
        }
      }
    }
  `;

  return uploadClient.mutate<any>({
    mutation,
    variables: { ...inputs },
  });
}
