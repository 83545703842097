import { formsQuery } from '@se/data/forms/query/index.ts';
import { getFormTemplateSubjectType } from '@se/data/forms/utils.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map, switchMap } from 'rxjs';
import { FormTemplateItemDefinition } from '../../../parse/types/template.types.ts';
import { ANSWER_TYPE_LABELS } from '../../../util/constants.ts';
import { exportableQuestionsFromTemplate } from '../../../util/util.parse.template.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../../store.ts';
import { FormReduxAction } from '../../types.ts';
import {
  templateQuestionsLoad,
  templateQuestionsLoadError,
  templateQuestionsLoaded,
} from './actions.ts';
import {
  ReduxFormBulkUploadDownloadTemplateToggleShowHiddenFields,
  ReduxFormBulkUploadTemplateChange,
  ReduxFormBulkUploadTemplateQuestionsLoad,
} from './types.ts';

export const epics = combineEpics<
  FormReduxAction,
  FormReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(
  loadTemplateQuestionsEpic,
  loadTemplateQuestionsOnTemplateSelect,
  loadTemplateQuestionsOnShowHiddenFields,
);

function loadTemplateQuestionsOnTemplateSelect(
  action$: Observable<ReduxFormBulkUploadTemplateChange>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/download-template/TEMPLATE_CHANGE'),
    map(() => templateQuestionsLoad()),
  );
}

function loadTemplateQuestionsOnShowHiddenFields(
  action$: Observable<ReduxFormBulkUploadDownloadTemplateToggleShowHiddenFields>,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/download-template/TOGGLE_SHOW_HIDDEN_FIELDS'),
    map(() => templateQuestionsLoad()),
  );
}

function loadTemplateQuestionsEpic(
  action$: Observable<ReduxFormBulkUploadTemplateQuestionsLoad>,
  state$: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms/bulk-upload/download-template/TEMPLATE_QUESTIONS_LOAD'),
    filter(() =>
      Boolean(state$.value.formBulkUpload.downloadTemplate.selectedTemplate),
    ),
    switchMap(async () => {
      const { selectedTemplate, showHiddenFields, exportableQuestions } =
        state$.value.formBulkUpload.downloadTemplate;

      try {
        const templateResponse = await formsQuery.getFormTemplate(
          client,
          selectedTemplate.id.toString(),
        );

        if (templateResponse.isSuccess !== true)
          return templateQuestionsLoadError();

        const definition = templateResponse.data.currentDefinition?.definition;
        if (!definition) return templateQuestionsLoadError();

        const definitionParsed = JSON.parse(
          definition,
        ) as FormTemplateItemDefinition[];

        let keys = exportableQuestionsFromTemplate(
          definitionParsed,
          showHiddenFields,
          exportableQuestions,
        );

        if (getFormTemplateSubjectType(definition) !== 'Team')
          keys = keys.concat(ACTION_EXPORT_OPTIONS);

        return templateQuestionsLoaded(keys);
      } catch (error) {
        log.error(`Unable to load template id ${selectedTemplate.id}`, error);
        return templateQuestionsLoadError();
      }
    }),
  );
}

const ACTION_EXPORT_OPTIONS = [
  {
    id: 'Action',
    label: 'Action',
    isSelected: true,
    isHeading: true,
    isHiddenByDefault: false,
  },
  {
    id: 'Action Description',
    label: 'Action Description',
    isSelected: true,
    isHiddenByDefault: false,
    parentHeadingId: 'Action',
    answerOptions: [ANSWER_TYPE_LABELS.text],
  },
  {
    id: 'Action Due Date',
    label: 'Action Due Date',
    isSelected: true,
    isHiddenByDefault: false,
    parentHeadingId: 'Action',
    answerOptions: [ANSWER_TYPE_LABELS.date],
  },
];
