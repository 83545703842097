import {
  ReduxFormsBulkUploadFailedRowsRequestDownloadLink,
  ReduxFormsBulkUploadFailedRowsDownloadLinkReady,
  ReduxFormsBulkUploadFailedRowsDownloadComplete,
  ReduxFormsBulkUploadFailedRowsDownloadFailed,
} from './types.ts';

export const formsBulkUploadFailedRowsRequestDownloadLink = (
  jobId: string,
): ReduxFormsBulkUploadFailedRowsRequestDownloadLink => ({
  type: 'ui.forms/bulk-upload/jobs/failed-rows/download/REQUEST_DOWNLOAD_LINK',
  payload: { jobId },
});

export const formsBulkUploadFailedRowsDownloadFailed = (
  jobId: string,
  failure: 'REQUEST' | 'DOWNLOAD',
): ReduxFormsBulkUploadFailedRowsDownloadFailed => ({
  type: 'ui.forms/bulk-upload/jobs/failed-rows/download/DOWNLOAD_FAILED',
  payload: { jobId, failure },
});

export const formsBulkUploadFailedRowsDownloadLinkReady = (
  jobId: string,
  uri: string,
): ReduxFormsBulkUploadFailedRowsDownloadLinkReady => ({
  type: 'ui.forms/bulk-upload/jobs/failed-rows/download/DOWNLOAD_LINK_READY',
  payload: { jobId, uri },
});

export const formsBulkUploadFailedRowsDownloadComplete = (
  jobId: string,
): ReduxFormsBulkUploadFailedRowsDownloadComplete => ({
  type: 'ui.forms/bulk-upload/jobs/failed-rows/download/DOWNLOAD_COMPLETE',
  payload: { jobId },
});
