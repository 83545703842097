import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import {
  DistributionListItem,
  DistributionListItemStatus,
  DistributionListRuleType,
  DistributionListTeamRuleDetail,
  DistributionListType,
} from '../distributionList/types.ts';
import {
  DistributionListRule,
  DistributionListValidationErrors,
  IDistributionListItemDraft,
} from './types.ts';

export type EditDistributionListState = {
  hasDraftChanged: boolean;
  saved: boolean;
  draft: Partial<IDistributionListItemDraft>;
  original?: DistributionListItem;
  isSaving: boolean;
  errors: {
    fieldErrors?: DistributionListValidationErrors;
    globalError?: string;
  };
};

const DEFAULT_STATE: EditDistributionListState = {
  draft: undefined,
  original: undefined,
  isSaving: false,
  errors: {},
  hasDraftChanged: false,
  saved: false,
};

const slice = createSlice({
  name: 'libs/forms/editDistributionList',
  initialState: DEFAULT_STATE,
  reducers: {
    addRuleRow(state) {
      state.draft.rules.push({
        id: state.draft.rules.length.toString(),
      });
      state.hasDraftChanged = true;
    },
    cancelEditingDistributionList(state) {
      state.draft = undefined;
      state.original = undefined;
      state.isSaving = false;
      state.errors = {};
      state.hasDraftChanged = false;
      state.saved = false;
    },
    createDraftDistributionList(state, action: PayloadAction<ModuleType>) {
      state.draft = {
        canEdit: true,
        canSave: true,
        rules: [],
        status: 'Active',
        type: action.payload === 'coaching' ? 'Person' : 'Team',
      };
      state.original = undefined;
      state.errors = {};
      state.isSaving = false;
      state.hasDraftChanged = false;
      state.saved = false;
    },
    deleteRuleRow(state, action: PayloadAction<string>) {
      state.draft.rules = state.draft.rules.filter(
        (rule) => rule.id !== action.payload,
      );
      state.hasDraftChanged = true;
      state.errors.globalError = undefined;
      if (state.errors.fieldErrors) {
        state.errors.fieldErrors.rules = state.errors.fieldErrors.rules?.filter(
          (error) => error.id !== action.payload,
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editDistributionList(state, action: PayloadAction<DistributionListItem>) {
      state.draft = toDistributionListDraft(action.payload);
      state.original = action.payload;
      state.errors = {};
      state.hasDraftChanged = false;
      state.isSaving = false;
      state.saved = false;
    },
    previewDistributionList(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        listName: string;
        rules: DistributionListRule[];
        type: DistributionListType;
      }>,
    ) {
      state.errors = {};
    },
    previewDistributionListError(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        fieldErrors: DistributionListValidationErrors;
        globalError: string;
      }>,
    ) {
      const ruleErrors = action.payload.fieldErrors?.rules;

      state.draft.rules.forEach((rule) => {
        rule.errors = ruleErrors?.find((error) => error.id === rule.id)?.error;
      });
      state.isSaving = false;
      state.errors = action.payload;
      state.saved = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveDistributionList(state, action: PayloadAction<string>) {
      state.isSaving = true;
      state.errors = {};
      state.saved = false;
    },
    saveDistributionListError(
      state,
      action: PayloadAction<{
        fieldErrors?: DistributionListValidationErrors;
        globalError?: string;
      }>,
    ) {
      const ruleErrors = action.payload.fieldErrors?.rules;

      if (!ruleErrors || ruleErrors.length === 0) {
        state.isSaving = false;
        state.errors = action.payload;
        state.saved = false;

        return;
      }

      state.draft.rules.forEach((rule) => {
        rule.errors = ruleErrors.find((error) => error.id === rule.id)?.error;
      });
      state.isSaving = false;
      state.errors = action.payload;
      state.saved = false;
    },
    saveDistributionListSuccess(
      state,
      action: PayloadAction<{
        id: string;
        status: DistributionListItemStatus;
        draft?: DistributionListItem;
      }>,
    ) {
      const updatedDistributionList = {
        ...state.draft,
        ...action.payload.draft,
      };

      state.draft = updatedDistributionList;
      state.draft.id = action.payload.id;
      state.draft.status = action.payload.status;
      state.original = updatedDistributionList;
      state.original.id = action.payload.id;
      state.original.status = action.payload.status;
      state.isSaving = false;
      state.hasDraftChanged = false;
      state.errors = {};
      state.saved = true;
    },
    showAlertDialogOnClickOutsideDistributionList(state) {
      state.errors = {};
    },
    updateDraftDistributionList(
      state,
      action: PayloadAction<{
        field: keyof IDistributionListItemDraft;
        value: any;
      }>,
    ) {
      const field = action.payload.field;
      const updatedDraft = {
        ...state.draft,
        [field]: action.payload.value,
      };

      state.draft = updatedDraft;
      state.hasDraftChanged = true;
    },
    updateRuleRow(state, action: PayloadAction<DistributionListRule>) {
      state.draft.rules.forEach((rule) => {
        if (rule.id !== action.payload.id) return;

        rule.selections = action.payload.selections;
        rule.type = action.payload.type;
        rule.errors = undefined;
      });
      state.hasDraftChanged = true;
    },
  },
});

const toDistributionListDraft = (
  item: DistributionListItem,
): IDistributionListItemDraft => {
  return {
    id: item.id,
    canEdit: item.canEdit,
    canSave: item.canSave,
    created: item.created,
    lastUpdatedBy: item.lastUpdatedBy,
    lastUpdatedOn: item.lastUpdatedOn,
    listName: item.listName,
    rules: getDistributionListRules(
      item.ruleExcludeTeams,
      item.ruleIncludeTeams,
      item.ruleIncludeTeamsBelow,
      item.ruleExcludePositionTitles,
      item.ruleIncludePositionTitles,
    ),
    type: item.type,
    status: item.status,
  };
};

const getDistributionListRules = (
  ruleExcludeTeams: DistributionListTeamRuleDetail[],
  ruleIncludeTeams: DistributionListTeamRuleDetail[],
  ruleIncludeTeamsBelow: DistributionListTeamRuleDetail[],
  ruleExcludePositionTitles: string[],
  ruleIncludePositionTitles: string[],
): DistributionListRule[] => {
  const excludeTeams = ruleExcludeTeams
    ? {
        id: 'excludeTeams',
        type: DistributionListRuleType.ExcludeTeams,
        selections: ruleExcludeTeams,
      }
    : undefined;
  const includeTeams = ruleIncludeTeams
    ? {
        id: 'includeTeams',
        type: DistributionListRuleType.IncludeTeams,
        selections: ruleIncludeTeams,
      }
    : undefined;
  const includeTeamsBelow = ruleIncludeTeamsBelow?.map((team, index) => {
    return {
      id: `includeTeamsBelow-${index}`,
      type: DistributionListRuleType.IncludeTeamsBelow,
      selections: [team],
    };
  });
  const includePositionTitles = ruleIncludePositionTitles
    ? {
        id: 'includePositionTitles',
        type: DistributionListRuleType.IncludePositionTitles,
        selections: ruleIncludePositionTitles,
      }
    : undefined;
  const excludePositionTitles = ruleExcludePositionTitles
    ? {
        id: 'excludePositionTitles',
        type: DistributionListRuleType.ExcludePositionTitles,
        selections: ruleExcludePositionTitles,
      }
    : undefined;

  return [
    excludeTeams,
    includeTeams,
    includePositionTitles,
    excludePositionTitles,
    ...(includeTeamsBelow ?? []),
  ].filter(Boolean);
};

export const {
  addRuleRow,
  cancelEditingDistributionList,
  createDraftDistributionList,
  deleteRuleRow,
  editDistributionList,
  previewDistributionList,
  previewDistributionListError,
  saveDistributionList,
  saveDistributionListError,
  saveDistributionListSuccess,
  showAlertDialogOnClickOutsideDistributionList,
  updateDraftDistributionList,
  updateRuleRow,
} = slice.actions;
export const reducer = slice.reducer;
export type State = EditDistributionListState;
