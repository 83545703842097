import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  delay,
  filter,
  from,
  map,
  mergeMap,
  of,
} from 'rxjs';
import {
  hasIssueCoaching,
  validateRequiredFields,
} from '../../util/util.instance.ts';
import { formInstanceAnswerRequiredError } from '../form-instance/answer/actions.ts';
import {
  loadPermissions,
  setAppealed,
  validationFailed,
} from '../form-instance/instance/actions.ts';
import {
  complianceIssueValidationErrorActions,
  issueCoachingPlanValidationErrorActions,
} from '../issue/util.ts';
import { GlobalFormsEpicDependencies, GlobalFormsState } from '../store.ts';
import * as formInstanceAppealSlice from './formInstanceAppealSlice.ts';
import { GraphQLAppealResponse } from './types.ts';

export const formInstanceAppealEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsState,
  GlobalFormsEpicDependencies
>(reloadAppealEpic, submitAppealEpic, validateAppealEpic);

/**
 * Fetch the current appeal state for an instance from the server.
 * This method has a 500ms delay to allow for server updates to take place.
 */
function reloadAppealEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formInstanceAppealSlice.reloadAppeal.match),
    delay(500),
    mergeMap(async (action) => {
      const instanceId = action.payload;
      const response = await client.query<GraphQLAppealResponse>({
        fetchPolicy: 'network-only',
        query: gql`
          query FormInstanceStatusNotes($instanceId: ID!) {
            forms {
              formInstance(id: $instanceId) {
                statusNotes {
                  appealCategory
                  appealOutcome
                  appealOutcomeReason
                  appealReason
                  appealResponse
                }
              }
            }
          }
        `,
        variables: { instanceId },
      });

      const statusNotes = response?.data?.forms?.formInstance?.statusNotes;

      if (!statusNotes) return EMPTY;

      return of(
        formInstanceAppealSlice.loadedAppeal({
          outcome: statusNotes.appealOutcome,
          outcomeReason: statusNotes.appealOutcomeReason,
          reason: statusNotes.appealReason,
          request: statusNotes.appealReason,
          response: statusNotes.appealResponse,
        }),
      );
    }),
    concatAll(),
  );
}

function submitAppealEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsState>,
  { client }: GlobalFormsEpicDependencies,
) {
  return action$.pipe(
    filter(formInstanceAppealSlice.serverAppeal.match),
    mergeMap(async (action) => {
      const { instanceId, signature, request } = action.payload;
      const response = await client.mutate<{
        forms: { appealFormInstance: { ok: boolean } };
      }>({
        mutation: gql`
          mutation AppealFormInstance($instanceId: ID!, $request: String!) {
            forms {
              appealFormInstance(
                input: { formInstanceId: $instanceId, appealReason: $request }
              ) {
                ok
              }
            }
          }
        `,
        variables: { instanceId, request },
      });

      if (!response?.data?.forms.appealFormInstance.ok) {
        throw new Error(
          'Server returned error or not OK trying to appeal form instance',
        );
      }

      return from([
        formInstanceAppealSlice.serverAppealSuccess({
          instanceId,
          request,
          signature,
        }),
        loadPermissions(instanceId),
        setAppealed(instanceId),
      ]);
    }),
    concatAll(),
  );
}

function validateAppealEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(formInstanceAppealSlice.validateAppeal.match),
    map((action) => {
      const { instanceId, signature } = action.payload;

      const authenticatedUser =
        state$.value.tenantState.tenant?.authenticatedUser?.id;

      const tenantTimezone =
        state$.value.tenantState.tenant.configuration.timezone;

      const instance = state$.value.formInstance.instances[instanceId];
      const formScore = state$.value.formScore;

      const requiredQuestionKeys = validateRequiredFields(
        instance,
        formScore,
        tenantTimezone,
      );

      const complianceIssueErrors = complianceIssueValidationErrorActions(
        state$.value,
      );
      const coachingPlanErrors =
        hasIssueCoaching(instance, false) &&
        issueCoachingPlanValidationErrorActions(instanceId, state$.value);

      const hasErrors =
        complianceIssueErrors?.length || coachingPlanErrors?.length;

      if (hasErrors)
        return from(
          [
            formInstanceAnswerRequiredError(instanceId, requiredQuestionKeys),
            complianceIssueErrors,
            coachingPlanErrors,
            validationFailed(instanceId),
          ]
            .flat()
            .filter(Boolean),
        );
      return from([
        formInstanceAppealSlice.confirmAppeal({
          instance,
          personId: authenticatedUser,
          signature,
        }),
      ]);
    }),
    concatAll(),
  );
}
